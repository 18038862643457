<div class="full-width">
  <p text-large-semibold>{{ 'MICRO_AUTOSAVE_PROJECTIONS_TITLE' | translate }}</p>

  <div>
    <bw-cell *ngFor="let row of projectionRows" separatorType="full" contentLeft="true" rightLabelColor="--brand" [labelRight]="row.value | currency: 'USD':'symbol':'1.0-0'">
      <p content-left text-large-regular color="var(--secondary-text)">{{ row.label }}</p>
    </bw-cell>
  </div>

  <p text-small-regular class="secondary-text center mt-xs">
    {{ 'MICRO_AUTOSAVE_PROJECTIONS_SUB_TITLE' | translate }}
  </p>
</div>
