import { Component, OnInit } from '@angular/core';
import {DisplayCampaignConfig, DisplayCampaignEvent} from "@brightside-web/shared/display";
import {Router} from "@angular/router";

import {
  BsAuthService,
  BsCacheService,
  BsHubService
} from "@brightside-web/desktop/data-access/core-services";

import {AuthService, BadgeIDInterface} from "@brightside-web/desktop/data-access/shared";

//TODO: sign out user -> redirect them to sign in page -> redirect them back here

@Component({
  selector: 'brightside-web-verify-alias',
  templateUrl: './verify-alias.component.html',
  styleUrls: ['./verify-alias.component.scss']
})
export class VerifyAliasComponent implements OnInit {
  showPreFormCampaign = true;
  showPostFormCampaign = false;
  showForm = false;
  displayPage = false;
  redirectUrl = '/verification/alias';
  hasSignOnForReverification: boolean;

  preFormCampaign: DisplayCampaignConfig = {
    closeable: true,
    showBackArrow: false,
    secondaryCtaKey: 'CLOSE',
    ctaKey: 'REVERIFY',
    secondaryCtaPath: '/',
    ctaPath: 'displayForm',
    image: 'fa_intro_1',
    key: 'string',
    textBody: 'Some information you provided during registration does not match your employer’s records. Please re-enter it now.',
    textBodyKey: 'VERIFICATION_INTRO_DESC',
    titleKey: 'VERIFICATION_INTRO_TITLE'
  }
  postFormCampaign: DisplayCampaignConfig = {
    closeable: true,
    showBackArrow: false,
    cta: 'Continue',
    ctaKey: 'BUTTON_CONTINUE',
    ctaPath: '/',
    image: 'verified',
    key: 'string',
    textBody: 'We are reverifying your employment now. Your Financial Assistant will follow up shortly.',
    textBodyKey: 'VERIFICATION_OUTRO_DESC',
    titleKey: 'VERIFICATION_OUTRO_TITLE'
  }

  constructor(
    private router: Router,
    private auth: AuthService,
    private bsAuthService: BsAuthService,
    private bsCacheService: BsCacheService,
    private bsHubService: BsHubService
  ) {
    this.bsCacheService.removeItem('uniqueIdRetriesExceeded');
  }

  ngOnInit(): void {
    this.bsCacheService.getItem('hasSignOnForReverification').then(
      hasSignOnForReverification => {
        this.hasSignOnForReverification = hasSignOnForReverification;
        if (!this.hasSignOnForReverification && this.auth.redirectUrl !== '') {
          this.bsAuthService.signOut()
            .then(() => {
              this.auth.setRedirectUrl(this.redirectUrl);
              this.bsCacheService.setItem('hasSignOnForReverification', true);
              this.router.navigate(['/sign_in']);
            })
            .catch((err: any) => console.log(err));
        } else {
          this.displayPage = true;
        }
      }
    );
  }

  handleEventClick(event: DisplayCampaignEvent) {
    switch (event.path) {
      case '/':
        this.router.navigate([event.path]);
        break;
      case 'displayForm':
        this.showPreFormCampaign = false;
        this.showForm = true;
        break;
    }
  }

  handleAliasSubmitted(event: BadgeIDInterface) {
    if (event.status === 'success') {
      this.showForm = false;
      this.showPostFormCampaign = true;
    }
  }

  handleBackClick(event: boolean) {
    if (event) {
      this.showPreFormCampaign = true;
      this.showForm = false;
    }
  }
}
