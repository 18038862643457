<div class="account-header_wrapper mb-sm">
  <bw-header [title]="title | translate"></bw-header><br>
  <div class="account-header_balance mb-sm">
    <typography text-xxlarge-semibold>{{balance|currency}}</typography>
    <div class="account-header_balance-subsection flex-row space-between">
      <typography text-xsmall-regular color="var(--gray-50)">
        {{ 'ACCOUNT_HOME_CURRENT_BALANCE' | translate }}
      </typography>
      <ng-content select="[extra-details]"></ng-content>
    </div>
  </div>
  <ng-content select="[header-action-cards]"></ng-content>
</div>
