import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route, Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {from, Observable, of} from 'rxjs';
import { AuthService } from '@brightside-web/desktop/data-access/shared';
import {
  BsAuthService,
  BsCacheService
} from "@brightside-web/desktop/data-access/core-services";
import {map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class GuidGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router:Router,
    private bsAuthService: BsAuthService,
    private bsCacheService: BsCacheService
    ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    return from(this.bsAuthService.fetchUserAttributes()).pipe(
      map(attributes => {
        if (!attributes) {
          return this.router.createUrlTree(['registration/create-account']);
        } else {
          if (state.url.includes('home')) {
            if (attributes['custom:guid']) {
              return true;
            } else {
              return this.router.createUrlTree(['registration/select-employer']);
            }
          } else {
            // else is meant to cover registration paths
            if (attributes['custom:guid']) {
              return this.router.createUrlTree(['/home']);
            } else {
              return true;
            }
          }
        }
      })
    );
  }
}
