import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedDisplayModule } from '@brightside-web/shared/display';

import { AuthGuard, OnboardingCompleteGuard, SharedDesktopModule, VerificationStatusGuard } from '@brightside-web/shared/desktop';
import { VerifyAliasComponent } from './verify-alias/verify-alias.component';
import { BrightsideUiModule } from '@brightside/brightside-ui';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ProductIdentityComponent } from './identity/identity.component';
import { StatusComponent } from './status/status.component';
import { EmploymentComponent } from './employment/employment.component';
import { EmploymentFailedGuard } from './employment/employment.failed.guard';
import { LayoutStyle } from '@brightside-web/micro/core/utility';
import { SmfaComponent } from './smfa/smfa.component';
import { GoToBrightsideDesktopComponent } from '../../../../../shared/desktop/src/';
import { EmailVerificationComponent } from './email-verification/email-verification.component';
import {MicroUiMoleculeModule} from "@micro-ui/molecule";
import {EmailVerificationGuard} from "./email-verification/email-verification.guard";

const routes: Routes = [
  { path: 'alias', component: VerifyAliasComponent },
  {
    path: 'identity/:product',
    component: ProductIdentityComponent,
    canActivate: [AuthGuard, OnboardingCompleteGuard],
    data: {
      label: 'Identity Verification',
      breadcrumb: 'Identity Verification',
    },
  },
  {
    path: 'email',
    component: EmailVerificationComponent,
    canActivate: [AuthGuard, OnboardingCompleteGuard, EmailVerificationGuard],
    data: { appDisplayStyle: LayoutStyle.PUBLIC_ACCESS },
  },
  { path: 'status', component: StatusComponent, canActivate: [AuthGuard, OnboardingCompleteGuard] },
  { path: 'employment', component: EmploymentComponent, canActivate: [AuthGuard, EmploymentFailedGuard] },
  { path: 'smfa', component: GoToBrightsideDesktopComponent, data: { appDisplayStyle: LayoutStyle.PUBLIC_ACCESS } },
  { path: 'smfa/:linkId', component: SmfaComponent, data: { appDisplayStyle: LayoutStyle.PUBLIC_ACCESS } },
  { path: 'go-to-brightside', component: GoToBrightsideDesktopComponent, data: { appDisplayStyle: LayoutStyle.PUBLIC_ACCESS } },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    BrightsideUiModule,
    ReactiveFormsModule,
    SharedDesktopModule,
    SharedDisplayModule,
    TranslateModule,
    MicroUiMoleculeModule,
  ],
  declarations: [
    VerifyAliasComponent,
    ProductIdentityComponent,
    StatusComponent,
    EmploymentComponent,
    SmfaComponent,
    EmailVerificationComponent,
  ],
  exports: [ProductIdentityComponent],
})
export class DesktopFeatureVerificationModule {}
