import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AnalyticsAttributesV4, FirebaseService } from '@brightside-web/desktop/data-access/shared';
import { CampaignEventStatus } from '@brightside-web/desktop/data-access/onboarding';
import { MessageBusInternalService } from '@brightside-web/micro/core/message-bus';

import { DisplayData } from '../models/display.enum';

export interface DisplayCampaignConfig extends DisplayData {
  pageTitle?: string;
  closeable?: boolean;
  showBackArrow?: boolean;
  showFullScreenCta?: boolean;
  analyticsAttributes?: AnalyticsAttributesV4;
}

export interface DisplayCampaignEvent {
  path: string;
  type: CampaignEventStatus;
}

@Component({
  selector: 'brightside-web-display-campaign',
  templateUrl: './display-campaign.component.html',
  styleUrls: ['./display-campaign.component.scss'],
})
export class SharedDisplayCampaignComponent {
  @Input() analyticsAttributes: AnalyticsAttributesV4;
  @Input() campaign: DisplayCampaignConfig;
  @Input() showBackArrow = true;

  @Output() campaignEvent: EventEmitter<DisplayCampaignEvent> = new EventEmitter<DisplayCampaignEvent>();

  processing = false;

  private dataFromRoute = false;

  constructor(protected activatedRoute: ActivatedRoute, protected analytics: FirebaseService, protected router: Router) {

    this.activatedRoute.data.subscribe((data) => {
      if (data?.config) {
        this.campaign = data.config;
        this.showBackArrow = Boolean(this.campaign.showBackArrow);
        this.dataFromRoute = true;
        if (this.campaign.analyticsAttributes) {
          this.analyticsAttributes = this.campaign.analyticsAttributes;
        }
      }
    });
  }

  instanceOfV4(object: any): object is AnalyticsAttributesV4 {
    return 'event_name' in object;
  }

  getFbInfo(extra: { [key: string]: string } = {}) {

    if (this.analyticsAttributes && this.instanceOfV4(this.analyticsAttributes)) {
      if (!extra.action) {
        return { event_name: this.analyticsAttributes.event_name + '_shown' };
      }
      switch (extra.action) {
        case 'success':
          return { event_name: this.analyticsAttributes.event_name + '_tapped', action: 'click' };
        default:
          return { event_name: this.analyticsAttributes.event_name + '_dismissed', action: 'click' };
      }
    }
  }

  get CampaignEventStatus() {
    return CampaignEventStatus;
  }

  get imageName() {
    return this.campaign?.image || '';
  }

  get pageTitle() {
    return this.campaign?.pageTitle || '';
  }

  get titleText() {
    return this.campaign?.title ?? this.campaign?.titleKey ?? '';
  }

  get bodyText() {
    return this.campaign?.textBody ?? this.campaign?.textBodyKey ?? '';
  }

  get primaryCtaText() {
    return this.campaign?.cta ?? this.campaign?.ctaKey ?? '';
  }

  get secondaryCtaText() {
    return this.campaign?.secondaryCta ?? this.campaign?.secondaryCtaKey ?? '';
  }

  get closeable() {
    return Boolean(this.campaign?.closeable);
  }

  get bodyTextAlign() {
    return this.campaign?.textBodyAlign || 'center';
  }

  get bodyTextNoWrap() {
    return this.campaign?.textBodyNoWrap? 'no-wrap' : 'pre-wrap';
  }

  get creditIntroItems() {
    return ['CREDIT_INTRO_ITEM_1', 'CREDIT_INTRO_ITEM_2', 'CREDIT_INTRO_ITEM_3'];
  }

  handleClick(path: string, type: CampaignEventStatus) {

    //Only call this if data is not from route
    if (!this.dataFromRoute) {
      return this.campaignEvent.emit({ path, type });
    }

    if (type === CampaignEventStatus.SUCCESS) this.handlePrimaryClick();
    if (type === CampaignEventStatus.FAILED) this.handleSecondaryClick();
  }

  handleCloseClick() {
    if ((this.campaign as any).pageSecondaryCtaEvent) {
      MessageBusInternalService.sendOutgoingHubEvent((this.campaign as any).pageSecondaryCtaEvent);
    } else {
      this.router.navigate(['home']);
    }
  }

  handlePrimaryClick() {

    this.processing = true;

    if (!this.campaign.ctaPath && !this.campaign.ctaEvent) {
      //ToDo: Maybe this should throw error?
      this.handleCloseClick();
      return;
    }

    //Check if there is an event passed
    if (this.campaign.ctaEvent) {
      MessageBusInternalService.sendOutgoingHubEvent(this.campaign.ctaEvent);
    } else if (typeof this.campaign.ctaPath === 'string') {
      this.router.navigateByUrl(this.campaign.ctaPath);
    } else {
      this.router.navigate(this.campaign.ctaPath, { relativeTo: this.activatedRoute });
    }
  }

  handleSecondaryClick() {
    //Check if there is an event passed
    if (this.campaign.secondaryCtaEvent) {
      MessageBusInternalService.sendOutgoingHubEvent(this.campaign.secondaryCtaEvent);
    } else if (!this.campaign.secondaryCtaPath) {
      //ToDo: Maybe this should throw error?
      this.handleCloseClick();
    } else {
      if (typeof this.campaign.secondaryCtaPath === 'string') {
        this.router.navigateByUrl(this.campaign.secondaryCtaPath);
      } else {
        this.router.navigate(this.campaign.secondaryCtaPath, { relativeTo: this.activatedRoute });
      }
    }
  }
}
