<bw-modal *ngIf="visible" modalWidth="large">
  <typography title-medium>{{title | translate}}</typography>
  <div class="body">
    <bw-spinner brightsideWebTimeout *ngIf="!data"></bw-spinner>
    <div [innerHtml]="data" style="max-height:540px;"></div>
  </div>
  <div class="footer">
    <div class="flex-row row-reverse">
      <button bw-button (click)="visible=false;">{{cta | translate}}</button>
    </div>
  </div>
</bw-modal>
