export enum KnownFeatureFlags {
  HELIX1 = 'enable-helix-alpha-for-applications-pt-1',
  HELIX2 = 'enable-helix-alpha-for-applications-pt-2',
  HELIX3 = 'enable-helix-alpha-for-applications-pt-3',
  HELIXBETA1 = 'enable-helix-beta-for-applications-pt-1',
  HELIXBETA2 = 'enable-helix-beta-for-applications-pt-2',
  LEGALDOCSSYN = 'application-enable-legal-docs-ui-in-synapse-workflows',
  CREDITSETUP = 'enable-credit-setup-feature',
  DIGITALCARDACCESS = 'enable-digital-card-access',
  HELIXMIGRATION = 'helix-migration',
  FREEZETRANSFERPULL = 'helix-migration-freeze-ach-pull-transactions',
  FREEZETRANSFERS = 'helix-migration-freeze-all-ach-transactions',
  FREEZEDISPUTE = 'helix-migration-freeze-app-based-dispute-submissions',
  FREEZECASHADVANCE = 'helix-migration-freeze-cash-advance',
}
