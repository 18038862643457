import {Injectable} from '@angular/core';
import {Cache} from 'aws-amplify/utils'
import { CacheItemOptions } from '@aws-amplify/core/src/Cache/types/cache';

@Injectable({
  providedIn: 'root'
})
export class BsCacheService {

  static setItemStatic(key: string, value: any, options?: Record<string, any> | undefined) : Promise<void> {
    return Cache.setItem(key, value, options);
  }

  static getItemStatic(key: string) : Promise<any>
  static getItemStatic(key: string, options?: CacheItemOptions | undefined) : Promise<any> {
    if (options) return Cache.getItem(key, options);
    return Cache.getItem(key);
  }

  static removeItemStatic(key: string) : Promise<void> {
    return Cache.removeItem(key);
  }

  constructor() { }

  async getItem(key: string) : Promise<any>
  async getItem(key: string, options?: CacheItemOptions | undefined) : Promise<any> {
    if (options) return await Cache.getItem(key, options);
    return await Cache.getItem(key);
  }

  async setItem(key: string, value: any, options?: Record<string, any> | undefined) : Promise<void> {
    return await Cache.setItem(key, value, options);
  }

  async removeItem(key: string) : Promise<void> {
    return await Cache.removeItem(key);
  }

  clear(): Promise<void> {
    return Cache.clear();
  }

}
