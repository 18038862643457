import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FinancialAccountTransaction} from "@brightside-web/desktop/feature/financial-accounts-core";
import {SimpleGlobalModalService} from "@brightside-web/desktop/data-access/core-services";
import { BsHubService } from "@brightside-web/desktop/data-access/core-services";

@Component({
  selector: 'brightside-web-transaction-cell',
  templateUrl: './transaction-cell.component.html',
  styleUrls: ['./transaction-cell.component.scss']
})
export class TransactionCellComponent {
  @ViewChild('transactionDetails') transactionDetails!: TemplateRef<any>;
  @Input() transaction: FinancialAccountTransaction;
  showDetails: boolean;

  constructor(
    private modalSvc: SimpleGlobalModalService,
    private bsHubService: BsHubService
  ) { }

  openDetails() {
    this.modalSvc.createModal({
      title: 'TRANSACTIONS_DETAILS_TITLE',
      displayCloseButton: true,
      eventName: 'transaction_details',
      isError: false,
      size: "medium",
      ctaKey: 'TRANSACTIONS_DETAILS_DISPUTE_CTA',
      ctaAction: () => {
        this.showDisputeModal();
      },
      templateRef: this.transactionDetails
    });
  }

  showDisputeModal() {
    this.modalSvc.createModal({
      title: 'TRANSACTIONS_DETAILS_DISPUTE_TITLE',
      contentKey: 'TRANSACTIONS_DETAILS_DISPUTE_DESC',
      displayCloseButton: true,
      eventName: 'dispute_transaction',
      isError: false,
      size: "medium",
      ctaKey: 'MESSAGE_FA',
      ctaAction: () => {
        this.bsHubService.dispatch('IntercomChannel', {event: 'open'});
        this.openDetails();
      },
      closeAction: () => {
        this.openDetails();
      }
    })
  };
}
