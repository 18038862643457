<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <p title-medium header-title class="mb-md">Sign in</p>
  <div content-section>
    <p text-small-semibold class="primary-text mb-xs">Mobile Phone Number</p>
    <input id="microLogInClientPhone" [formControlName]="'mobile'" type="text" />
    <p text-small-semibold class="primary-text mb-xs">Password</p>
    <input id="microLogInClientPassword" [formControlName]="'password'" minlength="8" />
  </div>
  <div footer-actions class="flex-column">
    <button id="microLogInCta" type="submit" [disabled]="loginForm.invalid">
      {{ processing ? '' : 'Sign in' }}
    </button>
  </div>
</form>
