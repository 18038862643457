import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MicroContestModule, MicroContestNgModuleConfig } from './micro-contest.module';
import { BaseRoutes as ResultRoutes } from './routes/result';

import { MicroContestResultsRevealResolver } from './routes/resolver/result-reveal.resolver';
import { MicroContestResultResolver } from './routes/result/reveal/result.resolver';

import { MicroContestRoute, MicroContestRouteParamKey } from './model/contest.enum';

@NgModule({
  ...MicroContestNgModuleConfig,
  imports: [...MicroContestNgModuleConfig.imports, RouterModule.forChild(ResultRoutes)],
  providers: [...MicroContestNgModuleConfig.providers, MicroContestResultResolver, MicroContestResultsRevealResolver],
})
export class MicroContestResultModule extends MicroContestModule {
  /**
   * Use this static helper to check if the contest details you have are
   * ready to be shown to the client for a reveal.
   *
   * @param contestDetail <{ id: number; name: string; resultsViewed: boolean; isClientParticipating: boolean; didClientParticipate: boolean; isSingleContest?: boolean; drawingDate: string }> - Not a named interface
   * @returns <boolean> - True=Contest if valid and ready False=Contest should not be shown to the client
   */
  static HelperShouldShowReveal = (contestDetail: {
    id: number;
    name: string;
    resultsViewed: boolean;
    isClientParticipating: boolean;
    didClientParticipate: boolean;
    isSingleContest?: boolean;
    drawingDate: string;
  }) => contestDetail.id > 0 && (contestDetail.didClientParticipate || contestDetail.isSingleContest);

  /**
   * Use this static helper to get the correct routing information
   * to reveal the contest results to this client.
   *
   * Before calling this, you should have already done HelperShouldShowReveal
   *
   * @param contestId <number>
   * @returns Route information (string | number)[]
   */
  static HelperGetRevealRoute = (contestId: number): (string | number)[] =>
    MicroContestRoute.RESULT.map((routePart: string) =>
      routePart === MicroContestRouteParamKey.CONTEST_ID ? contestId : routePart
    );
}
