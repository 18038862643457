import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrightsideUiModule } from '@brightside/brightside-ui';
import { BrightsideUiServicesModule } from '@brightside/brightside-ui-services';
import { SharedDesktopModule } from '@brightside-web/shared/desktop';

import { MicroUiMoleculeModule } from '@micro-ui/molecule';
import { MicroUiTemplatePageModule } from '@micro-ui/template/page';

import { NgxCurrencyModule } from "ngx-currency";

import { AutosaveEditComponent } from './edit/autosave-edit.component';

import { AutosaveFrequencyComponent } from './frequency/autosave-frequency.component';
import { AutosaveProjectionComponent } from './projection/autosave-projection.component';
import { AutosaveSelectorComponent } from './selector/autosave-selector.component';
import { AutosavePauseComponent } from './pause/autosave-pause.component';
import { IntroComponent } from './intro/intro.component';
import { IntroFaqComponent } from './intro-faq/intro-faq.component';

import { DisplayAutosaveComponent } from './display-autosave/display-autosave.component';
import {TranslateModule, TranslateService, TranslatePipe} from "@ngx-translate/core";
import {BrightsideTranslateService} from "@brightside-web/desktop/data-access/shared";

const PAGES = [AutosaveEditComponent, DisplayAutosaveComponent, IntroComponent];
const COMPONENTS = [AutosaveFrequencyComponent, AutosaveProjectionComponent, AutosaveSelectorComponent, AutosavePauseComponent, IntroFaqComponent];

@NgModule({
  imports: [
    BrightsideUiModule,
    BrightsideUiServicesModule,
    CommonModule,
    FormsModule,
    MicroUiMoleculeModule,
    MicroUiTemplatePageModule,
    NgxCurrencyModule,
    ReactiveFormsModule,
    SharedDesktopModule,
    TranslateModule,
  ],
  declarations: [...PAGES, ...COMPONENTS],
  exports: [...PAGES, ...COMPONENTS],
  providers: [TranslatePipe, { provide: TranslateService, useClass: BrightsideTranslateService }],
})
export class SharedAutosaveModule {}
