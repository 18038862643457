import {ErrorHandler, Injectable, Injector} from '@angular/core';
import { ToastService } from '@brightside/brightside-ui-services';
import { AmplitudeService } from "@brightside-web/desktop/data-access/shared";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import * as StackTrace from "stacktrace-js";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root',
})
export class BsErrorHandler implements ErrorHandler {
  constructor(private toasty:ToastService, private amplitudeService: AmplitudeService, private injector: Injector, private translateSvc: TranslateService) {
  }
  // ideally we would fix the root cause of these issues so we don't have to ignore them
  ignoreErrors = ["No current user", "ExpressionChangedAfterItHasBeenCheckedError", "Http failure during parsing for"]
  async handleError(error: any): Promise<void> {
    //TODO: should the no current user redirect to some way to relogin/refresh the token?
    const contextInfo = await this.addContextInfo(error);
    const errMsg = this.translateSvc.instant('ERROR_TITLE_GENERAL') ?? 'Something went wrong';
    if (error.message && !this.ignoreErrors.find(ignore => error.message.includes(ignore))) {
      console.error(error);
      this.toasty.error(errMsg, {transient:false})
      this.amplitudeService.incrementErrorsViewed();
      this.amplitudeService.logEvent(`error_logged`, contextInfo as unknown as {[key:string]:string});
     }
    console.error(JSON.stringify(contextInfo));
  }
  async addContextInfo(error:any) {
    const name = error.name || null;
    const location = this.injector.get(LocationStrategy);
    const url = location instanceof PathLocationStrategy ? location.path() : "";
    const agent = navigator.userAgent;
    const message = error.message || error.toString();
    let stack = null;
    if (!(error instanceof HttpErrorResponse)) {
      try {
        // we have to do an await to get source mapping on the stack
        const frames = await StackTrace.fromError(error);
        if (frames && frames.length) {
          stack = frames[0];
        }
      } catch (e){}
    }
    return { name, url, agent, message, stack: JSON.stringify(stack) };
  }
}
