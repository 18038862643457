import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FirebaseService } from '@brightside-web/desktop/data-access/shared';
import {TranslateService} from "@ngx-translate/core";

const MULTIPLIERS: { sixMonth: any; oneYear: any } = {
  sixMonth: {
    Monthly: 6.028567417,
    'Bi-Weekly': 13.06189607,
    Weekly: 26.12379214,
  },
  oneYear: {
    Monthly: 12.1145417,
    'Bi-Weekly': 24.2290834,
    Weekly: 52.49634737,
  },
};

@Component({
  selector: 'brightside-web-autosave-projections',
  templateUrl: './autosave-projection.component.html',
})
export class AutosaveProjectionComponent implements OnInit, OnChanges {
  @Input() amountPerCycle = 0;
  @Input() payFrequency = 'Monthly';

  projectionRows: { label: string; value: number }[] = [];

  constructor(protected analytics: FirebaseService, private translateService: TranslateService) {}

  ngOnInit() {
    this.updateProjections();
  }

  ngOnChanges() {
    this.updateProjections();
  }

  private updateProjections() {
    this.projectionRows = [
      {
        label: this.translateService.instant('MICRO_AUTOSAVE_PROJECTIONS_SIX_MONTHS_LABEL'),
        value: this.amountPerCycle * MULTIPLIERS.sixMonth[this.payFrequency],
      },
      {
        label: this.translateService.instant('MICRO_AUTOSAVE_PROJECTIONS_ONE_YEAR_MONTHS_LABEL'),
        value: this.amountPerCycle * MULTIPLIERS.oneYear[this.payFrequency]
      },
    ];
  }
}
