import { FormGroup } from '@angular/forms';
import { DisplayFormControlConfig, DisplayFormProcessResponse, DisplayFormSettings } from '@brightside-web/shared/desktop';
import { MessageBusInternalHubEvent } from '@brightside-web/micro/core/message-bus';

import { Observable } from 'rxjs';

export { DisplayFormProcessErrorType as SharedDisplayFormProcessErrorType } from '@brightside-web/shared/desktop';

//ToDo: Add property information to comments
//ToDo: Refactor this to be an interface instead of a class.. not sure why it was ever a class to be honest
/**
 * @description
 * A child class to handle the shared component display needs. Main difference here is
 * removal from the desktop library and support for cell displays along with form information.
 *
 * @extends DisplayFormSettings
 * Beyond what's already part of the DisplayFormSettings interface we add the following support:
 *
 * @property
 * - Key: cellDetails
 * - Type: SharedDisplayCellConfig
 * - Reason: used in-cases when we don't want to render the direct input and want cell displayed
 * - optional
 */
export class SharedDisplayFormSettings extends DisplayFormSettings {
  constructor(config: any = {}) {
    super(config);

    this.pageAdditionalInformation = config.pageAdditionalInformation || undefined;
    this.pageCalloutInformation = config.pageCalloutInformation || undefined;
    this.pageConfirmCheckText = config.pageConfirmCheckText;
    this.pageHeaderIconName = config.pageHeaderIconName || '';

    if (typeof config.pageCellRedirectPath !== 'string') {
      this.pageCellRedirectPath = './:controlKey';
    } else {
      this.pageCellRedirectPath = config.pageCellRedirectPath;
    }

    if (config.pageSecondaryCtaEvent) {
      this.pageSecondaryCtaEvent = config.pageSecondaryCtaEvent;
    }

    if (config.pageOnBackCtaEvent) {
      this.pageOnBackCtaEvent = config.pageOnBackCtaEvent;
    }

    if (config.pageSubTitleColor) {
      this.pageSubTitleColor = config.pageSubTitleColor;
    }

    if (config.pageSubTitleStyle) {
      this.pageSubTitleStyle = config.pageSubTitleStyle;
    }

    this.cellClicked = config.cellClicked || null;

    this.pageFormForceAsValid = config.pageFormForceAsValid || false;
    if (config.pageFormControlsObservable) {
      this.pageFormControlsObservable = config.pageFormControlsObservable;
    }
  }

  pageHeaderIconName? = '';
  pageCellRedirectPath?: string;
  pageConfirmCheckText? = '';

  pageFormForceAsValid? = false;
  pageFormControlsObservable?: Observable<SharedDisplayFormControlConfig[]>;

  pageCalloutInformation?: SharedDisplayCalloutInformation;
  pageAdditionalInformation?: SharedDisplayAdditionalInformation;

  pageSubTitleColor?: string;
  pageSubTitleStyle?: string;

  pageSecondaryCtaEvent?: MessageBusInternalHubEvent;
  pageOnBackCtaEvent?: MessageBusInternalHubEvent;

  cellClicked?: ClickResponderFunction;

  getCellRedirectRoute(controlKey: string = ''): string[] {
    return (this.pageCellRedirectPath || '').replace(':controlKey', controlKey).split('/');
  }
}

/**
 * @description
 * An interface used in the shared display component to understand how to display
 * a cell instead of the direct form input.
 *
 * @property
 * - Key: config
 * - Type: { title: string; subTitle: string; }
 * - Reason: these details will be used when the client clicks into a cell
 * @property
 * - Key: controls
 * - Type: DisplayFormControlConfig[]
 * - Reason: tells us which control(s) to display once the client has selected the cell
 * @property
 * - Key: labelLeft
 * - Type: string
 * @property
 * - Key: labelSubLeft
 * - Type: string
 * @property
 * - Key: iconNameRight
 * - Type: string
 * - Notes: If not provided, will default to chevron_right
 * - optional
 * @property
 * - Key: flipLeftLabels
 * - Type: boolean
 * - Reason: This will flip the labels to move the smaller "subLabel" above the larger "label"
 * - Notes: Default state will be 'false'
 * - optional
 */
export interface SharedDisplayCellConfig {
  action?: SharedDisplayCellActionConfig;

  config: { title: string; subTitle: string }; //ToDo: need template key support for this copy
  controls: SharedDisplayFormControlConfig[];

  labelLeft: string;
  labelSubLeft: string;
  iconNameRight?: string;

  flipLeftLabels?: boolean;
}

export interface SharedDisplayCellActionConfig {
  clickResponder?: ClickResponderFunction;
  emphasize?: boolean;
  event?: MessageBusInternalHubEvent;
  supportedHelpHint?: string;
  supportedFiles?: string;
  supportMultiple?: boolean;
  redirectPath?: string;
}

export enum SharedDisplayChoiceStyle {
  CHECKBOX = 'checkbox',
  RADIO = 'radio'
}

export interface SharedDisplayCellChoiceQuestion {
  questionHintText?: string;
  selectedChoices?: {};
  selected?: boolean;
  style?: SharedDisplayChoiceStyle;
  id: string;
  text: string;
  choices: string[];
  choiceValueMapping?: { [key: string]: string | boolean | number };
}


export interface SharedDisplayCellChoiceConfig {
  questions: SharedDisplayCellChoiceQuestion[];
  clickResponder?: ClickResponderFunction;
  event?: MessageBusInternalHubEvent;
}

/**
 * @description
 * An interface used in the shared display component to tell the component how
 * to render the page. Includes the form controls and pages details like title/body text.
 *
 * @extends DisplayFormControlConfig
 * Beyond what's already part of the DisplayFormControlConfig interface we add the following support:
 *
 * @property
 * - Key: cellDetails
 * - Type: SharedDisplayCellConfig
 * - Reason: used in-cases when we don't want to render the direct input and want cell displayed
 * - optional
 */
export interface SharedDisplayFormControlConfig extends DisplayFormControlConfig {
  cellDetails?: SharedDisplayCellConfig;
  choice?: SharedDisplayCellChoiceConfig;
  groupId?: number;
  rules?: { display: SharedDisplayFormControlDisplayRules };
}

export interface SharedDisplayFormControlDisplayRules {
  showIfTrue?: SharedDisplayFormControlDisplayRule[];
  hideIfTrue?: SharedDisplayFormControlDisplayRule[];
}

export interface SharedDisplayFormControlDisplayRule {
  objKey?: string;
  formKey: string;
  values: (string | number | boolean)[];
}

/**
 * @description
 * An interface used to display callout text with an icon right below the subTitle
 *
 *
 * @property
 * - Key: text
 * - Type: string
 *
 * @property
 * - Key: iconName
 * - Type: string
 *
 */
export interface SharedDisplayCalloutInformation {
  text: string;
  iconName: string;
}

/**
 * @description
 * An interface used to display privacy type links at the bottom of forms
 *
 *
 * @property
 * - Key: bodyText
 * - Type: string
 * - Reason: Text to render when link is clicked
 *
 * @property
 * - Key: linkPostText
 * - Type: string
 *
 * @property
 * - Key: linkPreText
 * - Type: string
 *
 * @property
 * - Key: linkText
 * - Type: string
 * - Reason: Clickable text in the link
 *
 * @property
 * - Key: title
 * - Type: string
 * - Reason: Text to render at the top of the modal
 */
export interface SharedDisplayAdditionalInformation {
  bodyText: string;
  linkPostText: string;
  linkPreText: string;
  linkText: string;
  title: string;
  cta: string;
}

export type SharedDisplayFormProcessResponse = DisplayFormProcessResponse;
export type ClickResponderFunction = (form: FormGroup, targetControlConfig?: SharedDisplayFormControlConfig) => null | void;
