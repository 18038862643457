import { Injectable } from '@angular/core';

import { BsHubService } from '@brightside-web/desktop/data-access/core-services';

import { MessageBusEventChannel } from '../models/message-bus.enum';
import {
  HubCapsule,
  HubPayload,
  MessageBusHubListenerArgs,
  MessageBusHubListener,
  MessageBusInternalHubEvent,
} from '../models/message-bus.interface';
import { MessageBusBaseService } from '../service/message-bus-base.service';

@Injectable({
  providedIn: 'root',
})
export class MessageBusInternalService extends MessageBusBaseService {
  static hubListeners: MessageBusHubListenerArgs[] = [];

  static addHubListener(details: MessageBusHubListenerArgs): MessageBusHubListener {
    const { channel, callbackListener } = details;

    //Let's wrap the incoming callback to abstract things like pulling out payload
    const wrappedCallbackListener = (data: HubCapsule<any, any>) => {
      const payload: HubPayload = data.payload;

      callbackListener(payload);
    };
    // TODO: KEN CHECK THIS, the original intention seems to be that Hub supported RegExp to filter channels
    BsHubService.listenStatic(channel as string, wrappedCallbackListener);

    //Now we need to return the object so we can properly destroy later
    return {
      channel,
      callbackListener: wrappedCallbackListener,
      destroy: () => {
        BsHubService.listenStatic(channel as string, wrappedCallbackListener);
      },
    };
  }

  static addHubListenerWithEventFilter(details: MessageBusHubListenerArgs): MessageBusHubListener {
    const { channel, filterByEvents, callbackListener } = details;
    let { take } = details;

    if (!filterByEvents) {
      throw new Error('This method does not support use without event filters. Please use addHubListener directly.');
    }

    //We are going to wrap here to be able to filter out bad events
    const wrappedCallbackListener = (payload: HubPayload | MessageBusInternalHubEvent) => {
      if (filterByEvents.includes(payload.event)) {
        callbackListener(payload);

        //This is the mech to destroy after x callbacks
        if (take) {
          take -= 1;

          if (take >= 0) {
            returnMessageBusHubListener.destroy();
          }
        }
      }
    };

    const returnMessageBusHubListener = this.addHubListener({ channel, callbackListener: wrappedCallbackListener });

    MessageBusInternalService.hubListeners.push(details);

    return returnMessageBusHubListener;
  }

  static sendOutgoingHubEvent(hubEvent: MessageBusInternalHubEvent, offSetDelay?: number) {

    if (!offSetDelay || offSetDelay <= 0) {
      BsHubService.dispatchStatic(MessageBusEventChannel.OUTGOING, hubEvent);
      return;
    }

    setTimeout(() => {
      BsHubService.dispatchStatic(MessageBusEventChannel.OUTGOING, hubEvent);
    }, offSetDelay);
  }

  static sendInternalHubEvent(
    hubEvent: MessageBusInternalHubEvent,
    channel: MessageBusEventChannel | string = MessageBusEventChannel.INTERNAL
  ) {
    BsHubService.dispatchStatic(channel, hubEvent);
  }

  constructor() {
    super();
  }
}
