<bw-tx-cell
  [txCellLabel]="transaction.createdDate | date : 'mediumDate'"
  [txCellValue]="transaction.amount | currency"
  [txCellDetails]="transaction.description"
  (clickAction)="openDetails()">
</bw-tx-cell>

<ng-template #transactionDetails>
  <ng-container class="body">
    <typography text-medium-semibold>
      {{ transaction.merchant }}
    </typography>
    <bw-cell
      [leftLabelColor]="'--secondary-text'"
      [labelLeft]="'AMOUNT'|translate"
      [labelRight]="transaction.amount | currency" >
    </bw-cell>
    <bw-cell
      [leftLabelColor]="'--secondary-text'"
      [labelLeft]="'DATE'|translate"
      [labelRight]="transaction.createdDate | date: 'longDate'" >
    </bw-cell>
    <bw-cell
      [leftLabelColor]="'--secondary-text'"
      [labelLeft]="'TRANSACTIONS_DETAILS_STATUS'|translate"
      [labelRight]="transaction.statusCopyKey | translate" >
    </bw-cell>
    <typography text-small-regular color="var(--secondary-text)">
      <div class="mt-xs mb-xs">
        {{'TRANSACTIONS_DETAILS_BANK_PROVIDER'|translate}}
      </div>
      <hr class="button-bar">
    </typography>
  </ng-container>
</ng-template>
