<bw-modal
  *ngIf="showHalfsheet && halfSheetSettings"
  [brightsideWebFb]="displayAnalytics.shown"
  (closeModal)="showHalfsheet = false"
>
  <typography title-medium color="var(--gray-70)">{{ halfSheetSettings.data.title || '' | translate }}</typography>
  <section class="body">
    <bw-paragraph [textStyle]="'Body2'" color="var(--gray-50)"
      ><span [innerHTML]="halfSheetSettings.data.subText || '' | translate"></span
    ></bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        [ngStyle]="{ 'background-color': 'var(--error)' }"
        [brightsideWebFb]="displayAnalytics.submit"
        (click)="onPrimaryClick()"
      >
        {{ halfSheetSettings.data.primaryCta.label || 'OK' | translate }}
      </button>
      <a bw-anchor [brightsideWebFb]="displayAnalytics.dismiss" (click)="onSecondaryClick()" style="margin: 0 16px">{{
        halfSheetSettings.data.secondaryCta.label || 'BUTTON_CANCEL' | translate
      }}</a>
    </div>
  </footer>
</bw-modal>
