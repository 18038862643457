<div class="content-layout" [brightsideWebFb]="{ event_name: fbEventName + '_shown' }">
  <bw-action-article>
    <ui-nav-exit-back
      header-actions
      [trackingDetail]="{ event_name: fbEventName + '_dismissed' }"
      (exitActionHandler)="handleTryAnotherSecondaryCtaClick()">
    </ui-nav-exit-back>

    <div pre-title>
      <bw-steps
        *ngIf="pageStepperActiveIndex >= 0"
        styleClass="mb-md"
        [model]="pageStepperItems"
        [readonly]="true"
        [activeIndex]="pageStepperActiveIndex"
      ></bw-steps>
    </div>
    <div header-title class="mb-sm">
      <typography title-medium>{{ pageTitle }}</typography>
    </div>
    <div content-section>
      <p class="secondary-text mb-md">{{ pageSubTitle }}</p>

      <form [formGroup]="routingForm" autocomplete="off">
        <div class="form-grid">
          <bw-input
            [isCustomControl]="true"
            label="{{ 'MICRO_VERIFY_ACCOUNT_AMOUNT_LABEL' | translate: { counter: 1 } }}"
            [inputError]="(amountOne?.invalid && amountOne?.touched) || showError"
            inputErrorText="{{ 'MICRO_VERIFY_ACCOUNT_DEPOSIT_AMOUNT_ERROR' | translate }}"
          >
            <input type="text" class="body" formControlName="amountOne" currencyMask [options]="{ prefix: '$', align: 'left' }" />
          </bw-input>

          <bw-input
            [isCustomControl]="true"
            label="{{ 'MICRO_VERIFY_ACCOUNT_AMOUNT_LABEL' | translate: { counter: 2 } }}"
            [inputError]="(amountTwo?.invalid && amountTwo?.touched) || showError"
            inputErrorText="{{ 'MICRO_VERIFY_ACCOUNT_DEPOSIT_AMOUNT_ERROR' | translate }}"
          >
            <input type="text" class="body" formControlName="amountTwo" currencyMask [options]="{ prefix: '$', align: 'left' }" />
          </bw-input>
        </div>
      </form>
    </div>
    <div footer-actions class="flex-row row-reverse">
      <button
        *ngIf="pageCtaLabel"
        bw-button
        type="submit"
        [disabled]="routingForm.invalid"
        [processing]="processing"
        (click)="handleCtaClick()"
      >
        {{ pageCtaLabel }}
      </button>

      <typography text-large-semibold>
        <button *ngIf="pageSecondaryCtaLabel" bw-link-button class="bs-blue brand pr-xl" (click)="handleSecondaryCtaClick()">
          {{ pageSecondaryCtaLabel }}
        </button>
      </typography>
    </div>
  </bw-action-article>
</div>

<bw-modal *ngIf="showResend" modalWidth="medium">
  <typography title-medium color="var(--bs-dark-gray)" class="mb-sm">{{
    'MICRO_VERIFY_ACCOUNT_SHOW_RESEND_TITLE' | translate
  }}</typography>
  <section class="body">
    <bw-paragraph>
      <typography text-large-regular color="var(--primary-text)">{{ resendLineWithDate }}</typography>
    </bw-paragraph>
    <bw-paragraph>
      <typography text-large-regular color="var(--primary-text)">{{
        'MICRO_VERIFY_ACCOUNT_SHOW_RESEND_TEXT_LINE_1' | translate
      }}</typography>
    </bw-paragraph>
    <bw-paragraph>
      <typography text-large-regular color="var(--primary-text)">{{
        'MICRO_VERIFY_ACCOUNT_SHOW_RESEND_TEXT_LINE_2' | translate: { resendTimesLeft: this.resendTimesLeft }
      }}</typography>
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button *ngIf="resendPrimaryCta" bw-button [processing]="processing" (click)="handleResendCtaClick()">
        {{ resendPrimaryCta }}
      </button>

      <button *ngIf="resendSecondaryCta" bw-link-button class="bs-blue pr-xl" (click)="showResend = false">
        {{ resendSecondaryCta }}
      </button>
    </div>
  </footer>
</bw-modal>

<bw-modal *ngIf="showTryAnotherWay" modalWidth="medium">
  <typography title-medium color="var(--bs-dark-gray)" class="mb-sm">{{
    'MICRO_VERIFY_ACCOUNT_SHOW_ANOTHER_WAY_TITLE' | translate
  }}</typography>
  <section class="body">
    <bw-paragraph>
      <typography text-large-regular color="var(--primary-text)">
        {{ 'MICRO_VERIFY_ACCOUNT_SHOW_ANOTHER_WAY_TEXT_LINE_1' | translate }}
      </typography>
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button bw-button [processing]="processing" (click)="handleTryAnotherCtaClick()">
        {{ 'MICRO_VERIFY_ACCOUNT_FOOTER_CTA' | translate }}
      </button>

      <button *ngIf="resendSecondaryCta" bw-link-button class="bs-blue pr-xl" (click)="handleTryAnotherSecondaryCtaClick()">
        {{ 'MICRO_VERIFY_ACCOUNT_FOOTER_SECONDARY_CTA' | translate }}
      </button>
    </div>
  </footer>
</bw-modal>
