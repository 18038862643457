import {UiHeaderType} from '@micro-ui/molecule';
import {UiTemplateContentGroupCell, UiTemplateContentType} from '@micro-ui/template/content';
import {UiTemplateFormFlyerDataBuilder, UiTemplateNavType} from '@micro-ui/template/page';

import {MicroContestAnalyticsEventName, MicroContestTranslationKey} from '../../model/contest.enum';

const TemplateData = UiTemplateFormFlyerDataBuilder({
  inputs: {
    navType: UiTemplateNavType.NONE,
    header: {
      type: UiHeaderType.EYE_BROW,
      title: MicroContestTranslationKey.CONTEST_AMOE_LEGAL_TITLE,
      subTitle: MicroContestTranslationKey.CONTEST_AMOE_LEGAL_SUB_TITLE,
    },
    content: [
      {
        type: UiTemplateContentType.TITLE_TEXT,
        data: {
          icon: '',
          title: 'PLS_LEGAL_CONTENT_TITLE',
          //Subtile is here because there seems to be an issue with how copy is loaded. At times it's not ready
          //for the resolver function
          subTitle: MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_SUB_TITLE,
        },
      },
      {
        type: UiTemplateContentType.GROUP_CELL,
        data: {
          groupName: 'contest.legal.amoe',
          options: [
            {
              label: MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_FNAME_LABEL,
              value: 'fName',
            },
            {
              label: MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_LNAME_LABEL,
              value: 'lName',
            },
            {
              label: MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_PHONE_LABEL,
              value: 'phone',
            },
          ],
        } as UiTemplateContentGroupCell,
      },
    ],
    formContent: [],
    viewName: MicroContestAnalyticsEventName.ENTRY_COMPLETE,
  },
  actions: {},
});

export default TemplateData;
