import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import {
  BsCacheService as Cache
} from '@brightside-web/desktop/data-access/core-services';
import { Environment } from '@brightside-web/micro/core/environment';
import { AuthService, AuthSsoService, FirebaseService } from '@brightside-web/desktop/data-access/shared';
import { RoutingStateService, ToastService, Toast, ToastType } from '@brightside/brightside-ui-services';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SSOAuthGuard implements CanLoad {
  private allowLoad = true;
  private blockLoad = false;

  constructor(
    private env: Environment,
    private authService: AuthService,
    private router: Router,
    private firebase: FirebaseService,
    public toastService: ToastService
  ) {}

  // private didUseSSOBefore() {
  //   const keyValue = Cache.getItem('loggedIntoSSO');
  //
  //   return keyValue === true;
  // }

  private isNotFromSSOResponse(): boolean {
    const parts: { [key: string]: string } = AuthSsoService.getRouteFragmentAsObject(this.router);

    return !parts['access_token'] || !parts['id_token'] || !parts['expires_in'];
  }

  private getErrorStatus(): null | { statusCode?: number } {
    const possibleErrors: { error_description?: string; error?: string } = AuthSsoService.getRouteFragmentAsObject(this.router);
    const errorStatusString = possibleErrors.error_description?.split('failed+with+error')[1] || '';
    const errorStatus = JSON.parse(errorStatusString.replace('+', '').replace('.+', ''));

    return errorStatus || null;
  }

  private isFromSSOError(): boolean {
    return AuthSsoService.getRouteFragment(this.router)?.includes('error_description') || false;
  }

  /**
   * default to success, pass error code in if it fails.
   * @param action
   * @param errorCode
   */
  logEvent(action="success",errorCode:string='') {
    let custom = null;
    if ( errorCode ) {
      custom = {value: errorCode };
    }
    this.firebase.logEvent("debug", { custom, action, page: "global", subCategory: "sso", category: "sign_in"});
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const redirectToSSOLogin = AuthSsoService.getRedirectTo(this.env.oauth);

    return this.authService.hasCurrentUser().pipe(
      map((hasUser: any) => {
        if (!hasUser) {
          if (this.isFromSSOError()) {
            const errorStatus = this.getErrorStatus();

            if (errorStatus && errorStatus.statusCode && errorStatus.statusCode > 400) {
              const errorCode = `${errorStatus.statusCode}`;
              this.logEvent("error",errorCode);
              this.router.navigate([], { queryParams: { loginSource: 'SSO', errorCode } });
              return this.blockLoad;
            }

            return this.allowLoad;
          }

          /*  Uncomment this to automatically launch SSO when a client navigates to this page.
          if (!this.didUseSSOBefore() && redirectToSSOLogin) {
            window.location.assign(redirectToSSOLogin);
            return this.blockLoad;
          }
          */
        }

        return this.allowLoad;
      }),
      switchMap(async (status: boolean) => {
        if (status && !this.isNotFromSSOResponse()) {
          const parts: { [key: string]: string } = AuthSsoService.getRouteFragmentAsObject(this.router);

          const company = AuthSsoService.getSubDomain();
          const provider = this.firebase.getValueAsString(`sso_${company}`);
          this.logEvent("submit");
          await this.authService.sso(
            provider,
            parts['id_token'],
            parts['access_token'],
            +parts['expires_in']
          );
          this.logEvent("success");
        }

        return status;
      })
    );
  }
}
