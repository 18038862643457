import {Injectable} from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route, Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree
} from "@angular/router";
import {Environment} from '@brightside-web/micro/core/environment';
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {AuthService} from '@brightside-web/desktop/data-access/shared';
import {
  MessageBusEventUtil,
  MessageBusInternalService,
  MessageBusOutgoingEventKey
} from '@brightside-web/micro/core/message-bus';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate, CanLoad {
  constructor(private environment: Environment, private authService: AuthService, private router: Router) {
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.hasCurrentUser().pipe(
      tap(hasUser => {
        if (!hasUser && !this.environment.production) {
          this.authService.redirectUrl = window.location.pathname;
          MessageBusInternalService.sendOutgoingHubEvent(
            {
              event: MessageBusOutgoingEventKey.EXIT_VIEW,
              bridgeSetting: {
                data: {},
              },
              fallbackSetting: {
                routeToUrl: '/log-in',
              },
            }
          );
        }
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.hasCurrentUser().pipe(
      tap(hasUser => {
        if (!hasUser && !this.environment.production) {
          this.authService.redirectUrl = window.location.pathname;
          MessageBusInternalService.sendOutgoingHubEvent(
            {
              event: MessageBusOutgoingEventKey.EXIT_VIEW,
              bridgeSetting: {
                data: {},
              },
              fallbackSetting: {
                routeToUrl: '/log-in',
              },
            }
          );
        }
      })
    );
  }
}
