<div class="mb-md">
  <h2 headline-semibold class="mb-xs">{{ 'MICRO_AUTOSAVE_FREQUENCY_TITLE' | translate }}</h2>
  <bw-radio
    id="frequencyEveryPaycheck"
    value="everyPaycheck"
    label="{{ 'MICRO_AUTOSAVE_FREQUENCY_LABEL_1' | translate }}"
    name="selectedFormFrequency"
    [startSelected]="startThisSelected === 'everyPaycheck'"
    [radioList]="true"
    (radioClick)="handleRadioClicked($event.value)"
  ></bw-radio>
  <bw-radio
    id="frequencyEveryOtherPaycheck"
    value="alternatePaycheck"
    label="{{ 'MICRO_AUTOSAVE_FREQUENCY_LABEL_2' | translate }}"
    name="selectedFormFrequency"
    [startSelected]="startThisSelected === 'alternatePaycheck'"
    [radioList]="true"
    (radioClick)="handleRadioClicked($event.value)"
  ></bw-radio>
</div>
