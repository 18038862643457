import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { BrightsideUiModule } from '@brightside/brightside-ui';
import { BrightsideUiServicesModule } from '@brightside/brightside-ui-services';
import { MicroUiTemplatePageModule } from '@micro-ui/template/page';
import { SharedDesktopModule } from '@brightside-web/shared/desktop';
import { MicroUiMoleculeModule } from '@micro-ui/molecule';

import { NgxCurrencyModule } from 'ngx-currency';

import { AccountAddComponent } from './add/add.component';
import { AccountVerifyComponent } from './verify/verify.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { AccountHeaderComponent } from './account-header/account-header.component';
import { TransactionCellComponent } from './transaction-cell/transaction-cell.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { AccountStatementsComponent } from './account-statements/account-statements.component';

@NgModule({
  imports: [
    BrightsideUiModule,
    BrightsideUiServicesModule,
    CommonModule,
    FormsModule,
    MicroUiMoleculeModule,
    MicroUiTemplatePageModule,
    NgxCurrencyModule,
    ReactiveFormsModule,
    SelectDropDownModule,
    SharedDesktopModule,
    TranslateModule,
  ],
  providers: [TranslatePipe],
  declarations: [AccountAddComponent, AccountVerifyComponent, AccountHeaderComponent, TransactionCellComponent, TransactionHistoryComponent, AccountStatementsComponent],
  exports: [AccountAddComponent, AccountVerifyComponent, AccountHeaderComponent, TransactionCellComponent,TransactionHistoryComponent, AccountStatementsComponent],
})
export class SharedAccountModule {}
