import { Injectable } from '@angular/core';
import {
  CognitoUserSession,
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool} from 'amazon-cognito-identity-js';

import {
  BsAuthService,
  BsHubService
} from "@brightside-web/desktop/data-access/core-services";
import * as Sentry from '@sentry/angular-ivy';

import {
  HubCapsule,
  HubPayload,
  MessageBusEventChannel,
  MessageBusIncomingEventKey,
  MessageBusInternalEventKey,
  MessageBusInternalService,
} from '@micro-core/message-bus';
import { MicroUtilDebugLogger } from '@micro-core/utility';

import { AmplitudeService, FirebaseService } from '@brightside-web/desktop/data-access/shared';

import { InjectedInformation } from '../models/Injected-data.interface';
import {FeatureFlagService} from "@brightside-web/desktop/data-access/core-services";
import {Environment} from "@micro-core/environment";

declare global {
  interface Window {
    Injected: InjectedInformation;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService extends MicroUtilDebugLogger {
  private _authToken: string;
  private _cognitoUserSession: CognitoUserSession;
  private _userPool: CognitoUserPool;

  static checkSessionValidity(expireAt: string): boolean {
    if (!expireAt) return false;

    return true;
  }

  constructor(
    private amplitudeService: AmplitudeService,
    protected analytics: FirebaseService,
    private featureFlagService: FeatureFlagService,
    private bsAuthService: BsAuthService,
    private bsHubService: BsHubService,
    private env: Environment) {
    super();

    this._userPool = new CognitoUserPool({
      UserPoolId: this.env.awsmobile.aws_user_pools_id,
      ClientId: this.env.awsmobile.aws_user_pools_web_client_id,
    });

    this.allowVerboseDebugMode = true;
    this.logPrefix = 'AuthorizationService - ';

    this.logForDebugging('AuthorizationService Creating');

    if (!window.Injected) {
      window.Injected = {};
    }

    this.startHubListenerForNewAuthToken();
    this.startInitAuthorize();
    this.checkCurrentAuthenticatedUser();
  }

  get injectedData(): InjectedInformation {
    if (!(window as any).Injected) return { authToken: '', version: '9.9.9' };

    return window.Injected;
  }

  get tokenPayload() {
    if (!this._cognitoUserSession) return {};

    return this._cognitoUserSession.getIdToken().decodePayload();
  }

  get userGuid(): string {
    return this.tokenPayload['custom:guid'] || '';
  }

  /**
   * Will look to the client's token first to fine locale and fall back
   * to anything passed inside Injected otherwise.
   *
   * This checks for: userAttributes.locale - Fallback to window.Injected?.locale
   */
  get userLocale(): string {
    const localeFromAuth = this.userAttributes.locale;
    const returnLocale = localeFromAuth || window.Injected?.locale || '';

    return returnLocale;
  }

  get userAttributes(): { [id: string]: any } {
    return this.tokenPayload || [];
  }

  get authToken(): string {
    return window.Injected?.authToken || '';
  }

  get accessToken(): string {
    return window.Injected?.token?.accessToken || '';
  }

  get idToken(): string {
    return window.Injected?.token?.idToken || '';
  }

  get refreshToken(): string {
    return window.Injected?.token?.refreshToken || '';
  }

  /**
   * Sends a internal hub event MessageBusInternalEventKey.AUTH_UPDATED
   */
  private broadcastAuthChange(user: unknown) {
    MessageBusInternalService.sendInternalHubEvent({
      event: MessageBusInternalEventKey.AUTH_UPDATED,
      data: { user },
    });
  }

  private startHubListenerForNewAuthToken() {
    this.logForDebugging(`Hub Started for events (${MessageBusIncomingEventKey.AUTH})`);

    MessageBusInternalService.addHubListenerWithEventFilter({
      channel: MessageBusEventChannel.INCOMING,
      filterByEvents: [MessageBusIncomingEventKey.AUTH],
      callbackListener: (payload: HubPayload) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const authToken = payload.data?.body?.value as string;

        this.logForDebugging(`${MessageBusIncomingEventKey.AUTH} event hit payload`, payload);

        if (window.Injected && authToken) {
          window.Injected.authToken = authToken;
        }

        this.tryToAuthorizeWithLegacy();
      },
    });
  }

  private checkCurrentAuthenticatedUser() {
    this.logForDebugging('triggering check current authenticated user');

    this.bsHubService.listen('auth', (data: HubCapsule<any, any>) => {
      this.logForDebugging(`Auth events triggered payload`, data);
      this.broadcastAuthChange(data.payload.data);
    });

    this.bsAuthService.currentAuthenticatedUser()
      .then((user /* : CognitoUser type*/) => {
        if (user) {
          this.bsAuthService.currentUserInfo().then(
            attributes => {
              if (attributes && attributes['custom:guid']) {
                this.logForDebugging(`Found current authenticated user, details here`, attributes);

                //Done so it will be picked up for future locale checks on this.userLocale
                window.Injected.locale = attributes.locale;

                this.setUserInfoToAmplitude(attributes['custom:guid'], attributes as { [key: string]: string });
                this.setUserInfoToAnalytics(attributes['custom:guid'], attributes as { [key: string]: string });
                Sentry.setUser({id:attributes['custom:guid']});
                this.broadcastAuthChange(attributes);
              } else {
                console.log('user doesn\'t have attributes');
              }
            }
          ).catch((err) => {
            this.logForDebugging(`currentUserInfo Errors`, err);
          });
        } else {
          console.log('No authenticated user');
        }

      })
      .catch((err) => {
        this.logForDebugging(`currentAuthenticatedUser Errors`, err);
      });
  }

  private setUserInfoToAmplitude(guid: string, userProperties: { [key: string]: string }) {
    const passThrough = {
      company: userProperties['custom:company'],
      guid,
    };

    this.logForDebugging(`Set up amplitude with`, passThrough);

    this.amplitudeService.setUserId(guid);
    this.amplitudeService.setUserProperties(passThrough);
  }

  private setUserInfoToAnalytics(guid: string, userProperties: { [key: string]: string }) {
    const passThrough = {
      company: userProperties['custom:company'],
      guid,
    };

    this.logForDebugging(`Set up analytics with`, passThrough);

    this.analytics.setUserId(guid);
    this.analytics.setProperties(passThrough);
  }

  private startInitAuthorize() {
    this.logForDebugging(`Kicked off init authorize check`);

    if (!this.tryToAuthorizeWithToken()) {
      //If we don't have the newer token object, fallback to legacy
      this.tryToAuthorizeWithLegacy();
    }

    if (this._cognitoUserSession) {
      // this is necessary to load the session into the Auth service so we can call Auth.currentAuthenticatedUser

      const currentUser = new CognitoUser({
        Username: this._cognitoUserSession.getIdToken().decodePayload()['cognito:username'],
        Pool: this._userPool,
      });
      const userPayload = this._cognitoUserSession.getIdToken().decodePayload();

      this.logForDebugging(`Trying to create user and payload`);
      this.logForDebugging(`Current user`, JSON.stringify(currentUser));
      this.logForDebugging(`User payload`, JSON.stringify(userPayload));

      currentUser.setSignInUserSession(this._cognitoUserSession);

      this.setUserInfoToAmplitude(userPayload['custom:guid'], userPayload);
      this.setUserInfoToAnalytics(userPayload['custom:guid'], userPayload);
      Sentry.setUser({id:userPayload['custom:guid']});
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.featureFlagService.initializeFeatureFlag(userPayload);
      this.broadcastAuthChange(userPayload);
    }
  }

  private tryToAuthorizeWithToken(): boolean {
    if (!this.refreshToken) {
      this.logForDebugging(`Trying to authorize with token but missing refresh token`);

      return false;
    }

    this.updateUserSessionToken();

    return true;
  }

  private tryToAuthorizeWithLegacy() {
    if (!this.authToken) {
      this.logForDebugging(`Trying legacy authorize but auth token NOT present`);

      return;
    }

    this.updateUserSessionLegacy();
  }

  public updateUserSessionToken() {
    this.logForDebugging(`Trying to update user session token`);

    this._cognitoUserSession = new CognitoUserSession({
      IdToken: new CognitoIdToken({ IdToken: this.idToken }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: this.refreshToken || '',
      }),
      AccessToken: new CognitoAccessToken({
        AccessToken: this.accessToken || '',
      }),
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // this.featureFlagService.initializeFeatureFlag(this._cognitoUserSession.payload);



    this.logForDebugging(`Results Token cognitoUserSession`, JSON.stringify(this._cognitoUserSession));
  }

  public updateUserSessionLegacy() {
    this.logForDebugging(`Trying legacy user session authorize`);

    this._authToken = this.authToken;
    this._cognitoUserSession = new CognitoUserSession({
      IdToken: new CognitoIdToken({ IdToken: this.authToken }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: '',
      }),
      AccessToken: new CognitoAccessToken({
        AccessToken: '',
      }),
    });

    this.logForDebugging(`Results Legacy cognitoUserSession`, this._cognitoUserSession);
  }
}
