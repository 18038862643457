<ng-container *ngIf="!showConfirm">
  <bw-action-article
    *ngIf="(email$ | async) as email; else spinner"
    [brightsideWebFb]="{ event_name: 'email_verify_prompt' }"
  >
    <div pre-title>
      <img src="../assets/confirm-email.png">
    </div>
    <typography header-title title-medium>{{ 'VERIFY_EMAIL_TITLE' | translate }}</typography>
    <div content-section>
      <bw-paragraph>
        <div [innerHTML]="'VERIFY_EMAIL_DESC' | translate:{email:email|maskEmail}"></div>
      </bw-paragraph>
      <a bw-anchor
         class="mb-sm"
         [anchorUnderline]="true"
         (click)="resendVerificationEmail()">
        {{'VERIFY_EMAIL_RESEND' | translate}}
      </a>
      <br/>
      <a bw-anchor
         [anchorUnderline]="true"
         (click)="showChangeEmail()">
        {{'EMAIL_UPDATE_TITLE' | translate}}
      </a>
    </div>
    <div footer-actions class="flex-row flex-item-flexible space-between">
      <div class="flex-row row-reverse">
        <button
          bw-button
          type="submit"
          [brightsideWebFb]="{ event_name: 'email_verify_prompt'}"
          (click)="showConfirm = true"
        >
          {{ 'BUTTON_CONTINUE' | translate }}
        </button>
      </div>
    </div>
  </bw-action-article>
</ng-container>

<bw-action-article
  *ngIf="showConfirm"
  [brightsideWebFb]="{ event_name: 'email_verify_confirm' }"
>
  <ui-nav-exit-back
    back-action-top
    [skipActionEmit]="true"
    (exitActionHandler)="showConfirm = false"
  ></ui-nav-exit-back>
  <div pre-title>
    <img src="../assets/verifying-email.png">
  </div>
  <typography header-title title-medium>{{ 'EMAIL_VERIFY_CONFIRM_TITLE' | translate }}</typography>
  <div content-section>
    <bw-paragraph>
      <div [innerHTML]="'EMAIL_VERIFY_CONFIRM_DESC' | translate"></div>
    </bw-paragraph>
  </div>
  <div footer-actions class="flex-row flex-item-flexible space-between">
    <div class="flex-row row-reverse">
      <button
        bw-button
        type="submit"
        [brightsideWebFb]="{ event_name: 'email_verify_confirm'}"
        [processing]="verificationProcessing"
        (click)="runVerification()"
      >
        {{ 'BUTTON_CONTINUE' | translate }}
      </button>
    </div>
  </div>
</bw-action-article>

<ng-template #changeEmailModal>
  <ng-container class="body">
    <bw-paragraph>
      {{ 'EMAIL_UPDATE_DESCRIPTION' | translate }}
    </bw-paragraph>
  <form [formGroup]="changeEmailForm" (ngSubmit)="submitNewEmail()">
    <bw-input type="email"
              formControlName="email"
              [label]="'EMAIL_ADDRESS' | translate"
              required="true"
              fcName="email"
              [inputError]="changeEmailForm.controls['email']?.invalid && changeEmailForm.controls['email']?.touched"
              [inputErrorText]="activeErrorMsgs.email | translate"
    ></bw-input>
    <bw-input type="email"
              formControlName="emailComp"
              [label]="'CONFIRM_EMAIL_ADDRESS' | translate"
              required="true"
              fcName="emailComp"
              [inputError]="changeEmailForm.controls['emailComp']?.invalid && changeEmailForm.controls['emailComp']?.touched"
              [inputErrorText]="activeErrorMsgs.emailComp | translate"
    ></bw-input>
    <div class="flex-row mb-sm" style="justify-content: end">
      <button
        bw-button
        type="submit"
        [disabled]="!changeEmailForm.valid"
        [processing]="emailProcessing"
        (click)="submitNewEmail()"
      >
        {{ 'SAVE' | translate }}
      </button>
    </div>
  </form>
  </ng-container>
</ng-template>

<ng-template #spinner>
  <bw-spinner></bw-spinner>
</ng-template>
