import { Injectable } from '@angular/core';
import {forkJoin, from, Observable, of} from "rxjs";
import {ApiCacheService} from "./api-cache.service";
import {EligibilityFieldsObject} from "./api-contracts";

import {
  BsCacheService
} from "@brightside-web/desktop/data-access/core-services";

export interface ProdEligResponseInterface {
  products: ProdEligObjInterface[]
}

export interface ProdEligObjInterface {
  name: string;
  required_fields?: ProdEligReqFieldsInterface[]
}

export interface ProdEligReqFieldsInterface {
  name: string;
  api_parameter_name: string;
  unique_identifier_flag?: boolean;
  sub_fields?: EligibilityFieldsObject[];
  not_replayable?: boolean;
  value?: string;
}

export enum ProductPostIntroMapping {
  '/external-accounts/intro/launch' = 'cashFlow',
  '/home/savings/consents' = 'payrollSavings',
  '/credit-setup/consents' = 'creditScore',
  '/loans/consents' = 'loan'
}

@Injectable({
  providedIn: 'root'
})
export class ProductEligibilityService {

  completedVerifications: string[] = [];
  alreadyCompleted: boolean;

// TODO: Ken check this
  constructor(
    private apiCache: ApiCacheService,
    private bsCacheService: BsCacheService
  ) {
    const routeKeys = Object.keys(ProductPostIntroMapping);
    const routeKeyCache$: Observable<any>[] = [];
    routeKeys.forEach(cacheKey => {
      routeKeyCache$.push(from(this.bsCacheService.getItem(cacheKey)));
    })

    forkJoin(routeKeyCache$).subscribe(
      (values) => {
        for (let valuesKey in values) {
          if(valuesKey) this.alreadyCompleted = true;
        }
      }
    )

  }

  getProductEligibility() : Observable<ProdEligResponseInterface>{
    const path = '/client/product/data-collect?fieldVersion=1.1';
    return from(this.apiCache.get<ProdEligResponseInterface>('api-mobile', path, {}));
  }

  didAlreadyFinish(): boolean {
    return this.alreadyCompleted;
  }

  getProductCodeRedirect(productCode: string): string {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const redirectPath = Object.keys(ProductPostIntroMapping)[Object.values(ProductPostIntroMapping).indexOf(productCode)];
    return redirectPath ? redirectPath : '/';
  }

}
