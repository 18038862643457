import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import {FinancialAccountsCreationService} from "./services/financial-accounts-creation.service";

@Injectable()
export class FinancialAccountCreationGuard implements CanActivate {

  constructor(
    private faCreateSvc: FinancialAccountsCreationService,
    private router: Router
    ) {
  }

  /**
   * Don't allow users to access creation steps ahead of their current step
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (route.data['creationStep']) {
      if (this.faCreateSvc.isStepBeforeCurrent(route.data['creationStep'])) {
        return true;
      }
    }
    this.router.navigate(['home']);
    return false;
  }

}
