<ng-container *ngIf="displayPage">
  <brightside-web-display-campaign
    *ngIf="showPreFormCampaign"
    [campaign]="preFormCampaign"
    [analyticsAttributes]="{event_name: 'verify_intro'}"
    (campaignEvent)="handleEventClick($event)">
  </brightside-web-display-campaign>
  <section *ngIf="showForm" class="content-layout">
    <brightside-web-badge-id-form
      [minHeight]="0"
      [centerAlignCaption]="false"
      [displayBackButton]="true"
      [submitCtaCopy]="'Submit'"
      (aliasSubmitted)="handleAliasSubmitted($event)"
      (backClicked)="handleBackClick($event)">
    </brightside-web-badge-id-form>
  </section>
  <brightside-web-display-campaign
    *ngIf="showPostFormCampaign"
    [campaign]="postFormCampaign"
    [analyticsAttributes]="{event_name: 'verify_outro'}"
    (campaignEvent)="handleEventClick($event)">
  </brightside-web-display-campaign>
</ng-container>
