import { Injectable } from '@angular/core';
import { from, Observable, of, Subject } from 'rxjs';
import {
  BsAuthService,
  BsCacheService,
  BsHubService,
  BsLoggerService,
} from '@brightside-web/desktop/data-access/core-services';
import { ConsoleLogger } from 'aws-amplify/utils'
import { catchError, map } from 'rxjs/operators';
import { FirebaseService } from './firebase.service';
import { HubCapsule } from 'aws-amplify/utils';
import { AuthSsoService } from './auth-sso.service';
import { AmplitudeService } from "./amplitude.service";
import * as Sentry from '@sentry/angular-ivy';
import {FeatureFlagService} from "@brightside-web/desktop/data-access/core-services";
import {CognitoAttributes} from "@brightside-web/shared/registration";

declare const Sprig:Function;


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userLoggedIn = new Subject<boolean>();
  redirectUrl: string;

  username: string | undefined;
  password: string | undefined;
  company: string | undefined;

  setRedirectUrl(path: string) {
    this.redirectUrl = path;
  }

  parseJwt(token: string): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  async sso(provider: string, idToken: string, accessToken: string, expiresIn: number) {
    const parsedToken = this.parseJwt(idToken);
    const email = parsedToken['email'];
    const name = parsedToken['cognito:username'];

    const response = {
      token: accessToken,
      identity_id: name,
      expires_at: Date.now() + expiresIn * 1000,
    };

    const user = {
      email,
      name,
    };

    const signInOptions = {
      customProvider: provider,
    };

    this.bsCacheService.setItem('loggedIntoSSO', true);

    // await Auth.federatedSignIn(
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //   // @ts-ignore
    //   signInOptions,
    //   response,
    //   user
    // );
  }
  logger: ConsoleLogger;
  constructor(
    private analytics: FirebaseService,
    private amplitudeService: AmplitudeService,
    private featureFlagSvc: FeatureFlagService,
    private bsAuthService: BsAuthService,
    private bsCacheService: BsCacheService,
    private bsHubService: BsHubService,
    private bsLoggerService: BsLoggerService,) {
    this.bsAuthService.fetchUserAttributes()
      .then((attributes) => {
        const userAtts = attributes as unknown as CognitoAttributes;
        this.featureFlagSvc.initializeFeatureFlag(userAtts);
        this.userLoggedIn.next(true)
      })
      .catch((err) => this.userLoggedIn.next(false));

    this.logger = new ConsoleLogger('My-Logger');
    const listener = (data: HubCapsule<any, any>) => {

      switch (data.payload.event) {
        case 'signedIn':

          this.logger.info('user signed in' as any);

          this.bsAuthService.fetchUserAttributes().then(
            attributes => {
              this.bsHubService.dispatch('AuthChannel', {event: 'logged in', data: attributes});
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this.handleSignIn(attributes['custom:guid']);
              this.setFirebaseProperties(attributes as unknown as { [key:string]:string });
              this.setAmplitudeProperties(attributes as unknown as { [key:string]:string });
              this.setUserLoggedIn(true);
              Sentry.setUser({id:attributes['custom:guid']});
              this.featureFlagSvc.initializeFeatureFlag(attributes as unknown as CognitoAttributes);
            }
          )
          break;
        case 'signUp':
          this.logger.info('user signed up' as any);
          break;
        case 'oAuthSignOut':
        case 'signedOut':
          // if ( Sprig ) {
          //   Sprig('logoutUser');
          // }
          this.bsCacheService.removeItem("GUID");
          this.logger.info(`user ${data.payload.event}` as any);
          this.analytics.clearProperties();
          this.amplitudeService.clearProperties();
          this.setUserLoggedIn(false);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          Sentry.setUser(null);
          this.bsHubService.dispatch('AuthChannel', {event: 'logged out'});
          this.featureFlagSvc.disableClient();
          break;
        case 'signIn_failure':
          this.logger.info('user sign in failed' as any);
          break;
        case 'configured':
          this.logger.info('the Auth module is configured' as any);
          break;
        default:
          this.logger.info(`unhandled Auth event: ${data.payload.event}` as any);
      }
    };

    this.bsHubService.listen('auth', listener);
  }



  private handleSignIn(guid: string) {
    this.bsCacheService.setItem("GUID",guid);

    const now = Date.now();

    const previousSignIn = window.localStorage[guid];

    this.bsCacheService.getItem("SIGN_IN_TIME").then(
      signInTime => {
        if (!previousSignIn || previousSignIn === signInTime) {
          window.localStorage[guid] = now;
          this.bsCacheService.setItem("SIGN_IN_TIME",now);
          this.bsCacheService.setItem("FIRST_SIGN_IN",true);
        }
        else {
          this.bsCacheService.setItem("FIRST_SIGN_IN",false);
        }
      }
    )

  }

  /**
   * want to set more than just guid.
   * @param properties
   */
  setFirebaseProperties(properties: { [key:string]:string }) {
    this.analytics.setUserId(properties['custom:guid']);
    const passThrough = {
      company: properties['custom:company']
    }
    this.analytics.setProperties(passThrough);

    this.analytics.setupRemoteConfig();
  }

  setAmplitudeProperties(properties: { [key:string]:string }) {
    this.amplitudeService.setUserId(properties['custom:guid']);
    const passThrough = {
      company: properties['custom:company']
    }
    this.amplitudeService.setUserProperties(passThrough);
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  isFromSSO(): Observable<boolean> {
    return of(false);
    // return from(
    //   Auth.currentAuthenticatedUser({
    //     bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    //   })
    // ).pipe(
    //   map(user => user.storage['amplify-signin-with-hostedUI'] === 'true' ? true : false),
    //   catchError((err) => of(false))
    // );
  }

  supportsSso(company: string = ''): boolean {
    if ( !company ) {
      company = AuthSsoService.getSubDomain();
    }
    const idProvider = this.analytics.getValueAsString(`sso_${company}`);
    return !!idProvider;
  }

  getProviderName(company:string=''): string {

    if ( !company ) {
      company = AuthSsoService.getSubDomain();
    }

    const friendlyProviderName = this.analytics.getValueAsString(`sso_${company}_provider`);
    return friendlyProviderName || "SSO";
  }

  hasCurrentUser(): Observable<boolean> {
    return from(
      this.bsAuthService.fetchUserAttributes()
    ).pipe(
      map((user) => !!user),
      catchError((err) => of(false))
    );
  }

  clearConfirmationState() {
    this.username = undefined;
    this.password = undefined;
  }
}
