import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {from, Observable, of, switchMap} from 'rxjs';
import {ProductEligibilityService} from "@brightside-web/desktop/data-access/shared";
import {map} from "rxjs/operators";

import {
  BsCacheService,
  BsHubService
} from "@brightside-web/desktop/data-access/core-services";

import {
  LayoutStyle,
  RoutingService,
  RoutingServiceDispatchEvent
} from "../../../../../../apps/desktop/src/app/routing.service";

@Injectable({
  providedIn: 'root'
})
export class UpdateInfoGuard implements CanActivate {

  constructor(
    private router: Router,
    private productEligibilityService: ProductEligibilityService,
    private bsCacheService: BsCacheService,
    private bsHubService: BsHubService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return from(this.bsCacheService.getItem('hasCheckedProductRegistration')).pipe(
      switchMap(cached => {
          if (cached) {
            return of(true);
          } else {
            return this.productEligibilityService.getProductEligibility().pipe(
              map(response => {
                this.bsCacheService.setItem('hasCheckedProductRegistration', true);
                if (response.products) {
                  const productFields = response.products.find(product => product.name.toLowerCase() === 'registration');
                  if (productFields?.required_fields) {
                    this.router.navigate(['verification', 'identity', 'registration']);
                    this.bsHubService.dispatch(RoutingService.DISPATCH_KEY,
                      { event: RoutingServiceDispatchEvent.LAYOUT_CHANGE, message: LayoutStyle.FULL_SCREEN }
                    );
                    return false;
                  }
                }
                return true;
              })
            )
          }
        }
      )
    )

  }

}
