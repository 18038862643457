import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { BsAuthService } from "@brightside-web/desktop/data-access/core-services";
import { from, Subscription } from 'rxjs';
import {AuthService} from "@brightside-web/desktop/data-access/shared";

@Component({
  selector: 'brightside-web-dev-login',
  templateUrl: './dev-log-in.component.html',
  styleUrls: ['./dev-log-in.component.scss'],
})
export class DevLogInComponent implements OnInit, OnDestroy {
  sub = new Subscription();
  processing: any;
  loginForm = new FormGroup({
    'mobile': new FormControl('', [Validators.required]),
    'password': new FormControl('', [Validators.required, Validators.minLength(8)])
  });
  loginError: string;

  constructor(
    public router: Router,
    protected title: Title,
    protected auth: AuthService,
    protected bsAuthService: BsAuthService) {
  }

  ngOnInit() {
    this.title.setTitle('Brightside | Client sign in');
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onSubmit() {
    this.processing = true;
    this.sub.add(
      from(this.bsAuthService.signIn(this.loginForm.controls['mobile'].value ?? '', this.loginForm.controls['password'].value ?? '')).subscribe(
        (_) => {
          if (this.auth.redirectUrl){
              const redirectUrl = this.auth.redirectUrl;
              // we want to clear the redirectUrl now that we have used it (one time only)
              this.auth.redirectUrl = '';
              this.router.navigate([redirectUrl]);
            } else {
              this.router.navigate(['/home']);
            }

        },
        (error) => {
          // see https://healthydollar.atlassian.net/wiki/spaces/EN/pages/759857153/Cognito+Auth for magic string info
          if (error.message && error.message.includes('Brightside:')) {
            this.loginError = error.message.replace('Brightside:', '');
          } else {
            this.loginError = "We don't recognize your phone number and/or password. Please try again.";
          }
          this.processing = false;
        }
      )
    );
  }
}
