import { Injectable } from '@angular/core';
import {
  signIn,
  signOut,
  signUp,
  SignInOutput,
  getCurrentUser,
  fetchAuthSession,
  fetchUserAttributes,
  resendSignUpCode,
  resetPassword,
  confirmResetPassword,
  updatePassword,
  confirmSignUp,
  FetchUserAttributesOutput
} from 'aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class BsAuthService {
  constructor() { }

  currentAuthenticatedUser = getCurrentUser;
  getCurrentUser = getCurrentUser;
  fetchUserAttributes = fetchUserAttributes;
  currentUserInfo = fetchUserAttributes;

  signOut = signOut;
  resendSignUp = resendSignUpCode;
  resetPassword = resetPassword;
  updatePassword = updatePassword;
  confirmResetPassword = confirmResetPassword;
  fetchAuthSession = fetchAuthSession;
  signUp = signUp;
  confirmSignUp = confirmSignUp;

  async signIn(username: string, password: string) : Promise<SignInOutput> {
    const curUser = await this.fetchAuthSession();
    if (curUser.tokens) await this.signOut();
    return signIn({username, password});
  }


}
