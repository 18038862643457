import {Component, Input, OnInit} from '@angular/core';
import {
  FinancialAccountsStatementsService,
  GroomedMonthlyAccountStatements,
  FinancialAccountStatementsResponse
} from "@brightside-web/desktop/feature/financial-accounts-core";
import {Observable} from "rxjs";

@Component({
  selector: 'brightside-web-account-statements',
  templateUrl: './account-statements.component.html',
  styleUrls: ['./account-statements.component.css']
})
export class AccountStatementsComponent implements OnInit {

  @Input() accountId: any

  statements: Observable<GroomedMonthlyAccountStatements[]> = this._statementsService.statements;


  constructor(
    private _statementsService: FinancialAccountsStatementsService
  ) { }

  ngOnInit(): void {
    this._statementsService.getMonthlyStatements();
  }

  downloadStatement($event: any, statementItem: FinancialAccountStatementsResponse) {
    this._statementsService.getDateForStatement(statementItem.statementId);
  }


}
