
  <bw-action-article [brightsideWebFb]="{event_name: 'monthly_statements'}">
    <div header-actions>
      <ui-nav-exit-back
        [trackingDetail]="{ event_name: 'monthly_statements' }"
      ></ui-nav-exit-back>
    </div>
    <div header-title>
      <typography title-large color="var(--primary-text)">
        {{ 'STATEMENTS_TITLE' | translate }}
      </typography>
    </div>
    <div content-section>
      <bw-bui-tile tileMargin="0 0 8px 0" class="mb-sm">
        <bui-tile-card  cardBackground="var(--green-5)">
          <bw-bui-row rightIconName="headset" rightIconColor="green">
            <div class="row-content">
              <bw-bui-column [rowSpacing]="4" [rows]="[
            {type: 'heading', content: 'STATEMENTS_NOTICE_TITLE' | translate, color: 'black'},
            {type: 'paragraph', content: 'STATEMENTS_NOTICE_DESC' | translate, color: 'var(--secondary-text)'}
            ]"></bw-bui-column>
            </div>
          </bw-bui-row>
        </bui-tile-card>
      </bw-bui-tile>
      <ng-container
        *ngIf="(statements|async) as statementsArray; else spinner"
      >
        <ng-container *ngIf="statementsArray.length >0 ;else noStatements">
          <div *ngFor="let year of statementsArray" class="mb-sm">
            <typography text-medium-regular color="var(--secondary-text)">
              {{ year.year }}
            </typography>
            <div>
              <bw-cell
                *ngFor="let statementItem of year.statements"
                (clicked)="downloadStatement($event, statementItem)"
                [labelLeft]="statementItem.month | monthName | uppercase | translate"
                iconNameRight="doc_download"></bw-cell>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </bw-action-article>

<ng-template #spinner>
  <bw-spinner></bw-spinner>
</ng-template>

<ng-template #noStatements>
  <bw-bui-tile
    tileMargin="0 0 8px 0"
    class="mb-sm"
    tileBorder="1px solid var(--gray-10)">
    <bui-tile-card cardBackground="white">
      <bw-bui-row leftIconName="document">
        <div class="row-content">
          <bw-bui-column [rowSpacing]="4" [rows]="[
            {type: 'paragraph', content: 'STATEMENTS_EMPTY_TITLE' | translate, color: 'var(--gray-30)'}
            ]"></bw-bui-column>
        </div>
      </bw-bui-row>
    </bui-tile-card>
  </bw-bui-tile>
</ng-template>
