// DO NOT IMPORT THIS FILE DIRECTLY
// import FirebaseService into your constructors
// otherwise your tests will fail

import { Injectable } from '@angular/core';
import { FirebaseService, RemoteConfig_DesktopNativeMapping } from './firebase.service';
import { AmplitudeService } from "./amplitude.service";
import {Environment} from "@brightside-web/micro/core/environment";
import { BsCacheService } from "@brightside-web/desktop/data-access/core-services";

import { initializeApp, FirebaseApp } from "firebase/app"
import {
  RemoteConfig,
  getRemoteConfig,
  fetchAndActivate,
  getValue,
  ensureInitialized,
  fetchConfig
} from "firebase/remote-config";
import {
  Analytics as FbAnalytics,
  getAnalytics,
  setUserProperties,
  setUserId,
  logEvent
} from "firebase/analytics";
import * as Sentry from "@sentry/angular-ivy";



const defaultMapping = {
  'partner/Credit_Card_Refi': {
    path: 'partner/Credit_Card_Refi',
  },
  'partner/Emergency_Cash': {
    path: 'partner/Emergency_Cash',
  },
  'finsol/deep/savings/autosave/once': {
    path: 'home/deep/savings/intro',
    queryParamsType: 'auto',
  },
  home: {
    path: 'home',
  },
  onboarding: {
    path: 'onboarding',
  },
  setupcredit: {
    path: 'credit-setup/creation',
  },
};

const pageShownAppend = ['video tapped', 'logo tapped'];

interface ThrottledEvent {
  lastCall: number;
  throttleTime: number;
}

/**
 * this class is poorly named, it sets up both Firebase Analytics AND Firebase Remote Config
 */
@Injectable({
  providedIn: 'root',
})
export class FirebaseAnalyticsService implements FirebaseService {
  firebaseApp: FirebaseApp;
  fbRemoteConfig: RemoteConfig;
  fbAnalytics: FbAnalytics;

  events: { [p: string]: ThrottledEvent } = {};
  lastPageShownEvent: string;

  typeOveride: { [p: string]: () => Promise<string> } = {
    credit: async () => (await this.bsCacheService.getItem('ONBOARDING_FLOW_ACTIVE') ? 'onboarding' : 'standalone'),
    'onboarding credit': async () => (await this.bsCacheService.getItem('ONBOARDING_FLOW_ACTIVE') ? 'onboarding' : 'standalone'),
  };

  typeOverRideKeys: string[];

  constructor(
    public amplitudeService: AmplitudeService,
    public env: Environment,
    protected bsCacheService: BsCacheService) {
    this.typeOverRideKeys = Object.keys(this.typeOveride);
    this.firebaseApp = this.initializeApp();
    this.fbRemoteConfig = this.getRemoteConfig();
    this.fbAnalytics = this.getAnalytics();
  }

  initializeApp() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return initializeApp(this.env.firebase);
  }

  getRemoteConfig() {
    return getRemoteConfig(this.firebaseApp);
  }

  getAnalytics() {
    return getAnalytics(this.firebaseApp);
  }

  async logEvent(
    eventName: string,
    eventParams?: { custom: { [p: string]: any } | string; [p: string]: any },
    shouldThrottle: boolean = false,
    throttleTime?: number
  ): Promise<void> {
    if (eventName && eventName !== 'onboardingcredit shown') {
      if (eventName.includes('shown')) this.lastPageShownEvent = eventName.split('shown')[0].trim();
      if (pageShownAppend.includes(eventName)) eventName = `${this.lastPageShownEvent} ${eventName}`;

      const isEventThrottled = shouldThrottle === false ? false : this.isEventThrottled(eventName, throttleTime);

      if (!isEventThrottled) {
        let params = eventParams ?? {};

        if (eventParams?.custom && typeof eventParams?.custom === 'object') {
          eventParams.custom = JSON.stringify(eventParams?.custom);
          params = eventParams.custom;
        }

        for (let i = 0; i < this.typeOverRideKeys.length; i++) {
          if (eventName.startsWith(this.typeOverRideKeys[i])) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            params['type'] = await this.typeOveride[this.typeOverRideKeys[i]]();
            break;
          }
        }

        this.amplitudeService.logEvent(eventName.split('_').join(' '), params);

        logEvent(this.fbAnalytics, eventName, eventParams);
        if (!this.env.production) {
          console.log(`Analytics event fired: ${eventName.split('_').join(' ')} with `, params);
        }
      }
    }
  }

  isEventThrottled(eventName: string, throttleTime?: number): boolean {
    const event = this.events[eventName];
    const currentCall = Date.now();

    if (event) {
      if (currentCall - event.lastCall > event.throttleTime) {
        event.lastCall = currentCall;
        return false;
      } else {
        return true;
      }
    } else {
      this.events[eventName] = {
        lastCall: currentCall,
        throttleTime: throttleTime ? throttleTime : 1000,
      };
      return false;
    }
  }

  setUserId(id: string, options?: any): void {
    setUserId(this.fbAnalytics, id, options);
    this.amplitudeService.setUserId(id);
  }

  clearProperties(): void {
    setUserId(this.fbAnalytics, '');
    setUserProperties(this.fbAnalytics, {});
  }

  setProperties(properties: { [key: string]: string }) {
    setUserProperties(this.fbAnalytics, properties);
  }

  setupRemoteConfig() {
    fetchAndActivate(this.fbRemoteConfig)
      .then(() => {})
      .catch(error => {
        Sentry.captureException(error);
      });
  }

  remoteConfig(): RemoteConfig {
    return this.fbRemoteConfig;
  }

  getValueAsString(valueKey: string): string {
    return getValue(this.fbRemoteConfig, valueKey).asString();
  }

  getValueAsBoolean(valueKey: string): boolean {
    return getValue(this.fbRemoteConfig, valueKey).asBoolean();
  }

  ensureInitialized(): Promise<void> {
    return ensureInitialized(this.fbRemoteConfig);
  }

  fetchConfig(): Promise<void> {
    return fetchConfig(this.fbRemoteConfig);
  }

  remoteConfigMapping(): { [key: string]: any } {
    try {
      return JSON.parse(getValue(this.fbRemoteConfig, RemoteConfig_DesktopNativeMapping).asString()) as { [key: string]: any };
    } catch (e) {
      Sentry.captureException(e);
      return defaultMapping;
    }
    return defaultMapping;
  }
}
