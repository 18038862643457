
<bw-action-article>
  <div header-actions>
    <bw-spinner></bw-spinner>
  </div>
  <div header-title center class="mb-xs">
    <div class="flex-column center full-width">
      <typography title-medium>{{'SMFA_AUTH_TITLE'|translate}}</typography>
    </div>
  </div>
  <div content-section>
    <div class="flex-column center">
      <typography>{{'SMFA_AUTH_DESC'|translate}}</typography>
    </div>
  </div>
</bw-action-article>

