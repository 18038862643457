import { InjectedInformation } from './models/Injected-data.interface';

declare let Injected: InjectedInformation;
declare global {
  interface Window {
    Injected: InjectedInformation;
  }
}

class InjectedDataService {
  private localInjected: InjectedInformation;

  constructor() {
    const getter = () => this.localInjected;
    const setter = (v: InjectedInformation) => {
      this.localInjected = v;

      //Side affects of value changes to injected
      this.checkForAuthToken();
    };

    Object.defineProperty(window, 'Injected', {
      get: getter,
      set: setter,
    });

    //If we already have a value, let's assign it
    if (Injected) window.Injected = Injected;
  }

  private checkForAuthToken() {
    if (this.localInjected.authToken) {
      //ToDo: Need to send this as an incoming event
    }
  }
}

export default new InjectedDataService();
