import { Injectable } from '@angular/core';
import { EmailService } from "@brightside-web/desktop/data-access/email-verify";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {ToastService} from "@brightside/brightside-ui-services";
import {take} from "rxjs/operators";
import { BsCacheService } from "@brightside-web/desktop/data-access/core-services";
import * as moment from "moment";
import {Router} from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class EmailVerificationService {

  _currentEmail$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  currentEmail$: Observable<string> = this._currentEmail$.asObservable();

  _currentEmailVerification$: Subject<boolean> = new Subject<boolean>();
  currentEmailVerification$: Observable<boolean> = this._currentEmailVerification$.asObservable();

  constructor(
    private emailService: EmailService,
    private translateSvc: TranslateService,
    private toastSvc:ToastService,
    private router: Router,
    private bsCacheService: BsCacheService
  ) { }

  checkVerification() {
    this.emailService.get().subscribe( emResp => {
      this._currentEmail$.next(emResp.email);
      this._currentEmailVerification$.next(emResp.verified);
      if (!emResp.verified) {
        this.bsCacheService.getItem('verificationEmailSent').then((recentlySent) => {
          if (!recentlySent) {
            this.bsCacheService.setItem('verificationEmailSent', true, {expires: moment().add(30, 'seconds').valueOf()});
            this.resendVerification(false);
          }
        });
      }
    });
  }

  updateEmail(newEmail: string) {
    if (newEmail) {
      this._currentEmail$.next(newEmail);
      this.resendVerification(false);
    }
  }

  resendVerification(showToast = true) {
    if (this._currentEmail$.value) {
      this.emailService.put(this._currentEmail$.value)
        .pipe(take(1)).subscribe(
        resp => {
          if (showToast) {
            this.toastSvc.success(this.translateSvc.instant('VERIFY_EMAIL_RESENT'));
          }
        }
      )
    }
  }

  sendEmailVerifiedToast() {
    this.toastSvc.success(this.translateSvc.instant('EMAIL_VERIFIED_TOAST'), {id:'emailVerified'});
    this.delayedRouteToHome();
  }
  sendEmailNotVerifiedToast() {
    const email = this.translateSvc.instant('SURVEY_CHOICE_EMAIL');
    const notVerified = this.translateSvc.instant('LABEL_NOT_VERIFIED', {label:email});
    this.toastSvc.warn(notVerified, {id:'emailNotVerified'});
  }

  private delayedRouteToHome() {
    setTimeout(()=>{this.router.navigate(['home']);}, 1000);
  }
}
