<ng-container [ngSwitch]="cardConfig.cardStyle">
  <ng-container *ngSwitchCase="'large'">
    <ng-container *ngTemplateOutlet="imageTop"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'small'">
    <ng-container *ngTemplateOutlet="imageRight"></ng-container>
  </ng-container>
</ng-container>


<ng-template #imageTop>
 Not yet implemented
</ng-template>

<ng-template #imageRight>
  <div class="action-card action-card--image-right flex-row space-between mb-lg" [ngClass]="{'action-card--has-image': cardConfig.image}">
    <div class="flex-column action-card__content">
      <ng-container *ngIf="cardConfig.label">
        <ng-container *ngTemplateOutlet="label"></ng-container>
      </ng-container>
      <typography text-medium-semibold class="mb-sm">{{ cardConfig.title }}</typography>
      <typography text-xsmall-regular>{{ cardConfig.body }}</typography>
    </div>
    <div
      tabindex="1"
      class="action-card__cta flex-column ml-sm"
      (click)="emitCtaClicked()"
      (keyup.enter)="emitCtaClicked()"
      [style.background-image]="'url('+cardConfig.image+')'">
      <div class="action-card__cta_icon"><bw-icon [iconName]="'arrow_right'"></bw-icon></div>
    </div>
  </div>
</ng-template>

<ng-template #label>
  <div class="flex-row action-card__label mb-xs">
    <bw-icon *ngIf="cardConfig.labelIcon" [iconName]="cardConfig.labelIcon" [size]="16" [horizontalSpace]="'mr-xs'"></bw-icon>
    <typography text-xsmall-semibold>{{ cardConfig.label }}</typography>
    <typography *ngIf="cardConfig.labelSub" text-xsmall-regular>&nbsp;-&nbsp;{{ cardConfig.labelSub }}</typography>
  </div>
</ng-template>
