import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SavingsCompositeService } from '@brightside-web/desktop/data-access/savings';
import { CACHE_KEY } from '@brightside-web/shared/desktop';

import {
  BsCacheService
} from '@brightside-web/desktop/data-access/core-services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DeepSavingsScheduleGuard implements CanActivate {
  constructor(
    private savingsCompositeService: SavingsCompositeService,
    private router: Router,
    private bsCacheService: BsCacheService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    //Make sure we clear cache of any one click stuff
    this.bsCacheService.removeItem(CACHE_KEY.deepSavingsSource);
    this.bsCacheService.removeItem(CACHE_KEY.deepSavingsDetails);

    return this.savingsCompositeService.getAccountWithPayroll().pipe(
      map((resp) => {
        //If we have no payroll schedule exit
        if (!resp.payrollSchedule) {
          this.router.navigate(['/home']);
          return false;
        }

        //Check if there is a savings account AND schedule already setup
        if (resp.account && resp.account.next_deposit_amount && state.url && state.url.indexOf('/edit?') === -1) {
          this.router.navigateByUrl(state.url.replace('intro?', 'intro/edit?'));
          return false;
        }

        return true;
      })
    );
  }
}
