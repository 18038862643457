import { Injectable } from '@angular/core';
import * as LaunchDarkly from 'launchdarkly-js-client-sdk';
import { Environment } from "@micro-core/environment";
import { CognitoAttributes } from "@brightside-web/shared/registration";
import {BehaviorSubject, from, Observable, of, Subject, zip} from "rxjs";
import {map, take, takeUntil, takeWhile, tap} from "rxjs/operators";

interface FlagContextInterface {
  "kind": "user",
  "key": string,
  "guid": string,
  "company": string,
  "preferredLanguage": string,
  "version": string
}

abstract class FeatureFlagWrapperClass {

  client: LaunchDarkly.LDClient;

  protected _featureFlagsReady = new BehaviorSubject<boolean>(false);
  public readonly featureFlagsReady: Observable<boolean> = this._featureFlagsReady.asObservable();

  constructor(protected environment: Environment) {}

  protected _init(user: FlagContextInterface): LaunchDarkly.LDClient {
      const context = {...user};
      this.client = LaunchDarkly.initialize(this.environment.featureFlagApp.clientSideId, context);
      this.client.on('ready', () => {
        const flagKeys = Object.keys(this.client.allFlags());
        if (flagKeys.length !== 0) this._featureFlagsReady.next(true);
      });
      return this.client;
  }

  protected _identity(user: FlagContextInterface) {
    const context = {...user};
    this.client.identify(context,'', () => {
      this._featureFlagsReady.next(true);
    });
  }

  protected _logOutClient() {
    this.client.close().then(
      () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete this.client;
      }
    );
    this._featureFlagsReady.next(false);
  }

  /**
   * T should be of type boolean | number | string
   * @param flag
   * @param defaultVal
   * @protected
   */
  _getFlag<T>(flag: string, defaultVal?: T) : T {
      const flagResp = defaultVal ? this.client.variation(flag, defaultVal) : this.client.variation(flag);
      return flagResp;
  }

}

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService extends FeatureFlagWrapperClass {

  constructor(
    environment: Environment
  ) { super(environment); }

  initializeFeatureFlag(user: CognitoAttributes): LaunchDarkly.LDClient | null {
    // don't initialize unless a guid is present
    if (user["custom:guid"]) {
      const constInitUser : FlagContextInterface = {
        kind: 'user',
        guid: user["custom:guid"] ?? '',
        key: user["custom:guid"] ?? '',
        company: user["custom:company"] ?? '',
        preferredLanguage: user.locale ?? 'en',
        version: this.environment.appVersion ?? ''
      };

      return this._init(constInitUser);
    } else {
      return null;
    }
  }

  identifyContext(user: CognitoAttributes) {

    const constInitUser: FlagContextInterface = {
      kind: 'user',
      guid: user["custom:guid"] ?? '',
      key: user["custom:guid"] ?? '',
      company: user["custom:company"] ?? '',
      preferredLanguage: user.locale ?? 'en',
      version: this.environment.appVersion ?? ''
    };
    this._identity(constInitUser);
  }



  disableClient() {
    this._logOutClient();
  }

  /**
   * returns an observable subject in case the feature flag service isn't ready
   * featureFlagsReady will continue to listen until flags are ready
   * @param flag
   */
  getFlag<T>(flag: string, defaultVal?:T) : Observable<T> {
    const flagResponse = new Subject<T>();

    //sometimes this finishes too quickly
    setTimeout(()=>{
      this.featureFlagsReady.pipe(
        tap(ready => {
          if (ready) {
            const flagResp = this._getFlag<T>(flag, defaultVal);
            flagResponse.next(flagResp);
          }
        }),
        takeWhile(ready => !ready)
      ).subscribe();
    },0);

    // take(1) ensures any subscription to this method closes
    return flagResponse.asObservable().pipe(take(1));
  }

  checkMultipleFlags(flags: string[]): Observable<boolean> {
    const flagObservables: Array<Observable<boolean>> = [];
    flags.forEach(flag => {
      const flagObs = this.getFlag<boolean>(flag);
      flagObservables.push(flagObs);
    });
    return zip(...flagObservables).pipe(
      take(1),
      map(values => !values.includes(false)));
  }
}
