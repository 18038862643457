import { Injectable } from '@angular/core';

import {
  AwsApiWrapperService,
  BsCacheService,
} from '@brightside-web/desktop/data-access/core-services';

import { Observable, of, from } from 'rxjs';
import { take, map, switchMap, catchError } from 'rxjs/operators';

import {
  AmplitudeService,
  MicroCoreApiCacheService,
  MicroCoreUtilApiResponse,
  MicroCoreUtilApiResponseErrorCode,
  MicroCoreUtilApiResponseCode,
} from '@micro-core/utility';

import { MicroContestAnalyticsEventName } from '../model/contest.enum';
import { MicroContestTranslationKey } from "../model/contest.enum";
const useRealData = true;
/**
 * Please use this serve to hit the APP API layer for the contest. If you need to hit the lower
 * level contest APIs, please look at MicroContestService
 *
 * constructor(private amplitudeService: AmplitudeService, private microCoreApiCacheService: MicroCoreApiCacheService) {}
 */
@Injectable({
  providedIn: 'root',
})
export class MicroContestAppService {
  static apiVersion = 'v1';
  static apiPath = `/contests-app-api/${MicroContestAppService.apiVersion}`;

  private _unknownErrorResponse = {
    data: null,
    status: MicroCoreUtilApiResponseCode._404,
    error: {
      code: MicroContestAppResultsResponseErrorCode.UNKNOWN,
      message: MicroContestTranslationKey.CONTEST_APP_SERVICE_ERROR,
    },
  };

  private _clearDetailCache(id: number): void {
    this.bsCacheService.removeItem(`${MicroContestAppService.apiPath}/${id}`);
  }

  private _clearResultsCache(id: number, date: string): void {
    this.bsCacheService.removeItem(this._buildResultApiPath(id, date));
  }

  private _buildResultApiPath(id: number, date: string): string {
    return `${MicroContestAppService.apiPath}/${id}/results?contestDate=${date}`;
  }

  private _logMissingId(): void {
    console.warn('No contest id was passed so you have been returned undefined. Please call getContests if you need info');

    this.amplitudeService.logEvent(MicroContestAnalyticsEventName.API_ERROR_MISSING_ID);
  }

  constructor(
    private amplitudeService: AmplitudeService,
    private microCoreApiCacheService: MicroCoreApiCacheService,
    private awsApiWrapperService: AwsApiWrapperService,
    private bsCacheService: BsCacheService,) {}

  /**
   * Retrieve the latest contest applicable for your client.
   * If there are results for the contest we will also fetch
   * those.
   *
   * @returns Observable<{ details: MicroContestAppResponse; results: MicroContestAppResultsResponse }>
   */
  getLatestDetailsAndResults(): Observable<{ details: MicroContestAppResponse; results: MicroContestAppResultsResponse }> {
    return this.getAll({}).pipe(
      switchMap((contestsAppResponse: MicroContestsAppResponse) => {
        if (contestsAppResponse.data?.contests && contestsAppResponse.data?.contests?.length > 0) {
          const detailsResponse: MicroContestResponse = contestsAppResponse.data.contests[0];

          if (detailsResponse && detailsResponse.didClientParticipate && detailsResponse.previousDrawingDate) {
            //Clear the cache before making request
            this._clearResultsCache(detailsResponse.id, detailsResponse.previousDrawingDate);

            return this.getResults({ id: detailsResponse.id, body: { contestDate: detailsResponse.previousDrawingDate } }).pipe(
              map((resultResponse: MicroContestAppResultsResponse) => ({
                details: { data: detailsResponse, status: MicroCoreUtilApiResponseCode._200 },
                results: resultResponse,
              }))
            );
          }

          //Returns in cases when we found a contest but there are no results yet
          return of({
            details: { data: detailsResponse, status: MicroCoreUtilApiResponseCode._200 },
            results: { data: null, status: MicroCoreUtilApiResponseCode._404 },
          });
        }

        //Returns in cases when we are getting nothing back for contests
        return of({
          details: { data: null, status: MicroCoreUtilApiResponseCode._404 },
          results: { data: null, status: MicroCoreUtilApiResponseCode._404 },
        });
      })
    );
  }

  /**
   * Get all will return every contest that this client's company has.
   * If the client is participating you can look for more info in the
   * response.
   *
   * id: number; NOT USED
   * body MicroContestsAppRequest;
   *
   * @param { id, body }<MicroContestAppArguments>
   * @returns Observable<MicroContestsAppResponse>
   */
  public getAll({ body }: MicroContestAppArguments): Observable<MicroContestsAppResponse> {
    return this.microCoreApiCacheService
      .get<MicroContestsAppResponse>('api-mobile', `${MicroContestAppService.apiPath}`, { body })
      .pipe(
        map((response: MicroContestsAppResponse) => {
          //If we don't have data, we need to map the object into data
          if (!response.data) {
            return { status: MicroCoreUtilApiResponseCode._200, data: { ...(response as any) } };
          }

          return response;
        }),
        catchError((err) => {
          console.warn('Error trying to return contests details', err);
          this.amplitudeService.logEvent(MicroContestAnalyticsEventName.API_ERROR_GET);

          return of(this._unknownErrorResponse);
        })
      );
  }

  /**
   * Utilize this to get direct details about a certain contest passing in
   * the contest id
   *
   * id: number;
   * body ; NOT USED
   *
   * @param { id, body }<MicroContestAppArguments>
   * @returns Observable<MicroContestAppResponse>
   */
  public getDetails({ id }: MicroContestAppArguments): Observable<MicroContestAppResponse> {
    if (!id) {
      this._logMissingId();

      return of(this._unknownErrorResponse);
    }

    return this.microCoreApiCacheService
      .get<MicroContestAppResponse>('api-mobile', `${MicroContestAppService.apiPath}/${id}`)
      .pipe(
        map((response: MicroContestAppResponse) => {
          //If we don't have data, we need to map the object into data
          if (!response.data) {
            return { status: MicroCoreUtilApiResponseCode._200, data: { ...(response as any) } };
          }

          return response;
        }),
        catchError((err) => {
          console.warn('Error trying to return contests details', err);
          this.amplitudeService.logEvent(MicroContestAnalyticsEventName.API_ERROR_GET);

          return of(this._unknownErrorResponse);
        })
      );
  }

  /**
   * Utilize this to get contest results for this client
   *
   * id: number;
   * body MicroContestAppResultsRequest;
   *
   * @param { id, body }<MicroContestAppArguments>
   * @returns Observable<MicroContestAppResultsResponse>
   */
  public getResults({ id, body }: MicroContestAppArguments): Observable<MicroContestAppResultsResponse> {
    if (!id) {
      this._logMissingId();

      return of(this._unknownErrorResponse);
    }
    if (useRealData) {
      return this.microCoreApiCacheService
        .get<MicroContestAppResultsResponse>(
          'api-mobile',
          this._buildResultApiPath(id, (body as MicroContestAppResultsRequest)?.contestDate || ''),
          {},
          260
        )
        .pipe(
          map((response: MicroContestAppResultsResponse) => {
            //If we don't have data, we need to map the object into data
            if (!response.data) {
              return {status: MicroCoreUtilApiResponseCode._200, data: {...(response as any)}};
            }

            return response;
          }),
          catchError((err) => {
            console.warn('Error trying to return contests results', err);
            this.amplitudeService.logEvent(MicroContestAnalyticsEventName.API_ERROR_GET);

            return of(this._unknownErrorResponse);
          })
        );
    }
    return of({status: MicroCoreUtilApiResponseCode._200, data:{
      drawingDate:"2023-05-04",
        id,
        messageValues:{
        prizeAmount: 100,
          numberOfWinners:50
        },
        name:"a contest",
        result:MicroContestResult.WON
      }})
  }

  public updateResultsAsViewed({ id }: MicroContestAppArguments) {
    if (!id) {
      this._logMissingId();

      return;
    }

    from(this.awsApiWrapperService.post('api-mobile', `${MicroContestAppService.apiPath}/${id}/results`, {}))
      .pipe(take(1))
      .subscribe();
  }
}

export interface MicroContestResponse {
  id: number;
  name: string;
  previousDrawingDate: string;
  nextDrawingDate: string;
  messageValues: MicroContestMessageValues;
  isClientParticipating: boolean;
  didClientParticipate: boolean;
}

export interface MicroContestMessageValues {
  prizeAmount: number;
  numberOfWinners: number;
}

export interface MicroContestResultsCache {
  [key: string]: MicroContestResult;
}

export interface MicroContestResultsDetailCache {
  [key: string]: MicroContestResponse;
}

export interface MicroContestResultResponse {
  drawingDate: string; //yyyy-mm-dd
  id: number;
  messageValues: MicroContestMessageValues;
  name: string;
  result: MicroContestResult;
}

export enum MicroContestResult {
  WON = 'won',
  LOST = 'lost',
}

export interface MicroContestAppArguments {
  id?: number;
  body?: MicroContestsAppRequest | MicroContestAppResultsRequest;
}

export interface MicroContestsAppRequest {
  participating?: boolean;
}
export interface MicroContestsAppResponse extends MicroCoreUtilApiResponse {
  data: {
    contests?: MicroContestResponse[];
  } | null;
}
export const MicroContestsAppResponseErrorCode = {
  ...MicroCoreUtilApiResponseErrorCode,
};

export interface MicroContestAppResponse extends MicroCoreUtilApiResponse {
  data: MicroContestResponse | null;
}
export const MicroContestAppResponseErrorCode = {
  ...MicroCoreUtilApiResponseErrorCode,
  SERVICE_ERROR: 'SERVICE_ERROR',
};

export interface MicroContestAppResultsRequest {
  contestDate: string; //"yyyy-mm-dd"
}
export interface MicroContestAppResultsResponse extends MicroCoreUtilApiResponse {
  data: MicroContestResultResponse | null;
}
export const MicroContestAppResultsResponseErrorCode = {
  ...MicroCoreUtilApiResponseErrorCode,
  INVALID_CONTEST: 'INVALID_CONTEST',
  RESULTS_NOT_DRAWN: 'RESULTS_NOT_DRAWN',
  RESULTS_TOO_OLD: 'RESULTS_TOO_OLD',
};

export interface MicroContestAppUpdateViewedResponse extends MicroCoreUtilApiResponse {
  data: null;
}
