<bw-theme>
  <ng-container *ngIf="isMobileDevice && mobileLayoutType !== LayoutStyle.PUBLIC_ACCESS; else desktopContent">
    <bw-toast (emit)="toastClicked($event)" [toastObs]="toastService.onShowToast()"></bw-toast>
      <div *ngIf="mobileViewEvent" [brightsideWebFb]="{event_name: mobileViewEvent, custom: {'company': customerName}}"></div>
      <div class="mobile-layout flex-column center">
        <div class="mobile-layout-logo hidden-print mb-md">
          <img src="../assets/brightsideLockup.svg" alt="Brightside logo" width="124" class="mb-xs">
          <div class="customer-logo" *ngIf="customerLanding">
            <typography text-small-regular class="secondary-text mr-xxs no-wrap">{{ 'BROUGHT_BY' | translate }}</typography>
            <img src="/cdn.gobrightside.com/resources/assets/{{customerName}}-logo.png" alt="" height="16">
          </div>
        </div>
        <div class="mobile-layout-graphic mb-smd flex-item-rigid">
          <img [src]="mobileGraphic" alt="brightside welcome graphic, a guy with a key" style="max-width: fit-content;">
        </div>
        <div class="mobile-layout-content flex-item-flexible mb-smd"  style="width: 260px; margin: auto;">
          <typography headline-semibold>{{mobileHeadline | translate}}</typography>
          <bw-paragraph textStyle="Body2">{{mobileParagraph | translate}}</bw-paragraph>
          <!-- Must be hidden, but required for email verification -->
          <div class="hidden-router" *ngIf="isVerify">
            <router-outlet></router-outlet>
          </div>
        </div>
        <div class="mobile-layout-footer flex-item-rigid">
          <button bw-button fluidWidth (click)="downloadApp()">{{mobileCta | translate}}</button>
        </div>
      </div>
  </ng-container>
</bw-theme>

<ng-template #desktopContent>
    <ng-container *ngIf="isLandingLayout || !isLoggedIn; else loggedInTemplate">
      <bw-toast (emit)="toastClicked($event)" [toastObs]="toastService.onShowToast()"></bw-toast>
      <bw-page class="pt-lg">
        <bw-topbar page-header class="hidden-print" (logo)="logoClick()">
          <div class="customer-logo flex-row" *ngIf="customerLanding">
            <typography text-small-regular class="secondary-text bs-mid-gray no-wrap mr-xs">Brought to you by</typography>
            <img src="{{ env.cdnUrl }}resources/assets/{{customerName}}-logo.png" alt=""></div>
          <div class="action">
            <div class="flex-row">
              <div class="flex-item" *ngIf="displayLangSelector">
                <brightside-web-language-selector [bypassPut]="true"></brightside-web-language-selector>
              </div>
              <ng-container *ngIf="!isLoggedIn && showAppLink">
                <ng-template #appLinkNotSent >
                    <div class="flex-item"><button bw-outlined-button (click)="getTheApp()">{{ 'DOWNLOAD_MOBILE_APP' | translate }}</button></div>
                </ng-template>
                <div class="flex-row" *ngIf="appLinkSent; else appLinkNotSent">
                  <span class="body mr-sm bs-mid-gray" >{{ 'APP_LINK_SENT' | translate }}</span> <a bw-anchor class="bs-blue" (click)="getTheApp()">{{ 'RETRY' | translate }}</a>
                </div>
              </ng-container>
            </div>
          </div>
        </bw-topbar>
        <div page-primary-section *ngIf="isLandingLayout && showCopy" [ngClass]="{'mr-md': !isMobileWidth}">
          <brightside-web-how-we-help [heading]="landingHeading" [bodyCopy]="landingBodyCopy" [iconGroupTitle]="landingIconGroupTitle" (introVideoClicked)="showIntroVideo = true;"></brightside-web-how-we-help>
          <ng-container *ngIf="!isMobileWidth && customerLanding">
            <ng-container *ngTemplateOutlet="FAContact"></ng-container>
          </ng-container>

          <div class="media-small-center" *ngIf="showCustomerMessage" [ngClass]="{'mr-lg': !isMobileWidth}">
            <bw-paragraph color="var(--gray-50)" *ngIf="!isLegrand; else legrandCustomerMessage">
              <span [innerHTML]="'BS_CUSTOMER_MSG' | translate"></span>
            </bw-paragraph>
          </div>
          <ng-template #legrandCustomerMessage>
            <bw-paragraph color="var(--gray-50)">*For the time being, Brightside is available only to full-time employees of <span class="semibold">Finelite</span>, <span class="semibold">Cablofil</span>, <span class="semibold">Connectrac</span>, <span class="semibold">Pass and Seymour</span>, and <span class="semibold">Wiremold</span>.</bw-paragraph>
          </ng-template>
        </div>
      <div page-secondary-section>
        <router-outlet></router-outlet>
      </div>
        <div page-footer *ngIf="isLandingLayout">
          <ng-container *ngIf="isMobileWidth && customerLanding">
            <ng-container *ngTemplateOutlet="FAContact"></ng-container>
          </ng-container>
          <div class="media-small-center">
            <bw-caption>© {{currentYear}} Brightside |
              <a bw-anchor anchorStyle="text-small-regular" target="_blank" (click)="(true)" [href]="'//www.gobrightside.com/terms-of-service' | safe">
                Terms
              </a> |
              <a bw-anchor anchorStyle="text-small-regular" target="_blank" (click)="(true)" [href]="'//www.gobrightside.com/privacy-policy' | safe">
                Privacy
              </a>
              <span style='color:white'>
                {{buildVersion}}
              </span>
            </bw-caption>
          </div>
        </div>
      </bw-page>
    </ng-container>

    <router-outlet name="modal"></router-outlet>

    <bw-modal *ngIf="showLogoutWarningModal" modalWidth="medium">
      <typography title-medium>{{ 'SESSION_EXP_TITLE' | translate }}</typography>
      <div class="body">
        <bw-paragraph >{{ 'SESSION_EXP_DESC' | translate:{timeout:timeToLogout} }}</bw-paragraph>
      </div>
      <div class="footer">
        <div class="flex-row row-reverse">
          <button bw-button (click)="dismissAutoLogout()">{{ 'SESSION_EXP_CONT_CTA' | translate }}</button>
          <a bw-anchor (click)="logout()" style="margin: 0 16px;">{{ 'SIGN_OUT' | translate }}</a>
        </div>
      </div>
    </bw-modal>

  <ng-template #loggedInTemplate>
    <div *ngIf="!isFullScreenLayout; else loggedInTemplateFullScreen" class="left-nav-wrapper">
      <bw-topbar page-header (logo)="logoClick()"  *ngIf="!isOnboardingComplete">
        <div class="customer-logo flex-row" *ngIf="customerLanding"><typography text-small-regular class="secondary-text bs-mid-gray no-wrap mr-xs">{{ 'BROUGHT_BY' | translate }}</typography><img src="//cdn.gobrightside.com/resources/assets/{{customerName}}-logo.png" alt=""></div>
      </bw-topbar>
        <div class="left-nav hidden-print" *ngIf="isOnboardingComplete">
          <div class="brightside-logo pointer hidden-print" (click)="logoClick()"><img src="../assets/brightsideLockup.svg" alt=""></div>
          <nav class="app-menu">
            <ul>
              <li routerLinkActive="active"><a [routerLink]="[ 'home' ]"><bw-icon [iconName]="'home'" horizontalSpace="mr-xs"></bw-icon> <span class="link-text">{{ 'HOME' | translate }}</span> </a></li>
              <li routerLinkActive="active"><a [routerLink]="[ 'ways-we-can-help' ]"><bw-icon [iconName]="'search'" horizontalSpace="mr-xs"></bw-icon> <span class="link-text">{{ 'WAYS_WE_CAN_HELP' | translate }}</span></a></li>
              <li><a [routerLink]="[ {outlets: { modal: 'settings/profile'} } ]"><bw-icon [iconName]="'user'" horizontalSpace="mr-xs"></bw-icon> <span class="link-text">{{ 'PROFILE' | translate }}</span></a></li>
              <li><a [routerLink]="[ {outlets: { modal: 'settings/settings'} } ]"><bw-icon [iconName]="'cog'" horizontalSpace="mr-xs"></bw-icon> <span class="link-text">{{ 'SETTINGS' | translate }}</span></a></li>
              <li routerLinkActive="active"><a [routerLink]="[ 'invite' ]"><bw-icon [iconName]="'invite'" horizontalSpace="mr-xs"></bw-icon> <span class="link-text">{{ 'INVITE' | translate }}</span></a></li>
              <li><a (click)="logout()"><bw-icon [iconName]="'sign_out'" horizontalSpace="mr-xs"></bw-icon> <span class="link-text">{{ 'SIGN_OUT' | translate }}</span> </a></li>
            </ul>
          </nav>
          <div class='versionStamp'>{{buildVersion}}</div>
        </div>
        <div class="content-wrapper">
          <bw-toast (emit)="toastClicked($event)" [toastObs]="toastService.onShowToast()"></bw-toast>
          <div class="content-inner" [ngClass]="{'onboarding-complete': isOnboardingComplete}">
            <div class="content-inner-container">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
    </div>

    <ng-template #loggedInTemplateFullScreen>
      <div class="left-nav-wrapper full-screen">
        <bw-topbar page-header (logo)="logoClick()">
          <div class="customer-logo flex-row" *ngIf="customerLanding"><typography text-small-regular class="secondary-text bs-mid-gray no-wrap mr-xs">{{ 'BROUGHT_BY' | translate }}</typography><img src="//cdn.gobrightside.com/resources/assets/{{customerName}}-logo.png" alt=""></div>
        </bw-topbar>

        <div class="content-wrapper">
          <bw-toast (emit)="toastClicked($event)" [toastObs]="toastService.onShowToast()"></bw-toast>
          <div class="content-inner">
            <div class="content-inner-container">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-template>
</ng-template>

<ng-template #FAContact>
  <div *ngIf="customerNumberLoaded" class="media-small-center mr-md mb-sm" [ngClass]="{'mr-md': !isMobileWidth}">
    <bw-paragraph color="var(--gray-50)" verticalSpace="4">
      <div [innerHTML]="'BRIGHTSIDE_REGISTER_CALL' | translate:{customerNumber:customerNumber}"> </div>
    </bw-paragraph>
    <bw-caption>({{ faHours }})</bw-caption>
  </div>
</ng-template>

<bw-modal *ngIf="showSSOLoginError" modalWidth="medium" [brightsideWebFb]="{event_name: 'error_shown', custom: {'error id': 'sso_login'}}" [error_view]="true">
  <typography title-medium>We couldn’t find your record</typography>
  <div class="body">
    <bw-paragraph>We haven't been able to locate you in our record of eligible {{customerName | titlecase}} employees and household members.</bw-paragraph>
    <bw-paragraph>Please give us a call at {{customerNumber | phone}} so we can figure this out and get you started.</bw-paragraph>
  </div>
  <div class="footer">
    <div class="flex-row row-reverse">
      <button bw-button (click)="clearSSOAttempt()">Ok</button>
    </div>
  </div>
</bw-modal>

<brightside-web-mobile-app-modal *ngIf="showMobileModal" (ctaClick)="showMobileModal = false"></brightside-web-mobile-app-modal>

<bw-video *ngIf="showIntroVideo"
          [cdnBase]="env.cdnUrl"
          [path]="'videos/explainer/' + languageSelectorService.activeLang + '/explainer.mp4'"
          (modalClosed)="showIntroVideo = false;">
</bw-video>

<bw-modal *ngIf="bannerModal.displayMe"
          [showCloseButton]="true" (closeModal)="onBannerModalClose(bannerModal.id)"
          modalWidth="medium" [brightsideWebFb]="{event_name: bannerModal.id}">
  <typography title-medium>
    {{ bannerModal.titleKey | translate }}
  </typography>
  <div class="body">
    <div [innerHTML]="bannerModal.bodyKey | translate"></div>
  </div>
  <div class="footer">
    <div class="flex-row row-reverse">
      <button *ngIf="bannerModal.ctaKey" bw-button (click)="onBannerModalClose(bannerModal.id)">{{ bannerModal.ctaKey | translate }}</button>
    </div>
  </div>
</bw-modal>


<brightside-web-simple-global-modal></brightside-web-simple-global-modal>
