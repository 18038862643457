import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { PhonePipe } from '@brightside-web/shared/desktop';

import { UiTemplateContent, UiTemplateContentGroupCell, UiTemplateContentType } from '@micro-ui/template/content';
import { UiTemplateFlyerInterface } from '@micro-ui/template/page';

import { MicroContestRouteDataKey } from '../../model/contest.enum';
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class MicroContestLegalAmoeResolver implements Resolve<UiTemplateFlyerInterface> {
  constructor(private phonePipe: PhonePipe, private translateService:TranslateService) {
    this.startAutoDestroyTimer();
  }

  private startAutoDestroyTimer() {
    window.setTimeout(() => {
      window.location.replace('about:blank');
    }, 60000 * 30);
  }

  private insertValuesFromQuery(
    templateData: UiTemplateFlyerInterface,
    routeSnapshot: ActivatedRouteSnapshot
  ): UiTemplateFlyerInterface {
    const clientDetails: Record<string, string> = {
      fName: routeSnapshot.queryParams['fName'] || '',
      lName: routeSnapshot.queryParams['lName'] || '',
      phone: this.phonePipe.transform(routeSnapshot.queryParams['phone']),
    };

    templateData.inputs.content = templateData.inputs.content?.map((contentDetails: UiTemplateContent) => {
      if (contentDetails.type === UiTemplateContentType.GROUP_CELL) {
        const data: UiTemplateContentGroupCell = contentDetails.data as UiTemplateContentGroupCell;

        data.options = data.options.map((groupOption) => ({
          ...groupOption,
          value: clientDetails[groupOption.value] || groupOption.value,
        }));

        contentDetails.data = data;
      }

      return contentDetails;
    });

    return templateData;
  }

  async resolve(routeSnapshot: ActivatedRouteSnapshot) {
    this.translateService.use(routeSnapshot.queryParams["locale"] ?? "en");
    const templateData: UiTemplateFlyerInterface = routeSnapshot.data[MicroContestRouteDataKey.TEMPLATE] || {};
    const returnTemplateData: UiTemplateFlyerInterface = {
      ...this.insertValuesFromQuery(templateData, routeSnapshot),
    };

    return returnTemplateData;
  }
}
