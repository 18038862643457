import {Component, OnDestroy, OnInit} from '@angular/core';
import {CompanyService} from "@brightside-web/desktop/data-access/shared";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {ToastService} from "@brightside/brightside-ui-services";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'brightside-web-employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.scss']
})
export class EmploymentComponent implements OnInit, OnDestroy {
  sub = new Subscription();
  company: string;

  constructor(
    private companyService: CompanyService,
    private router: Router,
    private toastService: ToastService,
    private translateSvc: TranslateService
  ) { }

  ngOnInit(): void {
    this.sub.add(
      this.companyService.company.subscribe(
        value => {
          if (value !== '') this.company = value;
        }
      )
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onIdentityComplete(event: boolean) {
    if (event) {
      this.toastService.infoBlue(this.translateSvc.instant('EMPLOYEE_VERIFICIATION_IN_PROGRESS'))
      this.router.navigate(['/']);
    }
  }

}
