import {inject, Injectable} from '@angular/core';
import {from, Observable, of, switchMap} from 'rxjs';
import {
  AwsApiWrapperService,
  BsCacheService,
} from '@brightside-web/desktop/data-access/core-services';
import { tap } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ApiCacheService {

  awsApiWrapperService: AwsApiWrapperService = inject(AwsApiWrapperService);
  bsCacheService: BsCacheService = inject(BsCacheService);

  constructor() {}

  get<T>(api: string, key: string, init?: { [key: string]: any }): Observable<T> {
    return from(this.bsCacheService.getItem(key)).pipe(
      switchMap((cached: T) => {
        if (cached) {
          return of(cached);
        } else {
          return from(this.awsApiWrapperService.get(api, key, init)).pipe(
            tap((response) => {
              this.bsCacheService.setItem(key, response, {expires: moment().add(30, 'seconds').valueOf()})
            })
          );
        }
      })
    )
  }

  getForced<T>(api: string, key: string, init?: { [key: string]: any }): Observable<T> {
    this.bsCacheService.removeItem(key);
    return this.get<T>(api, key, init);
  }

  async getItem<T>(key: string): Promise<T> {
    return await this.bsCacheService.getItem(key);
  }

  setItem(key: string, value: any) {
    this.bsCacheService.setItem(key, value);
  }

  refreshItem(key: string) {
    this.bsCacheService.removeItem(key);
  }
}
