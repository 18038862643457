<div *ngIf="allTheSteps" class="content-layout"
     [brightsideWebFb]="{
       event_name: 'verification_status',
       custom: {'type': verificationStatus}
     }">
  <bw-action-article>
    <div content-section>
      <div class="flex-column">
        <div class="flex-row space-center">
          <div>
            <img src="/assets/shield-banner.png">
          </div>
        </div>
        <div class="flex-row space-center">
          <typography title-medium class="mb-sm mt-sm">
            {{ statusTitle | translate }}
          </typography>
        </div>
      </div>
      <p text-large-regular class="center" color="var(--secondary-text)">
        {{ statusBody | translate:{company:company|titlecase} }}
      </p>
      <bw-tracker [trackerSteps]="allTheSteps"></bw-tracker>
    </div>
    <button
      bw-button
      footer-actions
      (click)="continue()"
      [brightsideWebFb]="{
       event_name:'verification_status',
       custom: {'type': verificationStatus}
      }"
    >{{ continueBtnKey | translate }}</button>
  </bw-action-article>
</div>
