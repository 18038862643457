import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  FinancialAccountTransaction, TransactionPagination,
  TransactionPaginationItem
} from "@brightside-web/desktop/feature/financial-accounts-core";
import {Observable, Subscription} from "rxjs";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'brightside-web-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ opacity: 0, maxHeight: '0px', overflow:'hidden' }),
        animate('750ms ease-in',
          style({ opacity: 1, maxHeight: '500px' }))
      ]),
    ]),
  ],
})
export class TransactionHistoryComponent implements OnInit {
  sub = new Subscription();
  @Input() transactionsHistory: Observable<TransactionPagination|undefined>;
  @Input() hasMoreTransactions: Observable<boolean>;
  @Output() loadMoreClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  processing = false;

  constructor() {
  }

  ngOnInit(): void {
    this.sub.add(this.transactionsHistory.subscribe(
      resp => {
        this.processing = false;
      }
    ))
  }

  pageId(index: number, page: TransactionPaginationItem) {
    return page.page;
  }

  transactionId(index: number, transaction: FinancialAccountTransaction) {
    return transaction.transactionId;
  }

  loadMore() {
    this.processing = true;
    this.loadMoreClicked.emit(true);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
