import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {EmailService} from "@brightside-web/desktop/data-access/email-verify";
import {map, switchMap} from "rxjs/operators";
import {FeatureFlagService} from "@brightside-web/desktop/data-access/core-services";

@Injectable({
  providedIn: 'root'
})
export class EmailVerificationGuard implements CanActivate {
  private shouldBlockAppAccess: boolean;

  constructor(
    private emailService: EmailService,
    private router: Router,
    private featureFlagSvc: FeatureFlagService,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.featureFlagSvc.getFlag<boolean>('enable-forced-email-verification-in-apps').pipe(
      switchMap(
        shouldForce => {
          const isInVerification = state.url.includes('verification/email');
          if (shouldForce) {
            return this.emailService.get().pipe(
              map( emailResp => {
                const isEmailVerified = emailResp.verified;
                if (isInVerification && !isEmailVerified) {
                  return true;
                } else if (isInVerification && isEmailVerified) {
                  return this.router.createUrlTree(['home']);
                } else if (!isInVerification && isEmailVerified) {
                  return true;
                }  else {
                  // finally: we've determined the user is not in verification flow and their email is not verified
                  return this.router.createUrlTree(['verification', 'email']);
                }
              })
            );
          } else {
            if (isInVerification) return of(this.router.createUrlTree(['home']));
            return of(true);
          }
        }
      )
    )
  }

}
