import { MessageBusEventUtil } from '@micro-core/message-bus';

import { UiHeaderType } from '@micro-ui/molecule';
import { UiTemplateContentType } from '@micro-ui/template/content';
import {
  GetUiTemplateActionRoute,
  UiTemplateAction,
  UiTemplateFlyerDataBuilder,
  UiTemplateNavType,
} from '@micro-ui/template/page';

import {
  MicroContentMediaFor,
  MicroContestAnalyticsEventName,
  MicroContestRouteKey,
  MicroContestTranslationKey,
} from '../../../model/contest.enum';

const TemplateData = UiTemplateFlyerDataBuilder({
  inputs: {
    navType: UiTemplateNavType.CLOSE,
    header: {
      type: UiHeaderType.EYE_BROW,
      title: MicroContestTranslationKey.CONTEST_ENTRY_TITLE,
      subTitle: MicroContestTranslationKey.CONTEST_ENTRY_SUB_TITLE,
      icon: '',
    },
    content: [
      {
        type: UiTemplateContentType.LIST_ITEM,
        data: {
          title: MicroContestTranslationKey.CONTEST_ENTRY_EXTRA1_TITLE,
          subTitle: MicroContestTranslationKey.CONTEST_ENTRY_EXTRA1_SUB_TITLE,
          icon: 'money_bag',
        },
      },
      {
        type: UiTemplateContentType.LIST_ITEM,
        data: {
          title: MicroContestTranslationKey.CONTEST_ENTRY_EXTRA2_TITLE,
          subTitle: MicroContestTranslationKey.CONTEST_ENTRY_EXTRA2_SUB_TITLE,
          icon: 'plant_pot',
        },
      },
      {
        type: UiTemplateContentType.LIST_ITEM,
        data: {
          title: MicroContestTranslationKey.CONTEST_ENTRY_EXTRA3_TITLE,
          subTitle: MicroContestTranslationKey.CONTEST_ENTRY_EXTRA3_SUB_TITLE,
          icon: 'pen',
        },
      },
    ],
    media: MicroContentMediaFor(MicroContestAnalyticsEventName.ENTRY_INTRO),
    link: MicroContestTranslationKey.CONTEST_ENTRY_FOOTER_LINK,
    cta1Label: MicroContestTranslationKey.CONTEST_ENTRY_CTA1,
    viewName: MicroContestAnalyticsEventName.ENTRY_INTRO,
  },
  actions: {
    [UiTemplateAction.footerLink]: GetUiTemplateActionRoute.ROUTE_SAME_LEVEL([MicroContestRouteKey.RULES]),
    [UiTemplateAction.navLeftIcon]: () => MessageBusEventUtil.sendOutgoing(MessageBusEventUtil.event.standard.exitFeature),
    [UiTemplateAction.cta1]: GetUiTemplateActionRoute.ROUTE_SAME_LEVEL([MicroContestRouteKey.AUTOSAVE]),
  },
});



export default TemplateData;
