import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import {TranslateModule, TranslatePipe, TranslateService} from '@ngx-translate/core';

import { BrightsideUiModule } from '@brightside/brightside-ui';
import { BrightsideUiServicesModule } from '@brightside/brightside-ui-services';
import { PhonePipe } from '@brightside-web/shared/desktop';

import {
  FirebaseAnalyticsService,
  FirebaseService,
  LayoutStyle,
  MicroCoreUtilityModule,
  MicroUtilTranslationService,
} from '@micro-core/utility';

import { MicroUiTemplateContentModule } from '@micro-ui/template/content';
import { MicroUiTemplatePageModule, UiTemplateFormFlyerComponent } from '@micro-ui/template/page';

import { MicroContestRouteDataKey } from './model/contest.enum';

import TemplateData_LegalAmoe from './routes/legal/legal-amoe.template.data';
import { MicroContestLegalAmoeResolver } from './routes/legal/legal-amoe.template.resolver';
import { MicroContestLegalClientResolver } from './routes/legal/legal-client.resolver';

const moduleRoutes: Routes = [
  {
    path: 'attach-client',
    component: UiTemplateFormFlyerComponent,
    resolve: {
      _: MicroContestLegalClientResolver,
    },
    data: {
      [MicroContestRouteDataKey.DISPLAY_STYLE]: LayoutStyle.DEFAULT,
    },
  },
  {
    path: 'sweepstakes-amoe',
    component: UiTemplateFormFlyerComponent,
    resolve: {
      [MicroContestRouteDataKey.TEMPLATE]: MicroContestLegalAmoeResolver,
    },
    data: {
      [MicroContestRouteDataKey.TEMPLATE]: TemplateData_LegalAmoe,
      [MicroContestRouteDataKey.DISPLAY_STYLE]: LayoutStyle.PUBLIC_ACCESS,
    },
  },
];

export const MicroContestLegalNgModuleConfig = {
  imports: [
    BrightsideUiModule,
    BrightsideUiServicesModule,
    CommonModule,
    MicroCoreUtilityModule,
    MicroUiTemplateContentModule,
    MicroUiTemplatePageModule,
    TranslateModule
  ],
  providers: [
    MicroContestLegalAmoeResolver,
    MicroContestLegalClientResolver,
    PhonePipe,
    TranslatePipe,
    { provide: FirebaseService, useClass: FirebaseAnalyticsService },
    { provide: TranslateService, useClass: MicroUtilTranslationService },
  ],
};

// warning: do not call updateTranslations here it is overriding the Desktop app translation loading
@NgModule({
  imports: [...MicroContestLegalNgModuleConfig.imports, RouterModule.forChild(moduleRoutes)],
  providers: [...MicroContestLegalNgModuleConfig.providers],
})
export class MicroContestLegalModule {}
