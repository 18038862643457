import { Subscription } from 'rxjs';
import { TermsService } from '@brightside-web/desktop/data-access/shared';
import { Router } from '@angular/router';

export interface Consent {
  key: string;
  linkKey?: string;
  descriptionTitle?: string;
  descriptionBeforeLink?: string;
  descriptionLink?: string;
  descriptionAfterLink?: string;
  accepted: boolean;
}

export enum ConsentType {
  CustodialAccount = 'CustodialAccount',
  Kickforward = 'Kickforward',
  Withdrawal = 'Withdrawal',
  PayrollSavings = 'PayrollSavings',
  CreditTerms = 'CreditTerms',
  CreditTerms_2nd = 'CreditTerms_2nd',
  CreditTerms_3rd = 'CreditTerms_3rd',
  CreditTerms_4th = 'CreditTerms_4th',
}

export class ConsentManager {
  static ConsentDetails: { [key: string]: Consent } = {
    CreditTerms: {
      key: ConsentType.CreditTerms,
      descriptionTitle: 'CREDIT_CONSENTS_TITLE_1',
      descriptionBeforeLink: 'CREDIT_CONSENT_1',
    } as Consent,
    CreditTerms_2nd: {
      key: '',
      descriptionTitle: 'CREDIT_CONSENTS_TITLE_2',
      descriptionBeforeLink: 'CREDIT_CONSENT_2',
    } as Consent,
    CreditTerms_3rd: {
      key: '',
      descriptionTitle: 'CREDIT_CONSENTS_TITLE_3',
      descriptionBeforeLink: 'CREDIT_CONSENT_3',
    } as Consent,
    CreditTerms_4th: {
      key: '',
      descriptionTitle: 'CREDIT_CONSENTS_TITLE_4',
      descriptionBeforeLink: 'CREDIT_CONSENT_4',
    } as Consent,

    CustodialAccount: {
      key: 'CustodialAccount',
      descriptionTitle: 'SAVINGS_CONSENTS_TITLE_1',
      descriptionBeforeLink: `SAVINGS_CONSENT_1`,
    } as Consent,
    Kickforward: {
      key: 'Kickforward',
      linkKey: 'BrightsideTerms',
      descriptionTitle: 'SAVINGS_CONSENTS_TITLE_3',
      descriptionBeforeLink: 'SAVINGS_CONSENT_3',
    } as Consent,
    Withdrawal: {
      key: '',
      descriptionTitle: 'MICRO_CONSENT_WITHDRAWAL_DESCRIPTION_TITLE',
      descriptionBeforeLink: 'MICRO_CONSENT_WITHDRAWAL_DESCRIPTION_BEFORE_LINK',
    } as Consent,
    PayrollSavings: {
      key: 'PayrollSavings',
      descriptionTitle: 'SAVINGS_CONSENTS_TITLE_2',
      descriptionBeforeLink: `SAVINGS_CONSENT_2`,
    } as Consent,
  };
// TODO: replace w/ copy key for below: CREDIT_CONSENTS_SUBTEXT
  instructionalText = 'In order to use these services, please read and acknowledge the following:';

  static vendorTerms(key: string): Consent {
    return {
      key,
      descriptionTitle: 'SAVINGS_CONSENTS_TITLE_2',
      descriptionBeforeLink: 'SAVINGS_CONSENT_2',
    } as Consent;
  }
}

export class AbstractConsentComponent {
  consents:Consent[];
  sub:Subscription = new Subscription();
  public processing = false;
  displayedConsentKey = "";

  constructor(protected termsSvc: TermsService) {
    this.setupConsentArray();
    this.consents.forEach((consent) => consent.accepted = false);
  }

  protected setupConsentArray() {
    //populate consents here!
  }

  get ConsentManager(): ConsentManager {
    return new ConsentManager();
  }

  get allAccepted(): boolean {
    return this.consents.every((consent) => consent.accepted);
  }

  onSuccess() {
    //subclass implements this stuff.
  }

  accept() {
    this.processing = true;
    //MW shared/components/consents, consents.object
    this.termsSvc.acceptTermsFromConsentArray(this.consents).subscribe((result) => {
      this.processing = false;
      if (result) {
        this.onSuccess();
      }
    },(error) => {
      this.processing = false;
    });
  }
}
