<ui-page-transition [reverseExitAnimation]="shouldReverseExitAnimation">
  <bw-action-article page-template minHeight="0" [brightsideWebFb]="{ event_name: fbEventName + '_shown' }">
    <ui-nav-exit-back
      *ngIf="!pageHideBackButton"
      header-actions
      [trackingDetail]="{ event_name: fbEventName + '_dismissed' }"
      (exitActionHandler)="handleBackCtaClick()"
    ></ui-nav-exit-back>

    <div pre-title>
      <bw-steps
        *ngIf="pageStepperActiveIndex >= 0"
        styleClass="mb-md"
        [model]="pageStepperItems"
        [readonly]="true"
        [activeIndex]="pageStepperActiveIndex"
      ></bw-steps>
    </div>
    <div header-title class="mb-sm">
      <typography title-medium>{{ pageTitle }}</typography>
    </div>
    <div content-section>
      <p class="secondary-text mb-md">{{ pageSubTitle }}</p>
      <form [formGroup]="routingForm" autocomplete="off">
        <div class="form-grid">
          <div class="mb-xs">
            <!--TODO need a responsive select dropdown -->
            <label for="formAccountType" class="text-small-semibold mb-xs">{{
              'MICRO_ADD_ACCOUNT_FROM_ACCOUNT_TYPE_LABEL' | translate
            }}</label>
            <ngx-select-dropdown
              formControlName="accountType"
              [options]="accountTypes"
              [config]="selectAccountTypesConfig"
            ></ngx-select-dropdown>
          </div>
          <bw-input
            label="{{ 'MICRO_ADD_ACCOUNT_FORM_LABEL_ROUTING_NUMBER' | translate }}"
            formControlName="routingNumber"
          ></bw-input>

          <bw-input
            label="{{ 'MICRO_ADD_ACCOUNT_FORM_LABEL_ACCOUNT_NUMBER' | translate }}"
            formControlName="accountNumber"
          ></bw-input>

          <bw-input
            label="{{ 'MICRO_ADD_ACCOUNT_FORM_LABEL_CONFIRM_ACCOUNT_NUMBER' | translate }}"
            formControlName="accountNumberConfirm"
          ></bw-input>
        </div>
      </form>
    </div>
    <div footer-actions class="flex-row row-reverse">
      <button
        *ngIf="pageCtaLabel"
        bw-button
        type="submit"
        [disabled]="routingForm.invalid || isCtaDisabled"
        [brightsideWebFb]="{ event_name: fbEventName + '_tapped', action: 'click' }"
        [processing]="processing"
        (click)="handleCtaClick()"
      >
        {{ pageCtaLabel | translate }}
      </button>

      <button
        *ngIf="pageSecondaryCtaLabel"
        [brightsideWebFb]="{ event_name: fbEventName }"
        [secondaryCta]="true"
        bw-link-button
        class="bs-blue brand pr-xl"
        (click)="handleSecondaryCtaClick()"
      >
        {{ pageSecondaryCtaLabel | translate }}
      </button>
    </div>
  </bw-action-article>
</ui-page-transition>

<bw-modal *ngIf="showMicroWarning" modalWidth="medium">
  <typography title-medium color="var(--bs-dark-gray)" class="mb-sm">{{ microTitle }}</typography>
  <section class="body">
    <bw-paragraph>
      <typography text-large-regular color="var(--gray-90)">
        {{ 'MICRO_ADD_ACCOUNT_MICRO_WARNING_LINE_1' | translate }}

        <ul style="margin: var(--bs-space-24) 0; padding-inline-start: var(--bs-space-24)">
          <li>{{ 'MICRO_ADD_ACCOUNT_MICRO_WARNING_LINE_2' | translate }}</li>
          <li>{{ microLineWithDate }}</li>
          <li>{{ 'MICRO_ADD_ACCOUNT_MICRO_WARNING_LINE_3' | translate }}</li>
        </ul>

        {{ 'MICRO_ADD_ACCOUNT_MICRO_WARNING_LINE_4' | translate }}
      </typography>
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        *ngIf="microPrimaryCta"
        bw-button
        [processing]="processing"
        (click)="handleMicroCtaClick()"
        [brightsideWebFb]="{ event_name: fbEventName + '_tapped', action: 'click' }"
      >
        {{ microPrimaryCta }}
      </button>
    </div>
  </footer>
</bw-modal>
