<bw-action-article
  [brightsideWebFb]="{ event_name: 'transaction_history' }"
  [centeredFooter]="true">
  <div header-actions>
    <ui-nav-exit-back
      [trackingDetail]="{ event_name: 'transaction_history' }"
    ></ui-nav-exit-back>
  </div>
  <div content-section *ngIf="transactionsHistory">
    <ng-content></ng-content>

    <ng-container *ngIf="(transactionsHistory|async) as transactions;else spinner">
      <ng-container *ngIf="transactions.pages">
        <ng-container *ngFor="let page of transactions.pages;trackBy:pageId">
          <brightside-web-transaction-cell
            @enterAnimation
            *ngFor="let transaction of page.transactions;trackBy:transactionId"
            [transaction]="transaction">
          </brightside-web-transaction-cell>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div footer-actions>
    <ng-container *ngIf="(hasMoreTransactions|async) && (transactionsHistory|async)">
      <div class="pt-sm pb-sm">
        <button
          bw-button
          (click)="loadMore()"
          [processing]="processing"
        >
          {{ 'TRANSACTIONS_HISTORY_LOAD_MORE_WEB' | translate}}
        </button>
      </div>
    </ng-container>
  </div>
</bw-action-article>

<ng-template #spinner>
  <bw-spinner></bw-spinner>
</ng-template>
