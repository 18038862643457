import { Component } from '@angular/core';
import {Subscription} from "rxjs";
import {
  CompanyService,
  StatusMapStepperInterface,
} from "@brightside-web/desktop/data-access/shared";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

interface stepsObjects {
  label: string;
  details?: string;
  status: string;
}

@Component({
  selector: 'brightside-web-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent {

  private sub = new Subscription();
  company = '';
  continueBtnKey = 'CLOSE';
  allTheSteps: stepsObjects[];
  statusTitle = 'VERIFICATION_STATUS_TITLE';
  statusBody = 'VERIFICATION_STATUS_DESC';
  verificationStatus = 'pending';
  hasStepperInfo: boolean;

  constructor(
    private companyService: CompanyService,
    private router: Router,
    private translateService: TranslateService,
  ) {
    if (this.router.url && this.router.url.includes('registration')) {
      this.continueBtnKey = 'BUTTON_CONTINUE';
    }
    if (this.router.getCurrentNavigation()?.extras.state && this.router.getCurrentNavigation()?.extras.state?.stepperInfo) {
      this.createStepper(this.router.getCurrentNavigation()?.extras.state?.stepperInfo);
      this.hasStepperInfo = true;
    }
    this.sub.add(
      this.companyService.company.subscribe(
        value => {
          if (value) {
            this.company = value;

            if (!this.hasStepperInfo) {
              // defaulting to pending
              this.sub.add(
                this.translateService.get('VERIFICATION_SUBMITTED').subscribe(
                  translation => {
                    this.allTheSteps = [
                      {
                        label: translation,
                        status: 'complete'
                      },
                      {
                        label: this.translateService.instant('VERIFICATION_VALIDATING'),
                        details: this.translateService.instant('VERIFICATION_VALIDATING_DESC'),
                        status: 'current'
                      },
                      {
                        label: this.translateService.instant('VERIFICATION_COMPLETE'),
                        status: 'incomplete'
                      }
                    ];
                  }
                )
              )
            }
          }
        }
      )
    )
  }


  createStepper(stepperInfo: StatusMapStepperInterface) {
    this.statusTitle = stepperInfo.title;
    this.statusBody = stepperInfo.textBody;
    this.verificationStatus = stepperInfo.status ? stepperInfo.status : 'unknown';
    if (this.verificationStatus === 'failed') {
      this.continueBtnKey = 'BUTTON_CONTINUE';
    }
    this.allTheSteps = [];

    stepperInfo.steps?.forEach(step => {
      const stepLabel = this.translateService.instant(step.label);
      const stepDetails = step.details ? this.translateService.instant(step.details) : '';
      this.allTheSteps.push({
        label: stepLabel,
        details: stepDetails,
        status: step.status
      });
    });

  }

  continue() {
    if (this.verificationStatus && this.verificationStatus === 'failed') {
      this.router.navigate(['verification/employment']);
    } else {
      this.router.navigate(['/']);
    }
  }

}
