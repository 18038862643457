import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {EmailVerificationService} from "./email-verification.service";
import {Observable} from "rxjs";
import {AbstractControl, FormControl, FormGroup, Validators} from "@angular/forms";
import {EMAIL_PATTERN} from "@brightside-web/shared/desktop";
import {FeatureFlagService, SimpleGlobalModalService} from "@brightside-web/desktop/data-access/core-services";
import {debounceTime, take} from "rxjs/operators";

@Component({
  selector: 'brightside-web-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent {

  @ViewChild('changeEmailModal') changeEmailRef: TemplateRef<any>;

  errorMsgs = {
    email: {
      required: 'ERROR_INVALID_EMAIL',
      mustMatch: 'EMAIL_UPDATE_MUST_MATCH',
      pattern: 'ERROR_INVALID_EMAIL',
      email: 'ERROR_INVALID_EMAIL'
    },
    emailComp: {
      required: 'ERROR_INVALID_EMAIL',
      mustMatch: 'EMAIL_UPDATE_MUST_MATCH',
      pattern: 'ERROR_INVALID_EMAIL',
      email: 'ERROR_INVALID_EMAIL'
    }
  }

  activeErrorMsgs = {
    email: undefined,
    emailComp: undefined
  }

  email$: Observable<string> = this.verifyEmailSvc.currentEmail$;
  currentEmailVerification$: Observable<boolean> = this.verifyEmailSvc.currentEmailVerification$;

  showErrorModal: boolean;

  changeEmailForm = new FormGroup({
    'email': new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    'emailComp': new FormControl('', [
      Validators.required,
      Validators.email
    ])
  },
    {validators: [this.MustMatch('email', 'emailComp')]}
  );
  emailProcessing: boolean;
  showConfirm: boolean;

  private verificationProcessing: boolean;

  constructor(
    private router: Router,
    private verifyEmailSvc: EmailVerificationService,
    private modalSvc: SimpleGlobalModalService,
    private featureFlagSvc: FeatureFlagService,
  ) {
    this.verifyEmailSvc.checkVerification();
    this.changeEmailForm.statusChanges.pipe(debounceTime(500)).subscribe(
      resp => {
        console.log('ken here', resp, this.changeEmailForm);
        this.applyErrorCopy();
      }
    );
  }

  closeErrorModal(b: boolean) {
    this.showErrorModal = false;
    this.router.navigate(['registration','sign_in']);
  }

  resendVerificationEmail() {
    this.verifyEmailSvc.resendVerification();
  }


  MustMatch(controlName: string, matchingControlName: string) {
    return (group: AbstractControl) => {
      const control = group.get(controlName);
      const matchingControl = group.get(matchingControlName);

      if (!control || !matchingControl) {
        return null;
      }

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return null;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
      return null;
    }
  }

  submitNewEmail() {
    this.emailProcessing = true;
    if (this.changeEmailForm.valid) {
      this.verifyEmailSvc.updateEmail(this.changeEmailForm.controls['email'].value ?? '');
      this.email$.pipe(take(1)).subscribe(
        resp => {
          setTimeout(()=>{this.modalSvc.clearModal();}, 500);
        }
      )
    }
  }

  showChangeEmail() {
    this.emailProcessing = false;
    this.modalSvc.createModal({
      title: 'EMAIL_UPDATE_TITLE',
      displayCloseButton: true,
      eventName: 'email_verify_prompt_change_email',
      isError: false,
      size: "medium",
      templateRef: this.changeEmailRef
    });
  }

  applyErrorCopy() {
    for (const controlsKey in this.changeEmailForm.controls) {
      if (Object.keys(this.activeErrorMsgs).includes(controlsKey)) {
        const control = this.changeEmailForm.get(controlsKey);
        if (control?.errors) {
          const firstError = Object.keys(control.errors)[0];
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.activeErrorMsgs[controlsKey] = this.errorMsgs[controlsKey][firstError];
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.activeErrorMsgs[controlsKey] = undefined;
        }
      }
    }
  }

  runVerification() {
    this.verificationProcessing = true;
    this.currentEmailVerification$.pipe(
      take(1)
    ).subscribe(
      verified => {
        setTimeout(()=>{
          if (verified) {
            this.verifyEmailSvc.sendEmailVerifiedToast();
          } else {
            this.verificationProcessing = false;
            this.showConfirm = false;
            this.verifyEmailSvc.sendEmailNotVerifiedToast();
          }
        }, 500);
      }
    )
    this.verifyEmailSvc.checkVerification();
  }
}
