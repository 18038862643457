import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import {
  FirebaseService,
  LinkedAccountVerifyRequestBody,
  LinkedAccountVerifyResponse,
  LinkedBank
} from '@brightside-web/desktop/data-access/shared';
import { LinkedAccountService } from '@brightside-web/desktop/data-access/account-linking';
import { SavingsAccountService } from '@brightside-web/desktop/data-access/savings';
import { RoutingStateService, ToastService } from '@brightside/brightside-ui-services';

import { Subscription } from 'rxjs';
import {
  BsHubService,
} from '@brightside-web/desktop/data-access/core-services';
import * as moment from 'moment-business-days';
import { MenuItem } from '@brightside/brightside-ui';
import {MessageBusEventUtil, MessageBusInternalService} from "@brightside-web/micro/core/message-bus";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'brightside-web-account-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class AccountVerifyComponent implements OnInit {
  // ToDo: should have an object passed in that can have all these configs - maybe
  @Input() pageStepperActiveIndex = -1;
  @Input() pageOnBackCtaPath: string[] = [];
  @Input() pageOnForwardCtaPath: string[] = [];

  @Input() fbPage = '';
  @Input() fbCategory = '';
  @Input() fbEventName = '';

  protected sub = new Subscription();
  protected bankDetails: LinkedBank;
  protected expectedMicroDate = '';
  protected attemptsLeft = 5;
  protected resendAttemptsTaken = 1;

  formAmountOneErrorMessage = '';
  formAmountTwoErrorMessage = '';

  routingForm = this.fb.group({
    amountOne: [
      '',
      [Validators.required, Validators.pattern(/^0\.[0-9]{1,2}$/), Validators.min(0.01), Validators.max(0.99)],
    ],
    amountTwo: [
      '',
      [Validators.required, Validators.pattern(/^0\.[0-9]{1,2}$/), Validators.min(0.01), Validators.max(0.99)],
    ],
  });

  pageTitle:string;
  pageSubTitle = '';
  pageCtaLabel :string;
  pageSecondaryCtaLabel :string;
  pageStepperItems: MenuItem[] = [{}, {}, {}, {}];

  resendLineWithDate = '';
  resendTimesLeft :string;
  resendPrimaryCta :string;
  resendSecondaryCta :string;

  processing = false;
  fatalError = false;

  isCtaDisabled = true;

  showError = false;
  showResend = false;
  showTryAnotherWay = false;

  constructor(
    protected analytics: FirebaseService,
    private routingState: RoutingStateService,
    private router: Router,
    private toastService: ToastService,
    private savingsService: SavingsAccountService,
    private linkedAccountService: LinkedAccountService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private bsHubService: BsHubService
  ) {
    const data = this.activatedRoute.snapshot.data;
    this.pageStepperActiveIndex = data.pageStepperActiveIndex;
    this.pageOnForwardCtaPath = data.pageOnForwardCtaPath;
    this.pageOnBackCtaPath = data.pageOnBackCtaPath;
    this.fbPage = data.fbPage;
    this.fbCategory = data.fbCategory;
    this.fbEventName = data.fbEventName;
    this.pageSubTitle = this.checkValidSubTitleAndReturn();

    this.pageTitle = this.translateService.instant('MICRO_VERIFY_ACCOUNT_TITLE');
    this.pageCtaLabel = this.translateService.instant('MICRO_VERIFY_ACCOUNT_CTA');
    this.pageSecondaryCtaLabel = this.translateService.instant('MICRO_VERIFY_ACCOUNT_SECONDARY_CTA');
    this.resendPrimaryCta = this.translateService.instant('MICRO_VERIFY_ACCOUNT_RESEND_CTA');
    this.resendSecondaryCta = this.translateService.instant('MICRO_VERIFY_ACCOUNT_RESEND_SECONDARY_CTA');
  }

  ngOnInit() {
    this.setUpHub();
    this.subscribeAndLoadSavings();

    this.routingForm.valueChanges.subscribe(() => {
      // Clear errors once values change
      this.showError = false;
    });
  }

  get amountOne() {
    return this.routingForm.get('amountOne');
  }
  get amountTwo() {
    return this.routingForm.get('amountTwo');
  }

  private setUpHub() {
    this.bsHubService.listen('ErrorChannel', () => {
      this.handleCtaClick();
    });
  }

  private showResendModal(withSecondaryCta: boolean = true) {
    this.resendLineWithDate = this.translateService.instant('MICRO_VERIFY_ACCOUNT_RESEND_LINE', {
      lastFourAccountNumber: this.bankDetails.last_four,
      expectedMicroDate: this.expectedMicroDate,
    });
    this.resendSecondaryCta = withSecondaryCta ? this.resendSecondaryCta : '';

    this.showResend = true;
    this.showTryAnotherWay = false;
  }

  private showTryAnotherWayModal() {
    this.showResend = false;
    this.showTryAnotherWay = true;
  }

  private checkValidSubTitleAndReturn() {
    //Make sure we show try another way if we are at zero
    if (this.attemptsLeft <= 0) {
      this.showResendModal(false);
    }

    return this.translateService.instant('MICRO_VERIFY_ACCOUNT_SUB_TITLE', {
      attemptsLeft: this.attemptsLeft,
    });
  }

  private checkValidErrorSubTitleAndReturn() {
    const attemptString = ` you have ${this.attemptsLeft} attempt${this.attemptsLeft === 1 ? '' : 's'} left.`;

    //Make sure we show try another way if we are at zero
    if (this.attemptsLeft <= 0) {
      this.showResendModal(false);
    }

    return this.translateService.instant('MICRO_VERIFY_ACCOUNT_ERROR_SUB_TITLE', {
      attemptsLeft: this.attemptsLeft,
    });
  }

  private checkValidResendTimesAndReturn() {
    let resendsMsg;

    if (this.resendAttemptsTaken === 1) {
      resendsMsg = this.translateService.instant('MICRO_VERIFY_ACCOUNT_TWO_RESEND_TIMES_LEFT');
    } else if (this.resendAttemptsTaken === 2) {
      resendsMsg = this.translateService.instant('MICRO_VERIFY_ACCOUNT_ONE_RESEND_TIMES_LEFT');
    } else {
      resendsMsg = this.translateService.instant('MICRO_VERIFY_ACCOUNT_ZERO_RESEND_TIMES_LEFT');
      this.resendPrimaryCta = '';
    }

    return resendsMsg;
  }

  private getTwoBusinessDays(dateString: string) {
    return moment(new Date(dateString)).local().businessAdd(2).startOf('day').format('MMM Do');
  }

  private subscribeAndLoadSavings() {
    //TODO we could use an angular resolver to pre-load the right bank
    const bankId = this.activatedRoute.snapshot.paramMap.get('id');
    const handleSuccess = (banks: LinkedBank[]) => {
        const bank = banks.find(lb => String(lb.id) === bankId);
        if (!bank){
          return handleFailure();
        }
        if (bank.micro_meta && bank.micro_meta.micro_deposit_initiated_ts) {
          this.bankDetails = bank;
          this.expectedMicroDate = bank.micro_meta.micro_deposit_initiated_ts;
          this.attemptsLeft = bank.micro_meta.remaining_verification_attempts;
          this.resendAttemptsTaken = bank.micro_meta.sent_micro_deposit_attempts;
        }

        this.pageSubTitle = this.checkValidSubTitleAndReturn();
        this.resendTimesLeft = this.checkValidResendTimesAndReturn();

      this.processing = false;
      this.fatalError = false;
    };
    const handleFailure = () => {
      this.processing = false;
      this.analytics.logEvent('error_shown', {'error id': 'fetch_linked_banks'});
      this.fatalError = true;
    };

    this.sub.add(this.savingsService.getLinkedBanks().subscribe(handleSuccess, handleFailure));
  }

  private verifyAccount() {
    const requestBody: LinkedAccountVerifyRequestBody = {
      bankId: this.bankDetails.id,
      amounts: [this.amountOne?.value, this.amountTwo?.value],
    };
    const handleSuccess = (response: LinkedAccountVerifyResponse) => {
      this.toastService.delete('verifyLinkedAccount');
      this.routingState.popAndNavigateTo(this.pageOnForwardCtaPath);
    };
    const handleFailure = (error: any) => {
      console.log(error);

      this.processing = false;
      this.fatalError = false;
      this.showError = true;

      this.attemptsLeft -= 1;
      this.pageSubTitle = this.checkValidErrorSubTitleAndReturn();
    };

    this.processing = true;
    this.showError = false;
    this.linkedAccountService.verifyAccount(requestBody).subscribe(handleSuccess, handleFailure);
  }

  private resendDeposits() {
    const handleSuccess = (response: LinkedAccountVerifyResponse) => {
      this.routingState.popAndNavigateTo(this.pageOnBackCtaPath);
    };
    const handleFailure = () => {
      this.processing = false;
      this.fatalError = true;

      this.showTryAnotherWayModal();
    };

    this.processing = true;
    this.linkedAccountService.resendDeposits(this.bankDetails?.id || 0).subscribe(handleSuccess, handleFailure);
  }

  handleSecondaryCtaClick() {
    // Make sure we check if resend are availble
    if(this.resendAttemptsTaken > 2) {
      this.showTryAnotherWayModal();
      return;
    }

    this.showResendModal();
  }

  handleCtaClick() {
    if (this.processing || !this.amountOne?.value || !this.amountTwo?.value) {
      return;
    }

    if (!this.bankDetails) {
      this.toastService.error(this.translateService.instant('MICRO_VERIFY_ACCOUNT_ERROR_TOAST'), {
        link: false,
        transient: false,
      });
      return;
    }

    this.verifyAccount();
  }

  handleResendCtaClick() {
    this.resendDeposits();
  }

  handleTryAnotherCtaClick() {
    MessageBusInternalService.sendOutgoingHubEvent(MessageBusEventUtil.event.standard.exitFeatureToChat)
    this.analytics.logEvent("chat_shown", {source: 'auto'}, true);
  }

  handleTryAnotherSecondaryCtaClick() {
    this.routingState.popAndNavigateTo(this.pageOnBackCtaPath);
  }
}
