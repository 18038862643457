import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevLogInComponent } from './dev-log-in/dev-log-in.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [DevLogInComponent],
  exports: [DevLogInComponent],
})
export class MicroCoreAuthorizationModule {}
