import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Route, Router,
  RouterStateSnapshot,
} from '@angular/router';
import {Observable, of, Subscription} from 'rxjs';
import { VerificationStatusService, VerificationStatusEnum } from "@brightside-web/desktop/data-access/shared";
import {map, tap} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class EmploymentFailedGuard implements CanActivate {
  sub = new Subscription()

  constructor(private verificationSvc: VerificationStatusService, private router:Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.verificationSvc.getEmploymentVerificationStatusFromAPI().pipe(
      map( response => {
        return true;
        if (response.employment_data_verification_status.status === VerificationStatusEnum.FAILED) {
          return true;
        } else {
          this.router.navigate(['/']);
          return false;
        }
      })
    );

  }
}
