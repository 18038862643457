import { Component, OnDestroy, OnInit } from '@angular/core';
import { ForgotPasswordResponse, RegistrationService } from '@brightside-web/desktop/data-access/onboarding';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiResponse, BasicComponentConfig, CompanyService } from '@brightside-web/desktop/data-access/shared';
import { AxiosError } from 'axios';
import {
  BsAuthService,
  BsCacheService
} from "@brightside-web/desktop/data-access/core-services";
import { from, Subscription } from 'rxjs';
import {CONTROL_DOB, CONTROL_LNAME, CONTROL_LAST_FOUR} from "../display-form/controls/controls";
import * as moment from "moment";

@Component({
  selector: 'brightside-web-verify-identity',
  templateUrl: './verify-identity.component.html',
  styleUrls: ['./verify-identity.component.css']
})
export class VerifyIdentityComponent implements OnInit, OnDestroy {
  verifyForm:FormGroup;
  showFaModal: boolean;
  showHrModal: boolean;
  processing: boolean;
  sub = new Subscription();
  private isRegistration: boolean;
  registerModal:boolean;
  company:string;
  config: BasicComponentConfig;
  errorCount = 0;
  showMaxAttemptsModal: boolean;

  verifyIdentityControls = {
    lastName: CONTROL_LNAME,
    last4ssn: CONTROL_LAST_FOUR,
    dateOfBirth: CONTROL_DOB
  }

  constructor(
    private regSvc:RegistrationService,
    private router:Router,
    private route:ActivatedRoute,
    private companyService: CompanyService,
    private bsAuthService: BsAuthService,
    private bsCacheService: BsCacheService
  ) { }



  ngOnInit() {
    this.bsCacheService.removeItem('phoneNumber');
    this.bsCacheService.removeItem('recoveryToken');
    this.bsCacheService.removeItem('email');
    this.bsCacheService.removeItem('emailVerified');
    this.bsCacheService.removeItem('phoneVerified');
    this.checkForConfigViaData();

    this.verifyForm = new FormGroup({
      lastName: CONTROL_LNAME.control,
      last4ssn: CONTROL_LAST_FOUR.control,
      dateOfBirth: CONTROL_DOB.control
    });

    this.sub.add(from(this.bsAuthService.currentAuthenticatedUser()).subscribe(_ => {
    }, ()=>{
      this.isRegistration = true;
    }));

    this.sub.add(
      this.companyService.company.subscribe(
        value => {
          this.company = value;
        }
      )
    );
  }

  private checkForConfigViaData() {
    if (this.route?.snapshot?.data?.pageConfig) {
      this.config = new BasicComponentConfig(this.route.snapshot.data.pageConfig);
      this.config.fbPage = "verify_identity";
    } else {
      this.config = new BasicComponentConfig();
    }
  }

  submit() {
    this.processing = true;
    this.sub.add(this.regSvc.forgotPasswordPrecheck({
      last_four_ssn: this.verifyForm.controls['last4ssn'].value,
      last_name: this.verifyForm.controls['lastName'].value,
      date_of_birth: this.verifyForm.controls['dateOfBirth'].value
    }, this.isRegistration).subscribe(async (resp: ForgotPasswordResponse) => {
      try {
        console.log('ken is in here', resp);
        await Promise.all([
          this.bsCacheService.setItem('phoneNumber', resp.phoneNumber),
          this.bsCacheService.setItem('recoveryToken', resp.recoveryToken, { expires: moment().add(10, 'minutes').valueOf() }),
          this.bsCacheService.setItem('email', resp.email),
          this.bsCacheService.setItem('emailVerified', resp.emailVerified),
          this.bsCacheService.setItem('phoneVerified', resp.phoneVerified)
        ]);
        this.router.navigate(['registration/sign-in-help']);
      } catch (error) {
        console.error('Error setting cache items:', error);
      }
    }, (err: AxiosError) => {
      this.processing = false;
      const fallbackErr = { statusCode: 0, message: '' } as ApiResponse;
      this.handleResponse(
        err.response && err.response.data && typeof err.response.data === 'object' ? err.response.data : fallbackErr
      );
    }));
  }


  handleResponse(resp: unknown){
    const respAPI = resp as ApiResponse;
    this.errorCount++
    if (this.errorCount >= 3) {
      this.showMaxAttemptsModal = true;
    } else if (respAPI.statusCode && respAPI.statusCode === 412){
      this.registerModal = true;
    } else {
      this.showHrModal = true;
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  register(){
    this.bsCacheService.setItem('lastName', this.verifyForm.controls['lastName'].value);
    this.router.navigate(['/registration','sign_up', {prepopulate:true}]);
  }

  closeErrorModal() {
    this.router.navigate(['/']);
  }
}
