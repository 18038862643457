import { MicroContestTranslationKey } from '../../model/contest.enum';
import { MicroContestTranslation } from '../../model/contest.interface';

const TranslationKeyValues: MicroContestTranslation = {
  [MicroContestTranslationKey.CONTEST_ENTRY_TITLE]: 'New monthly sweepstakes!',
  [MicroContestTranslationKey.CONTEST_ENTRY_SUB_TITLE]: 'Be one of the many each month to win {{prizeAmount}}!',
  [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA1_TITLE]: 'Get rewarded for saving',
  [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA1_SUB_TITLE]: 'Dozens of lucky people will be randomly selected each month to win {{prizeAmount}}!',
  [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA2_TITLE]: 'Easy to participate',
  [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA2_SUB_TITLE]: 'Just turn on your autosave, and watch your savings grow',
  [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA3_TITLE]: 'Save on any budget',
  [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA3_SUB_TITLE]: 'You can adjust your autosave amount at any time',
  [MicroContestTranslationKey.CONTEST_ENTRY_FOOTER_LINK]: 'Full sweepstakes rules',
  [MicroContestTranslationKey.CONTEST_ENTRY_CTA1]: 'I’m ready to win!',
  [MicroContestTranslationKey.CONTEST_ENTRY_RULES_TITLE]: 'Full sweepstakes rules',
  [MicroContestTranslationKey.CONTEST_ENTRY_RULES_SUB_TITLE]: 'Monthly Brightside Saver Sweepstakes',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_TITLE]: 'Choose your own amount',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_SUB_TITLE]: 'How much per day do you wish to contribute?',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_CTA1]: 'Continue',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_CTA2]: 'Cancel',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_TITLE]: 'Choose your autosave plan',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_SUB_TITLE]: 'Deposit money right from your paycheck into your savings account',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CTA1]: 'Continue',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_TITLE]: 'Confirm your autosave amount',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_SUB_TITLE]: 'Your <span class="brand">{{dailySavingsAmount}} daily savings</span> will be added to your Brightside Savings account from each paycheck.',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA1_TITLE]: 'Frequency',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA1_SUB_TITLE]: 'Per paycheck',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA2_TITLE]: 'Amount (est.)',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA2_SUB_TITLE]: '{{autosaveAmount}}',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA3_TITLE]: 'First paycheck date',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA3_SUB_TITLE]: '{{autosaveDate}}',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_INFO]: 'Funds are held by Evolve Bank & Trust, Member FDIC',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_CTA1]: 'Confirm amount',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_CTA2]: 'Edit amount',
  [MicroContestTranslationKey.CONTEST_ENTRY_COMPLETE_TITLE]: 'Woohoo, you’re a saver!',
  [MicroContestTranslationKey.CONTEST_ENTRY_COMPLETE_SUB_TITLE]: `Letting your savings grow is the best way to prepare for the future. <br/><br/> Make sure to check back at the end of the month to see if you've won!`,
  [MicroContestTranslationKey.CONTEST_ENTRY_COMPLETE_CTA1]: 'Go to savings',
  [MicroContestTranslationKey.CONTEST_RESULT_TITLE]: 'Time to see if you won!',
  [MicroContestTranslationKey.CONTEST_RESULT_SUB_TITLE]: 'Sweepstakes results are in!',
  [MicroContestTranslationKey.CONTEST_RESULT_EXTRA_TITLE]: '',
  [MicroContestTranslationKey.CONTEST_RESULT_EXTRA_SUB_TITLE]: 'Tap below to see if you’re one of the {{numberOfWinners}} lucky savers who won {{prizeAmount}}, just for autosaving from your paycheck!',
  [MicroContestTranslationKey.CONTEST_RESULT_CTA1]: 'Show results',
  [MicroContestTranslationKey.CONTEST_WON_TITLE]: 'You are one of the lucky winners!',
  [MicroContestTranslationKey.CONTEST_WON_SUB_TITLE]: 'Congratulations!!!',
  [MicroContestTranslationKey.CONTEST_WON_EXTRA_TITLE]: '',
  [MicroContestTranslationKey.CONTEST_WON_EXTRA_SUB_TITLE]: 'Savings does pay off! Your Brightside Savings balance will reflect your {{prizeAmount}} winnings within the next 3 business days.',
  [MicroContestTranslationKey.CONTEST_WON_CTA1]: 'Go to Savings',
  [MicroContestTranslationKey.CONTEST_LOST_TITLE]: 'You haven\'t won...yet',
  [MicroContestTranslationKey.CONTEST_LOST_SUB_TITLE]: "Thanks for playing!",
  [MicroContestTranslationKey.CONTEST_LOST_EXTRA_TITLE]: '',
  [MicroContestTranslationKey.CONTEST_LOST_EXTRA_SUB_TITLE]: `You can still get a $10 bonus! Refer a co-worker and you'll both get $10 added to your Brightside Savings Account after their first autosavings deposit. And keep autosaving for a chance to win next month!`,
  [MicroContestTranslationKey.CONTEST_LOST_CTA1]: 'Go to Savings',
  [MicroContestTranslationKey.CONTEST_MORE_TITLE]: 'Autosave rewards',
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_ONE_TITLE]: 'Program details',
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_ONE_SUB_TITLE]: 'On the last business day every month, dozens of Brightside autosavers will be randomly selected to win $100.',
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_TWO_TITLE]: 'Eligibility',
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_TWO_SUB_TITLE]: 'Eligibility is simple - have your autosave turned on. As long as you’re an active autosaver, you’re automatically entered!',
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_THREE_TITLE]: 'Alternative method of entry',
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_THREE_SUB_TITLE]: `If you'd like to register for the Sweepstakes without autosaving, please print out <a href="loader?pageOnForwardCtaPath=/contest-promo/contest-legal/attach-client">this form</a> and mail it to the address noted in the instructions. Entries received within five (5) business days of the end of the month will be included in the next monthly Sweepstakes.`,
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_FOUR_TITLE]: 'Important dates',
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_FOUR_SUB_TITLE]: `Make sure your autosave is active prior to the final day of the month to ensure your automatic eligibility. If it's not, you can turn it on and become eligible for the next month's drawing.`,
  [MicroContestTranslationKey.CONTEST_MORE_LINK]: 'Full sweepstakes rules',
  [MicroContestTranslationKey.CONTEST_MORE_CTA1]: `I'm ready to win!`,
  [MicroContestTranslationKey.CONTEST_CARD_TITLE]: `Your rewards`,
  [MicroContestTranslationKey.CONTEST_TITLE]: 'Autosave sweepstakes',
  [MicroContestTranslationKey.CONTEST_SUB_TITLE]: ``,
  [MicroContestTranslationKey.CONTEST_PROMO_TITLE]: ``,
  [MicroContestTranslationKey.CONTEST_PROMO_MORE_TITLE]: `Program details`,
  [MicroContestTranslationKey.CONTEST_SUB_TITLE_DYNAMIC]: `Next drawing {{drawingDate}}`,
  [MicroContestTranslationKey.CONTEST_PROMO_TITLE_PENDING]: `{{contestWinnersTotal}} Autosavers are guaranteed to win {{contestPrizeTotal}} this month.`,
  [MicroContestTranslationKey.CONTEST_PROMO_TITLE_WINNER]: `Saving paid off! You won {{contestPrizeTotal}}! 🎉`,
  [MicroContestTranslationKey.CONTEST_PROMO_TITLE_LOST]: `Keep saving to remain eligible for next month’s drawing!`,
  [MicroContestTranslationKey.CONTEST_PROMO_MORE_INFO_CTA1]: `Got it`,
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_AMOUNT_FREQUENCY]: `per day`,
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_AMOUNT_OPTION_1]: `⭐️ Easiest way to start! ⭐`,
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_AMOUNT_OPTION_2]: `Choose your own amount`,
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_ERROR]: `We could not update your Autosave schedule`,
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_LABEL]: `Per day`,
  [MicroContestTranslationKey.CONTEST_APP_SERVICE_ERROR]: `We could not find a contest id to complete this request`,
  [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_SUB_TITLE]: `To enter without autosaving, print out this entry form and mail to: Brightside Benefit Sweepstakes, 55 N Arizona Pl. Suite 200, Chandler AZ 85225. <br /><br /> Entries received more than five (5) business days from the last day of the month will be entered in the current month's Sweepstakes. Entries received within five (5) business days of the end of the month will be included in the next monthly Sweepstakes. <br /><br /> By printing and mailing this form, you acknowledge and agree to the Sweepstakes <a href="//www.gobrightside.com/terms-of-service" target="_blank">Terms of Service</a> and the Brightside <a href="//www.gobrightside.com/privacy-policy" target="_blank">Privacy Policy</a>.`,
  [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_TITLE]: "Alternative Method of Entry",
  [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_SUB_TITLE]: "Monthly Brightside Savers Sweepstakes",
  [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_FNAME_LABEL]: "First name",
  [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_LNAME_LABEL]: "Last name",
  [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_PHONE_LABEL]: "Phone number",
}

export default TranslationKeyValues;
