import { NgModule } from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MicroUiMoleculeModule } from '@micro-ui/molecule';
import { BrightsideUiModule, NgxMaskModule, ngxMaskOptions } from '@brightside/brightside-ui';
import { BrightsideUiServicesModule } from '@brightside/brightside-ui-services';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PhonePipe } from './phone.pipe';
import { FbDirective } from './fb.directive';
import { NestedFormGroupComponent } from './nested-form-group/nested-form-group.component';
import { SafePipe } from './safe.pipe';
import { CallFaComponent } from './call-fa/call-fa.component';
import { VerifyIdentityComponent } from './verify-identity/verify-identity.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { HrModalComponent } from './hr-modal/hr-modal.component';
import { VerifyDobComponent } from './verify-dob/verify-dob.component';
import { SignUpPromptComponent } from './sign-up-prompt/sign-up-prompt.component';
import { HowWeHelpComponent } from './how-we-help/how-we-help.component';
import { DesktopCardComponent } from './desktop-card/desktop-card.component';
import { DesktopCardsComponent } from './desktop-cards/desktop-cards.component';
import { DisplayMessageComponent } from './display-message/display-message.component';
import { DisplayQuestionsComponent } from './display-questions/display-questions.component';
import { DisplayCardsComponent } from './display-cards/display-cards.component';
import { DisplayFormComponent } from './display-form/display-form.component';
import { DisplayLoaderComponent } from './display-loader/display-loader.component';
import { BadgeIdFormComponent } from "./badge-id-form/badge-id-form.component";

import { WaitingComponent } from './waiting/waiting.component';
import { GenericModalComponent } from './generic-modal/generic-modal.component';
import { CreditModalComponent } from './credit-modal/credit-modal.component';
import {TimeoutDirective} from "./timeout.directive";

import {TranslateModule, TranslatePipe} from "@ngx-translate/core";
import { EligibilityCheckComponent } from './forms/eligibility-check/eligibility-check.component';
import { GenCellValuePipe } from './gen-cell-value.pipe';
import { ProductEligibilityComponent } from './product-eligibility/product-eligibility.component';
import { MaskEmailPipe } from './mask-email.pipe';
import {FeatureFlagDirective} from "./feature-flag.directive";
import { NewFeatureFlagDirective } from "./new-feature-flag.directive";
import { GoToBrightsideDesktopComponent } from './go-to-brightside-desktop/go-to-brightside-desktop.component';
import { MonthNamePipe } from './month-name.pipe';

const exported = [
  CallFaComponent,
  DesktopCardsComponent,
  DisplayMessageComponent,
  DisplayQuestionsComponent,
  CreditModalComponent,
  DisplayCardsComponent,
  DisplayFormComponent,
  HowWeHelpComponent,
  DisplayLoaderComponent,
  HrModalComponent,
  ResetPasswordComponent,
  VerifyIdentityComponent,
  VerifyDobComponent,
  WaitingComponent,
  PhonePipe,
  FbDirective,
  TimeoutDirective,
  SafePipe,
  BadgeIdFormComponent,
  EligibilityCheckComponent,
  GenCellValuePipe,
  ProductEligibilityComponent,
  MaskEmailPipe,
  FeatureFlagDirective,
  NewFeatureFlagDirective,
  GoToBrightsideDesktopComponent,
  MonthNamePipe
];

@NgModule({
  imports: [
    BrightsideUiModule,
    BrightsideUiServicesModule,
    CommonModule,
    FormsModule,
    MicroUiMoleculeModule,
    NgxMaskModule.forRoot(ngxMaskOptions),
    ReactiveFormsModule,
    TranslateModule
  ],
  declarations: [
    ...exported,
    DesktopCardComponent,
    GenericModalComponent,
    NestedFormGroupComponent,
    SignUpPromptComponent,
    EligibilityCheckComponent,
    GenCellValuePipe,
    ProductEligibilityComponent,
    MaskEmailPipe,
    MonthNamePipe,
  ],
  exports: exported,
  providers: [TranslatePipe, DatePipe],
  schemas: [],
})
export class SharedDesktopModule {}

export const EMAIL_PATTERN = '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,63}$';
