import { Component, OnInit } from '@angular/core';
import { LocationStrategy } from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";

import {
  BsCacheService,
  BsHubService
} from "@brightside-web/desktop/data-access/core-services";

import {MobileStateService, ProductEligibilityService} from "@brightside-web/desktop/data-access/shared";
import {map} from "rxjs/operators";
import {LayoutStyle, RoutingServiceDispatchEvent} from "@brightside-web/micro/core/utility";
import {RoutingService} from "../../../../../../../apps/desktop/src/app/routing.service";
import { CreditSetupService } from 'libs/desktop/data-access/credit/src/lib/setup/credit-setup.service';

interface IdentityErrorInterface {
  titleKey: string;
  bodyKey: string;
  ctaKey?: string;
}

@Component({
  selector: 'brightside-web-identity',
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.scss']
})
export class ProductIdentityComponent implements OnInit {

  errors: {[key: string]: IdentityErrorInterface}  = {
    credit_dit_error: {
      titleKey: 'CREDIT_DIT_HALFSHEET_TITLE',
      bodyKey: 'CREDIT_DIT_HALFSHEET_DESC',
      ctaKey: 'BUTTON_CONTINUE'
    }
  };

  visibleError: IdentityErrorInterface;
  showError = '';

  constructor(
    private routeState: ActivatedRoute,
    private router: Router,
    private productEligibilityService: ProductEligibilityService,
    private mobileStateService: MobileStateService,
    private bsCacheService: BsCacheService,
    private bsHubService: BsHubService,
    private _creditSetupService: CreditSetupService) {
    const state = this.router.getCurrentNavigation()?.extras.state;

    if (state?.error) {
      const errorKey = state.error as string;
      if (this.errors[errorKey]) {
        this.visibleError = this.errors[errorKey];
        this.showError = errorKey;

        this._creditSetupService.blankPatchToCreditSub(); // TODO: check if we want to hadle a response here
      }
    }
    this.mobileStateService.get().subscribe(
      ms => {
        if (!ms.onboardingComplete) {
          this.bsHubService.dispatch(RoutingService.DISPATCH_KEY,
            { event: RoutingServiceDispatchEvent.LAYOUT_CHANGE, message: LayoutStyle.FULL_SCREEN }
          );
        }
      }
    )
  }

  ngOnInit(): void {
    const state = this.router.getCurrentNavigation()?.extras.state;
  }

  submitAction(event: string) {
    // only called on successful submission of form.
    // expects valid path string
    setTimeout(() => {
      this.bsCacheService.setItem(event, true);
      this.productEligibilityService.completedVerifications.push(event);
      this.router.navigate([event], {queryParams: {fromFormSubmission: true}});
    });
  }

}
