<ng-container *ngIf="wrapWithCell; else withOutWrapping">
  <bw-cell separatorType="none" [contentLeft]="true">
    <ng-container content-left *ngTemplateOutlet="consentBody; context: { $implicit: consent }"></ng-container>
  </bw-cell>
</ng-container>

<ng-template #withOutWrapping>
  <ng-container *ngTemplateOutlet="consentBody; context: { $implicit: consent }"></ng-container>
</ng-template>

<ng-template #consentBody let-consent>
  <typography text-medium-semibold color="var(--primary-text)">
    {{ consent.descriptionTitle | translate }}
  </typography>
  <typography text-medium-regular color="var(--secondary-text)">
    <div [innerHTML]="((consent.descriptionBeforeLink | translate) || '').trim()"></div>
    <a bw-anchor class="bs-blue" (click)="fetchTerms($event)" *ngIf="consent.descriptionLink"> {{ consent.descriptionLink | translate }}</a>
    <div [innerHTML]="consent.descriptionAfterLink | translate"></div>
  </typography>
</ng-template>
