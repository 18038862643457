import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

import { MessageBusEventUtil, MessageBusOutgoingEventKey } from '@micro-core/message-bus';
import { RoutingStateService } from '@brightside/brightside-ui-services';

import { BsAuthService } from "@brightside-web/desktop/data-access/core-services";
import {from, of} from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import {AuthorizationService} from "../../../../../core/authorization/src";


@Injectable()
export class MicroContestLegalClientResolver implements Resolve<undefined> {
  constructor(
    private routingStateService: RoutingStateService,
    private authorizationService: AuthorizationService,
    private bsAuthService: BsAuthService
    ) {}

  private buildAmoeLink(details: { fName: string; lName: string; phone: string, locale: string }): string {
    return `${window.location.origin}/contest-promo/contest-legal/sweepstakes-amoe?fName=${details.fName}&lName=${details.lName}&phone=${details.phone}&locale=${details.locale}`;
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (Object.keys(this.authorizationService.tokenPayload).length > 0) {
      return of(this.authorizationService.tokenPayload)
        .pipe(
          map((payload) => {
            this.sendRouting(payload);
            return undefined;
          }),
          delay(5000),
          tap(() => {
            this.routingStateService.popAndNavigateTo(['home']);
          })
        )
        .toPromise();
    } else {
      return from(this.bsAuthService.fetchUserAttributes())
        .pipe(
          map((attributes) => {
            // const clientInfo = currentSession.getIdToken().decodePayload();
            const clientInfo = attributes;
            this.sendRouting(clientInfo as {[key:string]:string});
            return undefined;
          }),
          delay(5000),
          tap(() => {
            this.routingStateService.popAndNavigateTo(['home']);
          })
        )
        .toPromise();
    }
  }

  sendRouting(payload: {[key:string]:string}) {
    MessageBusEventUtil.sendOutgoing({
      event: MessageBusOutgoingEventKey.EXTERNAL_LINK,
      bridgeSetting: {
        data: {
          url: this.buildAmoeLink({
            fName: payload['given_name'],
            lName: payload['family_name'],
            phone: payload['cognito:username'],
            locale: payload['locale'] ?? "en"
          }),
          chatIcon: false,
          displayStyle: 'browser',
        },
      },
    });
  }
}
