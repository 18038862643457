<bw-toggle
  label="{{ 'MICRO_AUTOSAVE_PAUSE_LABEL' | translate }}"
  [startSelected]="isCurrentlyPaused"
  [letCallerSetOn]="true"
  (statusChanged)="handleToggleChanged($event)"
></bw-toggle>

<bw-modal
  *ngIf="showConfirmModal"
  [brightsideWebFb]="{ event_name: 'savings_autosave_pause_shown' }"
  modalWidth="medium">
  <typography title-medium>{{ 'MICRO_AUTOSAVE_PAUSE_TITLE' | translate }}</typography>
  <div class="body">
    <typography text-large-regular color="var(--secondary-text)"
      >{{ 'MICRO_AUTOSAVE_PAUSE_TEXT_BODY' | translate }}</typography
    >
  </div>
  <div class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        [ngStyle]="{ 'background-color': 'var(--error)' }"
        (click)="handleConfirmCta()"
        [processing]="processing"
        [brightsideWebFb]="{ event_name: 'savings_autosave_pause_tapped', action: 'click' }">
        <typography text-large-semibold>
          {{ 'MICRO_AUTOSAVE_PAUSE_CONFIRM' | translate }}
        </typography>
      </button>
      <button
        bw-link-button
        class="bs-blue pr-xl"
        (click)="handleCancelCta()"
        [brightsideWebFb]="{ event_name: 'savings_autosave_pause_dismissed', action: 'click' }"
        [dismissedCta]="true">
        {{ 'MICRO_AUTOSAVE_PAUSE_CANCEL' | translate }}
      </button>
    </div>
  </div>
</bw-modal>
