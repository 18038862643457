import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanLoad,
  Route, UrlSegment
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@brightside-web/desktop/data-access/shared';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate, CanLoad {
  constructor(private authService: AuthService, private router:Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.hasCurrentUser().pipe(
      map(hasUser => {
        if (hasUser){
          this.router.navigate(['/home'])
        }
        return !hasUser;
      })
    );
  }
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.hasCurrentUser().pipe(
      map(hasUser => {
        if (hasUser){
          this.router.navigate(['/home'])
        }
        return !hasUser;
      })
    );
  }

}
