import { MicroContestTranslationKey } from '../../model/contest.enum';
import { MicroContestTranslation } from '../../model/contest.interface';

const TranslationKeyValues: MicroContestTranslation = {
  [MicroContestTranslationKey.CONTEST_ENTRY_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_SUB_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA1_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA1_SUB_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA2_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA2_SUB_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA3_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA3_SUB_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_FOOTER_LINK]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_CTA1]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_RULES_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_RULES_SUB_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_SUB_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_CTA1]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_CTA2]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_SUB_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CTA1]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_SUB_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA1_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA1_SUB_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA2_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA2_SUB_TITLE]: '{{autosaveAmount}}',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA3_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA3_SUB_TITLE]: '{{autosaveDate}}',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_INFO]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_CTA1]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_CTA2]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_COMPLETE_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_COMPLETE_SUB_TITLE]: `zzz`,
  [MicroContestTranslationKey.CONTEST_ENTRY_COMPLETE_CTA1]: 'zzz',
  [MicroContestTranslationKey.CONTEST_RESULT_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_RESULT_SUB_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_RESULT_EXTRA_TITLE]: '',
  [MicroContestTranslationKey.CONTEST_RESULT_EXTRA_SUB_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_RESULT_CTA1]: 'zzz',
  [MicroContestTranslationKey.CONTEST_WON_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_WON_SUB_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_WON_EXTRA_TITLE]: '',
  [MicroContestTranslationKey.CONTEST_WON_EXTRA_SUB_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_WON_CTA1]: 'zzz',
  [MicroContestTranslationKey.CONTEST_LOST_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_LOST_SUB_TITLE]: "zzz",
  [MicroContestTranslationKey.CONTEST_LOST_EXTRA_TITLE]: '',
  [MicroContestTranslationKey.CONTEST_LOST_EXTRA_SUB_TITLE]: `zzz`,
  [MicroContestTranslationKey.CONTEST_LOST_CTA1]: 'zzz',
  [MicroContestTranslationKey.CONTEST_MORE_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_ONE_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_ONE_SUB_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_TWO_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_TWO_SUB_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_THREE_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_THREE_SUB_TITLE]: `zzz`,
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_FOUR_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_MORE_CONTENT_FOUR_SUB_TITLE]: `zzz`,
  [MicroContestTranslationKey.CONTEST_MORE_LINK]: 'zzz',
  [MicroContestTranslationKey.CONTEST_MORE_CTA1]: `zzz`,
  [MicroContestTranslationKey.CONTEST_CARD_TITLE]: `zzz`,
  [MicroContestTranslationKey.CONTEST_TITLE]: 'zzz',
  [MicroContestTranslationKey.CONTEST_SUB_TITLE]: `zzz`,
  [MicroContestTranslationKey.CONTEST_PROMO_TITLE]: `zzz`,
  [MicroContestTranslationKey.CONTEST_PROMO_MORE_TITLE]: `zzz`,
  [MicroContestTranslationKey.CONTEST_SUB_TITLE_DYNAMIC]: `zzz`,
  [MicroContestTranslationKey.CONTEST_PROMO_TITLE_PENDING]: `zzz`,
  [MicroContestTranslationKey.CONTEST_PROMO_TITLE_WINNER]: `zzz`,
  [MicroContestTranslationKey.CONTEST_PROMO_TITLE_LOST]: 'zzz',
  [MicroContestTranslationKey.CONTEST_PROMO_MORE_INFO_CTA1]: 'zzz',
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_AMOUNT_FREQUENCY]: `zzz`,
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_AMOUNT_OPTION_1]: `zzz`,
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_AMOUNT_OPTION_2]: `zzz`,
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_ERROR]: `zzz`,
  [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_LABEL]: `zzz`,
  [MicroContestTranslationKey.CONTEST_APP_SERVICE_ERROR]: `zzz`,
  [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_SUB_TITLE]: `zzz`,
  [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_TITLE]: "zzz",
  [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_SUB_TITLE]: "zzz",
  [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_FNAME_LABEL]: "zzz",
  [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_LNAME_LABEL]: "zzz",
  [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_PHONE_LABEL]: "zzz",
}

export default TranslationKeyValues;
