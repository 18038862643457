import { MicroContestTranslationKey } from '../../model/contest.enum';
import { MicroContestTranslation } from '../../model/contest.interface';

const TranslationKeyValues: MicroContestTranslation = {
   [MicroContestTranslationKey.CONTEST_ENTRY_TITLE]: `¡Nuevos sorteos mensuales!`,
   [MicroContestTranslationKey.CONTEST_ENTRY_SUB_TITLE]: `¡Sé una de las muchas personas que cada mes ganan {{prizeAmount}}!`,
   [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA1_TITLE]: `Gana recompensas por ahorrar`,
   [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA1_SUB_TITLE]: `Decenas de personas afortunadas serán seleccionadas al azar cada mes para ganar {{prizeAmount}}!`,
   [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA2_TITLE]: `Es muy fácil participar`,
   [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA2_SUB_TITLE]: `Activa el ahorro automático y observa cómo crecen tus ahorros`,
   [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA3_TITLE]: `Ahorra con cualquier presupuesto`,
   [MicroContestTranslationKey.CONTEST_ENTRY_EXTRA3_SUB_TITLE]: `Puedes ajustar en cualquier momento la cantidad que se guardará automáticamente`,
   [MicroContestTranslationKey.CONTEST_ENTRY_FOOTER_LINK]: `Reglas completas del sorteo`,
   [MicroContestTranslationKey.CONTEST_ENTRY_CTA1]: `¡Estoy listo para ganar!`,
   [MicroContestTranslationKey.CONTEST_ENTRY_RULES_TITLE]: `Reglas completas del sorteo`,
   [MicroContestTranslationKey.CONTEST_ENTRY_RULES_SUB_TITLE]: `Sorteo mensual de ahorro de Brightside`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_TITLE]: `Elige tu propia cantidad`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_SUB_TITLE]: `¿Qué cantidad diaria deseas aportar?`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_CTA1]: `Continuar`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_CTA2]: `Cancelar`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_TITLE]: `Elige tu plan de autoahorro`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_SUB_TITLE]: `Deposita dinero directamente de tu nómina en tu cuenta de ahorros`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CTA1]: `Continuar`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_TITLE]: `Confirmar el importe del ahorro automático`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_SUB_TITLE]: `Tus <span class='brand'>{{dailySavingsAmount}} ahorros diarios</span> se transferirán de cada nómina a tu cuenta de ahorros de Brightside.`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA1_TITLE]: `Frecuencia`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA1_SUB_TITLE]: `Por nómina`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA2_TITLE]: `Importe (estimado)`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA2_SUB_TITLE]: `{{autosaveAmount}}`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA3_TITLE]: `Fecha de la primera nómina`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_EXTRA3_SUB_TITLE]: `{{autosaveDate}}`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_INFO]: `Los fondos son mantenidos por Evolve Bank & Trust, miembro de la FDIC`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_CTA1]: `Confirmar cantidad`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_CTA2]: `Editar cantidad`,
   [MicroContestTranslationKey.CONTEST_ENTRY_COMPLETE_TITLE]: `¡Genial, ya eres un ahorrador!`,
   [MicroContestTranslationKey.CONTEST_ENTRY_COMPLETE_SUB_TITLE]: `Dejar que tus ahorros crezcan es la mejor manera de prepararte para el futuro. <br/><br/>¡Asegúrate de volver al final del mes para ver si ganaste!`,
   [MicroContestTranslationKey.CONTEST_ENTRY_COMPLETE_CTA1]: `Ir a ahorros`,
   [MicroContestTranslationKey.CONTEST_RESULT_TITLE]: `¡Es hora de ver si ganaste!`,
   [MicroContestTranslationKey.CONTEST_RESULT_SUB_TITLE]: `¡Los resultados del sorteo están aquí!`,
   [MicroContestTranslationKey.CONTEST_RESULT_EXTRA_TITLE]: ``,
   [MicroContestTranslationKey.CONTEST_RESULT_EXTRA_SUB_TITLE]: "¡Pulsa a continuación para ver si eres uno de los {{numberOfWinners}} afortunados ahorradores que ganaron {{prizeAmount}}, simplemente por ahorrar automáticamente de su sueldo!",
   [MicroContestTranslationKey.CONTEST_RESULT_CTA1]: `Mostrar resultados`,
   [MicroContestTranslationKey.CONTEST_WON_TITLE]: `¡Eres uno de los afortunados ganadores!`,
   [MicroContestTranslationKey.CONTEST_WON_SUB_TITLE]: `¡¡¡Felicidades!!!`,
   [MicroContestTranslationKey.CONTEST_WON_EXTRA_TITLE]: ``,
   [MicroContestTranslationKey.CONTEST_WON_EXTRA_SUB_TITLE]: "¡Los ahorros sí dan resultados! Tu saldo de Brightside Savings reflejará tus ganancias de {{prizeAmount}} en los próximos 3 días hábiles.",
   [MicroContestTranslationKey.CONTEST_WON_CTA1]: `Ir a ahorros`,
   [MicroContestTranslationKey.CONTEST_LOST_TITLE]: `No has ganado... todavía`,
   [MicroContestTranslationKey.CONTEST_LOST_SUB_TITLE]: `¡Gracias por participar!`,
   [MicroContestTranslationKey.CONTEST_LOST_EXTRA_TITLE]: ``,
   [MicroContestTranslationKey.CONTEST_LOST_EXTRA_SUB_TITLE]: `¡Aún puedes obtener un bono de $10! Recomienda a un compañero de trabajo y ambos recibirán $10 adicionales en sus cuentas de ahorros de Brightside después de su primer depósito de Autosavings. ¡Y sigue ahorrando automáticamente para tener la oportunidad de ganar el próximo mes!`,
   [MicroContestTranslationKey.CONTEST_LOST_CTA1]: `Ir a ahorros`,
   [MicroContestTranslationKey.CONTEST_MORE_TITLE]: `Recompensas por ahorro automático`,
   [MicroContestTranslationKey.CONTEST_MORE_CONTENT_ONE_TITLE]: `Detalles del programa`,
   [MicroContestTranslationKey.CONTEST_MORE_CONTENT_ONE_SUB_TITLE]: `El último día hábil de cada mes, se seleccionarán al azar docenas de personas en Brightside que ahorran automáticamente para ganar $100.`,
   [MicroContestTranslationKey.CONTEST_MORE_CONTENT_TWO_TITLE]: `Elegibilidad`,
   [MicroContestTranslationKey.CONTEST_MORE_CONTENT_TWO_SUB_TITLE]: `Para ser elegible, basta con tener activado el ahorro automático. ¡Mientras tengas activado el ahorro automático, participarás automáticamente!`,
   [MicroContestTranslationKey.CONTEST_MORE_CONTENT_THREE_TITLE]: `Método de participación alternativo`,
   [MicroContestTranslationKey.CONTEST_MORE_CONTENT_THREE_SUB_TITLE]: `Si deseas inscribirte en el sorteo sin ahorro automático, imprime <a href='loader?pageOnForwardCtaPath=/contest-promo/contest-legal/attach-client'>este formulario</a> y envíalo por correo a la dirección que se indica en las instrucciones. Las participaciones que se reciban en los cinco (5) días hábiles siguientes al final del mes se incluirán en el siguiente sorteo mensual.`,
   [MicroContestTranslationKey.CONTEST_MORE_CONTENT_FOUR_TITLE]: `Fechas importantes`,
   [MicroContestTranslationKey.CONTEST_MORE_CONTENT_FOUR_SUB_TITLE]: `Asegúrate de que tu ahorro automático esté activo antes del último día del mes para garantizar tu elegibilidad automática. Si no está activo, podrás activarlo y participar en el sorteo del mes siguiente.`,
   [MicroContestTranslationKey.CONTEST_MORE_LINK]: `Reglas completas del sorteo`,
   [MicroContestTranslationKey.CONTEST_MORE_CTA1]: `¡Estoy listo para ganar!`,
   [MicroContestTranslationKey.CONTEST_CARD_TITLE]: `Tus recompensas`,
   [MicroContestTranslationKey.CONTEST_TITLE]: `Sorteo del ahorro automático`,
   [MicroContestTranslationKey.CONTEST_SUB_TITLE]: ``,
   [MicroContestTranslationKey.CONTEST_PROMO_TITLE]: ``,
   [MicroContestTranslationKey.CONTEST_PROMO_MORE_TITLE]: `Detalles del programa`,
   [MicroContestTranslationKey.CONTEST_SUB_TITLE_DYNAMIC]: `Próximo sorteo el {{drawingDate}}`,
   [MicroContestTranslationKey.CONTEST_PROMO_TITLE_PENDING]: `{{contestWinnersTotal}} Se garantiza que las personas que utilicen el guardado automático ganarán {{contestPrizeTotal}} este mes.`,
   [MicroContestTranslationKey.CONTEST_PROMO_TITLE_WINNER]: `¡Ahorrar valió la pena! Ganaste {{contestPrizeTotal}}! 🎉`,
   [MicroContestTranslationKey.CONTEST_PROMO_TITLE_LOST]: `¡Sigue ahorrando para participar en el sorteo del siguiente mes!`,
   [MicroContestTranslationKey.CONTEST_PROMO_MORE_INFO_CTA1]: `Entendido`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_AMOUNT_FREQUENCY]: `al día`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_AMOUNT_OPTION_1]: `⭐️ ¡La manera más fácil de comenzar! ⭐`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_AMOUNT_OPTION_2]: `Elige tu propio importe`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CONFIRM_ERROR]: `No pudimos actualizar tu programa de guardado automático`,
   [MicroContestTranslationKey.CONTEST_ENTRY_AUTOSAVE_CUSTOM_LABEL]: `Al día`,
   [MicroContestTranslationKey.CONTEST_APP_SERVICE_ERROR]: `No pudimos encontrar un id del concurso para completar esta solicitud`,
   [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_SUB_TITLE]: `Para participar sin ahorro automático, imprime este formulario de participación y envíalo por correo a: Brightside Benefit Sweepstakes, 55 N Arizona Pl. Suite 200, Chandler AZ 85225. <br /><br />Las inscripciones que se reciban después de que transcurran más de cinco (5) días hábiles posteriores al último día del mes se inscribirán en el sorteo del mes actual. Las participaciones que se reciban en los cinco (5) días hábiles siguientes al final del mes se incluirán en el sorteo del mes siguiente. <br /><br />Al imprimir y enviar este formulario, reconoces y aceptas los <a href='//www.gobrightside.com/terms-of-service' target='_blank'>Términos de servicio</a> del sorteo y la <a href='//www.gobrightside.com/privacy-policy' target='_blank'>Política de privacidad de Brightside</a>.`,
   [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_TITLE]: `Método de participación alternativo`,
   [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_SUB_TITLE]: `Sorteo mensual de ahorradores de Brightside`,
   [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_FNAME_LABEL]: `Nombre`,
   [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_LNAME_LABEL]: `Apellido`,
   [MicroContestTranslationKey.CONTEST_AMOE_LEGAL_CONTENT_PHONE_LABEL]: `Número de teléfono`,
};

export default TranslationKeyValues;
