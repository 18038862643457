import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CACHE_KEY } from '@brightside-web/shared/desktop';
import { SavingsAccountService } from '@brightside-web/desktop/data-access/savings';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  BsCacheService
} from '@brightside-web/desktop/data-access/core-services';

@Injectable({
  providedIn: 'root',
})
export class DeepSavingsLinkedAccountGuard implements CanActivate {
  constructor(
    private savingsService: SavingsAccountService,
    private router: Router,
    private bsCacheService: BsCacheService) {}

  canActivate(): Observable<boolean> {
    //Make sure we clear cache of any one click stuff
    this.bsCacheService.removeItem(CACHE_KEY.deepSavingsSource);
    this.bsCacheService.removeItem(CACHE_KEY.deepSavingsDetails);

    return this.savingsService.getLinkedBanks().pipe(
      map((resp) => {
        if (resp.length > 0) {
          return true;
        }

        this.router.navigate(['/home/savings']);
        return false;
      })
    );
  }
}
