import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SimpleGlobalModalService } from '@brightside-web/desktop/data-access/core-services';
import { FirebaseService } from '@brightside-web/desktop/data-access/shared';
import { Environment } from '@brightside-web/micro/core/environment';
import { Observable, Subject, combineLatest, of, throwError } from 'rxjs';
import { catchError, concatMap, delay, retryWhen, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'brightside-web-smfa',
  templateUrl: './smfa.component.html',
  styleUrls: ['./smfa.component.scss'],
})
export class SmfaComponent implements OnInit, OnDestroy {
  pollingTimeInSeconds: Observable<number>;
  maxRetryAttempts: Observable<number>;
  smfaDynamicLink: string;
  apiMobileNoAuthEndpoint: string;

  private _unsub$: Subject<void> = new Subject<void>();

  constructor(
    private _route: ActivatedRoute,    
    private _firebase: FirebaseService,
    private _globalModalService: SimpleGlobalModalService,
    private _http: HttpClient,
    private _env: Environment
  ) {}

  ngOnInit(): void {
    this.pollingTimeInSeconds = of(+this._firebase.getValueAsString('smfaPollFrequency') || 3);
    this.maxRetryAttempts = of(+this._firebase.getValueAsString('maxRetryAttempts') || 3);

    this.smfaDynamicLink = this._env.smfaDynamicLink;
    this.apiMobileNoAuthEndpoint = this._env.awsmobile.endpoints['api-mobile-noauth'].endpoint;

    combineLatest([this._route.params, this.pollingTimeInSeconds, this.maxRetryAttempts])
      .pipe(
        tap(([params, pollingTimeInSeconds, maxRetryAttempts]) => {
          this.getLinkInfo(params.linkId, pollingTimeInSeconds, maxRetryAttempts);
        }),
        takeUntil(this._unsub$)
      )
      .subscribe();
  }

  getLinkInfo(linkId: string, pollingTimeInSeconds: number, maxRetryAttempts: number): void {
    this._http
      .get(`${this.apiMobileNoAuthEndpoint}/links/${linkId}`)
      .pipe(
        retryWhen((error) => this.shouldRetry(error, pollingTimeInSeconds, maxRetryAttempts)),
        catchError(() => this.showErrorModal()),
        takeUntil(this._unsub$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._unsub$.next();
  }

  private shouldRetry(error: Observable<any>, pollingTimeInSeconds: number, maxRetryAttempts: number) {    
    return error.pipe(
      concatMap((response, index) => {
        if (response.status === 410) return throwError(response);        
        else return index === maxRetryAttempts ? throwError(response) : of(null).pipe(delay(pollingTimeInSeconds * 1000));
      })
    );
  }

  private redirectToDynamicLink(): void {    
    window.location.href = this.smfaDynamicLink;
  }

  private showErrorModal(): Observable<boolean> {
    this._globalModalService.createGenericErrorModal('smfa_verification', () => this.redirectToDynamicLink(), 'OK');
    return of(false);
  }
}
