import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'brightside-web-account-header',
  templateUrl: './account-header.component.html',
  styleUrls: ['./account-header.component.scss']
})
export class AccountHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() balance: number;

  constructor() { }

  ngOnInit(): void {
  }

}
