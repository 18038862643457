<brightside-web-product-eligibility (successfulSubmit)="submitAction($event)"></brightside-web-product-eligibility>


<bw-modal *ngIf="showError !== ''"
          modalWidth="medium"
          (closeModal)="showError = ''"
          [brightsideWebFb]="{ event_name: 'error_shown', custom: { 'error id': this.showError }}" [error_view]="true">
  <typography title-medium color="var(--primary-text)" class="mb-sm">
    {{ visibleError.titleKey | translate }}
  </typography>
  <section class="body">
    <bw-paragraph>
      <typography text-large-regular color="var(--secondary-text)">
        <p class="mb-xs">
          {{ visibleError.bodyKey | translate }}
        </p>
      </typography>
    </bw-paragraph>
  </section>
  <footer class="footer">
    <div class="flex-row row-reverse">
      <button
        bw-button
        [brightsideWebFb]="{ event_name: 'credit_intro_tapped', action: 'click' }"
        (click)="showError = ''">
        {{ visibleError.ctaKey | translate }}
      </button>
    </div>
  </footer>
</bw-modal>
